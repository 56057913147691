import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "reset-info" }

import useLogin from "@/composables/auth/useLogin";
import useProfileDetails from "@/composables/profile/useProfileDetails";
import { useUserStore } from "@/store/user";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { VBtn, VInput } from "v-ferry-components";
import { computed, reactive } from "vue";
import useSnackbar from "@/composables/useSnackbar";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyDetails',
  setup(__props) {

const userStore = useUserStore();

const user = computed(() => userStore.getUserDetails);

const { updateDetails, isLoading } = useProfileDetails();

const state = reactive({
    firstName: user.value?.firstName ?? "",
    lastName: user.value?.lastName ?? "",
    email: user.value?.email ?? "",
    mobilePhone: user.value?.mobilePhone ?? "",
    address: user.value?.address ?? "",
    city: user.value?.city ?? "",
    zip: user.value?.zip ?? 0,
    password: "",
});

const rules = {
    firstName: { required },
    lastName: { required },
    email: { required },
    mobilePhone: { required },
    address: { required },
    city: { required },
    zip: { required },
    password: { required },
};

const v$ = useVuelidate(rules, state, { $scope: false });

const { displaySnack } = useSnackbar();

const submit = async () => {
    const isValid = await v$.value.$validate();

    if (!isValid) return;

    updateDetails(state)
        .then(() => {
            displaySnack({
                message: "Dina personuppgifter har blivit uppdaterade.",
                type: "success",
                id: `${user.value?.email}newDetails`,
            });
        })
        .catch((err) => {
            displaySnack({
                message: err.response.data.Message,
                type: "danger",
                id: `${user.value?.email}newDetailsFail`,
            });
        });
};

const { sendForgottenPassword, isLoading: isLoadingForgot } = useLogin();

const resetPassword = () => {
    sendForgottenPassword(userStore.getUser.userName);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createElementBlock("form", {
      onSubmit: _withModifiers(submit, ["prevent"]),
      class: "details",
      key: user.value?.email
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(VInput), {
          modelValue: _unref(v$).firstName.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).firstName.$model) = $event)),
          label: _ctx.$t('fields.firstname.label'),
          hasError: _unref(v$).firstName.$error
        }, null, 8, ["modelValue", "label", "hasError"]),
        _createVNode(_unref(VInput), {
          modelValue: _unref(v$).lastName.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).lastName.$model) = $event)),
          label: _ctx.$t('fields.lastname.label'),
          hasError: _unref(v$).lastName.$error
        }, null, 8, ["modelValue", "label", "hasError"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(VInput), {
          modelValue: _unref(v$).email.$model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).email.$model) = $event)),
          label: _ctx.$t('fields.email.label'),
          hasError: _unref(v$).email.$error
        }, null, 8, ["modelValue", "label", "hasError"]),
        _createVNode(_unref(VInput), {
          modelValue: _unref(v$).mobilePhone.$model,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).mobilePhone.$model) = $event)),
          label: _ctx.$t('fields.phone.label'),
          hasError: _unref(v$).mobilePhone.$error
        }, null, 8, ["modelValue", "label", "hasError"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(VInput), {
          modelValue: _unref(v$).address.$model,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(v$).address.$model) = $event)),
          label: _ctx.$t('fields.address.label'),
          hasError: _unref(v$).address.$error
        }, null, 8, ["modelValue", "label", "hasError"]),
        _createVNode(_unref(VInput), {
          modelValue: _unref(v$).city.$model,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(v$).city.$model) = $event)),
          label: _ctx.$t('fields.city.label'),
          hasError: _unref(v$).city.$error
        }, null, 8, ["modelValue", "label", "hasError"]),
        _createVNode(_unref(VInput), {
          modelValue: _unref(v$).zip.$model,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(v$).zip.$model) = $event)),
          label: _ctx.$t('fields.zip.label'),
          hasError: _unref(v$).zip.$error
        }, null, 8, ["modelValue", "label", "hasError"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(VInput), {
          type: "password",
          modelValue: _unref(v$).password.$model,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(v$).password.$model) = $event)),
          label: _ctx.$t('fields.password.label'),
          hasError: _unref(v$).password.$error,
          outerLabel: _ctx.$t('myPages.myDetails.enterPassword')
        }, null, 8, ["modelValue", "label", "hasError", "outerLabel"])
      ]),
      _createVNode(_unref(VBtn), { isLoading: _unref(isLoading) }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("myPages.myProfile.save")), 1)
        ]),
        _: 1
      }, 8, ["isLoading"])
    ], 32)),
    _createElementVNode("form", {
      onSubmit: _withModifiers(resetPassword, ["prevent"]),
      class: "reset"
    }, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t("myPages.myProfile.reset")), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("myPages.myProfile.resetInfo")), 1),
        _createVNode(_unref(VBtn), { isLoading: _unref(isLoadingForgot) }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("myPages.myProfile.sendLink")), 1)
          ]),
          _: 1
        }, 8, ["isLoading"])
      ])
    ], 32)
  ], 64))
}
}

})