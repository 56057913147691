import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "tickets" }
const _hoisted_2 = { class: "prose" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = {
  key: 0,
  class: "agent-title"
}
const _hoisted_5 = {
  key: 1,
  class: "agent-title"
}
const _hoisted_6 = { class: "h2" }
const _hoisted_7 = { class: "ticket-select" }
const _hoisted_8 = { class: "ticket-select__option" }
const _hoisted_9 = { class: "vehicles" }
const _hoisted_10 = { class: "ticket-select__option" }
const _hoisted_11 = { class: "vehicles" }
const _hoisted_12 = { class: "ticket-select__option" }
const _hoisted_13 = {
  key: 0,
  class: "error-messages"
}

import useVehiclesQuery from "@/composables/profile/useVehiclesQuery";
import { useBookingStore } from "@/store/booking";
import { usePassengersStore } from "@/store/passengers";
import { useVehiclesStore } from "@/store/vehicles";
import useVuelidate from "@vuelidate/core";
import { VCard } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import TicketsSelectOption from "./TicketsSelectOption.vue";
import VehicleSelect from "./VehicleSelect.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TicketsSelect',
  setup(__props, { expose: __expose }) {

const bookingStore = useBookingStore();
const isBusiness = computed(() => bookingStore.isBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const { t } = useI18n();

const passengerStore = usePassengersStore();
const vehiclesStore = useVehiclesStore();

const { data, isLoading } = useVehiclesQuery();

const state = reactive({
    vehicle: 1,
    trailer: vehiclesStore.trailer?.length > 0 ? 1 : 0,
    adult: passengerStore.adult ?? 0,
});

const rules = {
    vehicle: {},
    trailer: {},
    adult: {},
};

const v$ = useVuelidate(rules, state);

const vehicleRef = ref();
const trailerRef = ref();

const persistState = () => {
    const vehicles = {
        car: state.vehicle > 0 ? vehicleRef.value.state : undefined,
        trailer: state.trailer > 0 ? trailerRef.value.state : undefined,
    };

    passengerStore.setPassengers(state.adult);
    vehiclesStore.setVehicles(vehicles);
};

__expose({
    persistState,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (isBusiness.value)
          ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_unref(t)("agent.title")), 1))
          : _createCommentVNode("", true),
        (isAmend.value)
          ? (_openBlock(), _createElementBlock("b", _hoisted_5, _toDisplayString(_unref(t)("bookingFlow.isAmend")) + " " + _toDisplayString(_unref(bookingStore).bookingNumber), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h1", _hoisted_6, _toDisplayString(_unref(t)("ticketSelect.title")), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(t)("ticketSelect.info.price")), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(t)("ticketSelect.info.vehicle")), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(TicketsSelectOption, {
          class: "vehicle-selection",
          modelValue: _unref(v$).vehicle.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).vehicle.$model) = $event)),
          icon: "car",
          max: 1,
          title: _unref(t)('ticketSelect.label.vehicle'),
          unelevated: "",
          hideQuantity: ""
        }, null, 8, ["modelValue", "title"]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_unref(VCard), {
            class: "vehicles-item vehicles-item__active car",
            unelevated: "",
            bordered: ""
          }, {
            default: _withCtx(() => [
              (_unref(isLoading) === false)
                ? (_openBlock(), _createBlock(VehicleSelect, {
                    key: 0,
                    vehicles: _unref(data),
                    ref_key: "vehicleRef",
                    ref: vehicleRef,
                    isBusiness: isBusiness.value
                  }, null, 8, ["vehicles", "isBusiness"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(TicketsSelectOption, {
          class: "vehicle-selection",
          modelValue: _unref(v$).trailer.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).trailer.$model) = $event)),
          icon: "carbon/trailer",
          max: 1,
          min: 1,
          title: _unref(t)('ticketSelect.label.trailer'),
          unelevated: ""
        }, null, 8, ["modelValue", "title"]),
        _createElementVNode("div", _hoisted_11, [
          (state.trailer > 0)
            ? (_openBlock(), _createBlock(_unref(VCard), {
                key: 0,
                class: "vehicles-item vehicles-item__active car",
                unelevated: "",
                bordered: ""
              }, {
                default: _withCtx(() => [
                  (_unref(isLoading) === false)
                    ? (_openBlock(), _createBlock(VehicleSelect, {
                        key: 0,
                        ref_key: "trailerRef",
                        ref: trailerRef,
                        isTrailer: "",
                        vehicles: _unref(data),
                        hasNoLicense: _unref(vehiclesStore).trailer.length > 0 && (_unref(vehiclesStore).trailer.license?.length ?? 0) === 0,
                        isBusiness: isBusiness.value
                      }, null, 8, ["vehicles", "hasNoLicense", "isBusiness"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(TicketsSelectOption, {
          modelValue: _unref(v$).adult.$model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).adult.$model) = $event)),
          icon: "person",
          title: _unref(t)('ticketSelect.label.adult'),
          unelevated: ""
        }, null, 8, ["modelValue", "title"])
      ])
    ]),
    (_unref(v$).adult.$error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).adult.$errors.map((e) => e.$message))], (error) => {
            return (_openBlock(), _createElementBlock("p", {
              key: error.toString()
            }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})