import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "booking-basket__header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "booking-basket__content"
}
const _hoisted_5 = {
  key: 0,
  class: "loading-overlay"
}
const _hoisted_6 = {
  key: 0,
  class: "heading"
}
const _hoisted_7 = {
  key: 1,
  class: "heading date"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "booking-basket__summary"
}
const _hoisted_10 = {
  key: 0,
  class: "nonrefund"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { key: 3 }
const _hoisted_14 = { key: 3 }
const _hoisted_15 = { class: "title" }

import BaseLoader from "@/components/base/BaseLoader.vue";
import { dayDateTime } from "@/helpers/dateHelpers";
import { BookingDeparture } from "@/models/front/BookingDeparture";
import { useBookingStore } from "@/store/booking";
import { computed } from "vue";
import BasketResources from "./BasketResources.vue";

interface Props {
    sections?: BookingDeparture[];
    total?: number;
    paid?: number;
    amd?: number;
    isLoading?: boolean;
    isMobile?: boolean;
    isCardPurchase?: boolean;
    isFetching?: boolean;
    showSummary: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingBasket',
  props: {
    sections: {},
    total: {},
    paid: {},
    amd: {},
    isLoading: { type: Boolean },
    isMobile: { type: Boolean },
    isCardPurchase: { type: Boolean },
    isFetching: { type: Boolean },
    showSummary: { type: Boolean }
  },
  setup(__props: any) {

const bookingStore = useBookingStore();
const isAgent = computed(() => bookingStore.getIsBusiness);

const isAmend = computed(() => bookingStore.getIsAmendment);

const props = __props;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'booking-basket': !_ctx.isMobile, 'mobile-basket': _ctx.isMobile })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (isAmend.value)
        ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.$t("bookingFlow.isAmend")) + " " + _toDisplayString(_unref(bookingStore).getBookingNumber), 1))
        : _createCommentVNode("", true),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("basket.title")), 1),
      (isAgent.value)
        ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.$t("agent.title")), 1))
        : _createCommentVNode("", true),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "basket-divider" }, [
        _createElementVNode("div", { class: "basket-divider__overlay" })
      ], -1))
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(BaseLoader, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.isFetching)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(BaseLoader)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, index) => {
            return (_openBlock(), _createElementBlock("ul", { key: index }, [
              (!_ctx.isCardPurchase)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(`routes.${section.route}`)), 1)
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.isCardPurchase)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, _toDisplayString(_unref(dayDateTime)(section.departureDate)), 1))
                : _createCommentVNode("", true),
              (section.resources)
                ? (_openBlock(), _createBlock(BasketResources, {
                    key: 2,
                    resources: section.resources
                  }, null, 8, ["resources"]))
                : _createCommentVNode("", true)
            ]))
          }), 128)),
          (isAgent.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t("basket.agentVAT")) + " 25%", 1))
            : _createCommentVNode("", true),
          (_ctx.showSummary === true)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                (_ctx.amd > 0)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("basket.amendmentFee")), 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.amd) + "kr", 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("basket.total")), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.total) + "kr", 1)
                ]),
                (_ctx.paid)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("basket.paid")), 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.paid) + "kr", 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.paid && _ctx.total && _ctx.paid > (_ctx.total + (_ctx.nonRefundable ?? 0)))
                  ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t("basket.refund")), 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.total - _ctx.paid) + "kr", 1)
                    ]))
                  : (_ctx.total && _ctx.paid && (_ctx.total - _ctx.paid) > 0)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("basket.topay")), 1),
                        _createElementVNode("span", null, _toDisplayString(_ctx.total - _ctx.paid) + "kr", 1)
                      ]))
                    : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                _createElementVNode("li", _hoisted_15, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("basket.unknown")), 1)
                ])
              ]))
        ]))
  ], 2))
}
}

})