import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "display-cards" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { class: "checkbox-container" }

import useAvailableMultiTripCardQuery from "@/composables/profile/useAvailableMultiTripCardQuery";
import { AvailableCard } from "@/models/front/AvailableCard";
import { CustomerCard } from "@/models/front/Cards";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { VBtn, VRadio } from "v-ferry-components";
import { reactive } from "vue";
import { useRouter } from "vue-router";

interface Props {
    multiTripCards?: CustomerCard[];
    travelCards?: CustomerCard[];
    cardsForPurchase?: AvailableCard[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DisplayCards',
  props: {
    multiTripCards: {},
    travelCards: {},
    cardsForPurchase: {}
  },
  setup(__props: any) {

const { buyTickets, isLoadingBuy } = useAvailableMultiTripCardQuery();
const router = useRouter();



const state = reactive({
    availableCard: "",
});

const rules = {
    availableCard: { required },
};

const v$ = useVuelidate(rules, state);

const submit = async () => {
    const isValid = await v$.value.$validate();

    if (!isValid) return;
    buyTickets(state.availableCard).then((resp) => {
        router.push(`/checkout/?reference=${resp.reference}`);
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _withModifiers(submit, ["prevent"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", null, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", null, _toDisplayString(_ctx.$t("myPages.myCards.labels.card")), 1),
            (_ctx.multiTripCards)
              ? (_openBlock(), _createElementBlock("th", _hoisted_2, _toDisplayString(_ctx.$t("myPages.myCards.labels.remainingTrips")), 1))
              : _createCommentVNode("", true),
            (_ctx.cardsForPurchase)
              ? (_openBlock(), _createElementBlock("th", _hoisted_3, _toDisplayString(_ctx.$t("myPages.myCards.labels.price")), 1))
              : _createCommentVNode("", true),
            (_ctx.travelCards)
              ? (_openBlock(), _createElementBlock("th", _hoisted_4, _toDisplayString(_ctx.$t("myPages.myCards.labels.validThrough")), 1))
              : _createCommentVNode("", true),
            (_ctx.travelCards)
              ? (_openBlock(), _createElementBlock("th", _hoisted_5, _toDisplayString(_ctx.$t("myPages.myCards.labels.usedTrips")), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.multiTripCards, (multiTripCard) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: multiTripCard.name
            }, [
              _createElementVNode("td", null, _toDisplayString(_ctx.$t(`myPages.myCards.cards.${multiTripCard.type}.${multiTripCard.subType}`)), 1),
              _createElementVNode("td", null, _toDisplayString(multiTripCard.maxUsage - multiTripCard.currentUsage) + "/" + _toDisplayString(multiTripCard.maxUsage) + " " + _toDisplayString(_ctx.$t("myPages.myCards.tripsRemaining")), 1)
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.travelCards, (card) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: card.name
            }, [
              _createElementVNode("td", null, _toDisplayString(_ctx.$t(`myPages.myCards.cards.${card.type}.${card.subType}`)), 1),
              _createElementVNode("td", null, _toDisplayString(card.validBetween), 1),
              _createElementVNode("td", null, _toDisplayString(card.currentUsage), 1)
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsForPurchase, (card) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: card.description
            }, [
              _createElementVNode("td", _hoisted_6, [
                _createVNode(_unref(VRadio), {
                  modelValue: _unref(v$).availableCard.$model,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).availableCard.$model) = $event)),
                  id: card.description,
                  label: _ctx.$t(`myPages.myCards.cards.${card.type}.${card.subType}`),
                  value: card.identifier,
                  hasError: _unref(v$).availableCard.$error,
                  name: "purchaseCard"
                }, null, 8, ["modelValue", "id", "label", "value", "hasError"])
              ]),
              _createElementVNode("td", null, _toDisplayString(card.price) + "kr", 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    (_ctx.cardsForPurchase)
      ? (_openBlock(), _createBlock(_unref(VBtn), {
          key: 0,
          isLoading: _unref(isLoadingBuy)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("myPages.myCards.buyBtn")), 1)
          ]),
          _: 1
        }, 8, ["isLoading"]))
      : _createCommentVNode("", true)
  ], 32))
}
}

})