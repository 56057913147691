<template>
    <div class="customer-details">
        <div v-if="isFetching" class="loading-overlay">
            <BaseLoader />
        </div>
        <template v-if="isLoggedIn || showCustomerDetailsForm">
            <CustomerDetailsForm
                :isLoggedIn="isLoggedIn"
                :details="details"
                :createAccount="createAccount"
                @abort="toggleCustomerForm(false)"
                ref="customerDetailsRef"
                :key="details?.email"
            />
        </template>
        <template v-else-if="!isLoggedIn">
            <div>
                <div class="subtitle">
                    <b v-if="isBusiness" class="agent-title">{{ $t("agent.title") }}</b>
                    <b v-if="isAmend" class="agent-title">{{ $t("bookingFlow.isAmend") }} {{ bookingStore.bookingNumber }}</b>
                </div>

                <h1 class="h2">
                    {{ $t("customerDetails.loginTitle") }}
                </h1>
            </div>
            <LoginForm @createAccount="toggleCustomerForm(true, true)" :isAgent="isBusiness" />
            <a href="#" @click.prevent="toggleCustomerForm(true)" class="link text-center">{{ $t("customerDetails.buttons.continue") }}</a>
        </template>
        <div class="error-messages">
            <p>{{ error }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import LoginForm from "@/components/login/LoginForm.vue";
import { UserType } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";
import useBookingDetailsQuery from "../../composables/useBookingDetailsQuery";
import CustomerDetailsForm from "./CustomerDetailsForm.vue";
import BaseLoader from "@/components/base/BaseLoader.vue";
import useCreateAccount from "@/composables/auth/useCreateAccount";

const bookingStore = useBookingStore();
const isBusiness = computed(() => bookingStore.isBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const userStore = useUserStore();

const { setDetails, data: details, isFetching } = useBookingDetailsQuery();
const { createCustomer } = useCreateAccount();

const userType = computed(() => userStore.getUserType);
const isLoggedIn = computed(() => userType.value !== UserType.Unknown);

const createAccount = ref(false);

const showCustomerDetailsForm = ref(isLoggedIn.value);

const toggleCustomerForm = (show: boolean, create?: boolean) => {
    createAccount.value = false;
    if (create) {
        createAccount.value = create;
    }
    showCustomerDetailsForm.value = show;
};

const customerDetailsRef = ref();

const error = ref();

const getUserType = (createNewAccount: boolean) => {
    if (isBusiness.value) return UserType.Agent;
    if (createNewAccount) return UserType.Customer;
    else return UserType.Guest;
};

const validateStepAsync = () => {
    const state = customerDetailsRef.value.state;
    const createNewAccount = customerDetailsRef.value.createNewAccount;

    const detailsRequest = {
        firstName: state.firstName,
        lastName: state.lastName,
        address: state.address,
        zip: state.zip,
        city: state.city,
        mobilePhone: state.mobilePhone,
        email: state.email,
        password: state.password,
        passwordRepeat: state.password,
        type: getUserType(createNewAccount),
        userName: state.email,
        name: !state.name ? `${state.firstName} ${state.lastName}` : state.name,
        terms: state.gdpr,
        bookingNumber: bookingStore.getBookingNumber,
    };

    return setDetails(detailsRequest)
        .then(() => {
            bookingStore.setTermsAccepted(true);

            if (createNewAccount) {
                return createCustomer(state).catch((err) => {
                    error.value = err.message;

                    return Promise.reject(err);
                });
            }

            if (userStore.getUserType === UserType.Unknown) {
                userStore.setUserType(UserType.Guest);
            }
        })
        .catch((err) => {
            error.value = err.message;
            return Promise.reject(err);
        });
};

defineExpose({
    validateStepAsync,
});
</script>

<style scoped lang="scss">
.customer-details {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .subtitle {
        display: flex;
        justify-content: space-between;
    }
}
</style>
