<template>
    <BaseLoader v-if="isLoadingCards || isLoadingTickets" />
    <div v-else class="my-cards">
        <div v-if="cards?.travelCards" class="my-cards__section">
            <header>
                <SvgIcon icon="carbon/card" />
                <h3>{{ $t("myPages.titles.travelcard") }}</h3>
            </header>
            <DisplayCards v-if="cards?.travelCards?.length" :travelCards="cards?.travelCards" />
            <div class="prose" v-else>
                <p>
                    Du har inga kort. Årskort beställs via mail: kontoret@ornosjotrafik.se i god tid innan man vill att det ska börja gälla.
                    Kostnaden faktureras via mail.
                </p>
            </div>
        </div>
        <div v-if="cards?.multiTripCards" class="my-cards__section">
            <header>
                <SvgIcon icon="carbon/ticket" />
                <h3>{{ $t("myPages.titles.tickets") }}</h3>
            </header>
            <DisplayCards v-if="cards?.multiTripCards?.length" :multiTripCards="cards?.multiTripCards" />
            <div class="prose" v-else>
                <p>Du har inga partibiljetter</p>
            </div>
        </div>
        <div v-if="availableCardsForPurchase" class="my-cards__section">
            <header>
                <SvgIcon icon="carbon/shopping-cart-plus" />
                <h3>{{ $t("myPages.titles.newTicket") }}</h3>
            </header>
            <DisplayCards class="my-cards__purchase" :cardsForPurchase="availableCardsForPurchase" ref="availableTicketsRef" />
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseLoader from "@/components/base/BaseLoader.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useAvailableMultiTripCardQuery from "@/composables/profile/useAvailableMultiTripCardQuery";
import useCustomerCardsQuery from "@/composables/profile/useCustomerCardsQuery";
import { ref } from "vue";
import DisplayCards from "./DisplayCards.vue";

const { data: cards, isLoading: isLoadingCards } = useCustomerCardsQuery();

const { data: availableCardsForPurchase, isLoading: isLoadingTickets } = useAvailableMultiTripCardQuery();

const availableTicketsRef = ref();
</script>

<style lang="scss" scoped>
.my-cards {
    display: flex;
    flex-direction: column;
    gap: 50px;

    &__section {
        max-width: 75ch;

        header {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 10px;
        }

        + .my-cards__section {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 20px;
        }
    }
}
</style>
