<template>
    <section class="departure-section" :class="{ disabled: disabled }">
        <header class="departure-section__header">
            <h2>{{ $t(`directions.${direction}`) }}</h2>
            <p>{{ $t(`routes.${route}`) }}</p>
        </header>
        <DepartureDates :direction="direction" />
        <div class="departure-table">
            <ul class="departure-table__header">
                <li>
                    <span>{{ $t(`departures.labels.departure`) }}</span>
                </li>
                <li>
                    <span>{{ $t(`departures.labels.arrival`) }}</span>
                </li>
            </ul>
            <ul class="departure-table__content">
                <Departure
                    v-for="departure in data"
                    :key="departure.identifier"
                    :departure="departure"
                    v-model="selectedDeparture"
                    :direction="direction"
                />
            </ul>
            <div v-if="isFetching" class="loading-overlay">
                <BaseLoader />
            </div>
            <div class="departure-table__empty" v-else-if="!data?.length">
                <SvgIcon icon="carbon/info" height="1.5rem" />
                <p>{{ $t(`departures.noDepartures`) }}</p>
            </div>
        </div>
        <div v-if="v$.selectedDeparture.$error" class="error-messages">
            <p v-for="error in [...new Set(v$.selectedDeparture.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
        </div>
    </section>
</template>

<script lang="ts" setup>
import BaseLoader from "@/components/base/BaseLoader.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { dateAndTime } from "@/helpers/dateHelpers";
import { Direction } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useDepartureStore } from "@/store/departure";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import useDepartureQuery from "../../composables/useDepartureQuery";
import Departure from "./Departure.vue";
import DepartureDates from "./DepartureDates.vue";

const { t } = useI18n();

interface Props {
    direction: Direction;
    disabled: boolean;
}

const props = defineProps<Props>();

const bookingStore = useBookingStore();
const departureStore = useDepartureStore();

const route = bookingStore.getRoute(props.direction);

const { data, isFetching } = useDepartureQuery(props.direction);
const selectedDeparture = computed({
    get() {
        return departureStore.getDeparture(props.direction).id;
    },
    set(departureId: string) {
        const departure = data.value?.find((departure) => departure.id === departureId);

        if (departure) {
            departureStore.setDeparture(props.direction, {
                id: departure.id,
                identifier: departure.identifier,
                date: dateAndTime(departure.departureDate),
            });
        }
        if (props.direction === Direction.Outward) {
            if (departureStore.getDeparture(Direction.Return).date < departureStore.getDeparture(Direction.Outward).date) {
                departureStore.setDeparture(Direction.Return, {
                    id: "",
                    identifier: "",
                    date: "",
                });
            }
        }
    },
});

const state = reactive({
    selectedDeparture: selectedDeparture.value,
});

const isDepartureSelected = () => {
    return data.value?.some((d) => d.id === selectedDeparture.value) ?? false;
};

const rules = {
    selectedDeparture: {
        v: helpers.withMessage(t("departures.errors.selectedDeparture"), () => isDepartureSelected()),
    },
};

const v$ = useVuelidate(rules, state);
</script>

<style lang="scss" scoped>
.departure-section {
    $prefix: ".departure-section";

    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--c-primary);
    border: none;

    &__header {
        h2 {
            color: var(--c-primary);
        }

        p {
            font-size: 1.25rem;
        }
    }

    + #{$prefix} {
        margin-top: 60px;
    }

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

.departure-table {
    display: flex;
    flex-direction: column;
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 var(--departure__table-padding--sm);

        li {
            position: relative;
            width: 40px;
            height: 1.25rem;
            font-weight: bold;

            span {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        @include media-breakpoint-up(md) {
            padding: 0 var(--departure__table-padding--lg);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-height: 215px;
    }

    &__empty {
        position: absolute;
        height: 100%;
        width: 100%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        p {
            font-size: 1.125rem;
        }
    }
}
</style>
