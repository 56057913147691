<template>
    <div class="login">
        <VModal v-if="showForgotPassword" @close="showForgotPassword = false" isClosable>
            <ForgotPassword @close="showForgotPassword = false" :email="v$.email.$model" />
        </VModal>
        <form @submit.prevent="submitForm">
            <div v-if="errorMessage" class="error-messages">
                <p>
                    {{ errorMessage }}
                </p>
            </div>
            <VInput v-if="isAgent === false" v-model="v$.email.$model" :label="$t('fields.emailOrCustomerNo.label')" />
            <VInput v-else v-model="v$.email.$model" :label="$t('fields.customerNo.label')" />
            <VInput v-model="v$.password.$model" :label="$t('fields.password.label')" type="password" />
            <div>
                <a href="#" @click.prevent="showForgotPassword = true" class="link">{{ $t("customerDetails.forgotPassword") }}</a>
            </div>
            <div class="buttons">
                <VBtn v-if="!hideCreateAccount" type="button" @click="emit('createAccount')" color="secondary" :disabled="isAgent">
                    {{ $t("customerDetails.buttons.create") }}
                </VBtn>
                <VBtn :isLoading="isLoading">{{ $t("customerDetails.buttons.login") }}</VBtn>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import useLogin from "@/composables/auth/useLogin";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { VBtn, VInput, VModal } from "v-ferry-components";
import { reactive, ref } from "vue";
import ForgotPassword from "./ForgotPassword.vue";
import { useQueryClient } from "vue-query";

interface Props {
    hideCreateAccount?: boolean;
    isAgent?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits(["createAccount", "onLogin"]);

const showForgotPassword = ref(false);
const queryClient = useQueryClient();

const { customerLogin, isLoading } = useLogin();

const state = reactive({
    email: "",
    password: "",
});

const rules = {
    email: { required },
    password: { required },
};

const v$ = useVuelidate(rules, state);

const errorMessage = ref("");

const submitForm = async () => {
    errorMessage.value = "";
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    customerLogin(state.email, state.password, (props.isAgent ?? false) ? 'agent' : 'customer')
        .then((resp) => {
            emit("onLogin", { defaultRoute: resp?.defaultRoute, type: resp?.type });
        })
        .catch((error) => {
            errorMessage.value = error.message;
        })
		.finally(() => {
        	queryClient.invalidateQueries("calc");
        });;
};
</script>

<style lang="scss" scoped>
.login {
    position: relative;

    form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .buttons {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;

        .v-btn {
            flex: 1;
        }
    }
}
</style>
