import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "departures" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = {
  key: 0,
  class: "agent-title"
}
const _hoisted_4 = {
  key: 1,
  class: "agent-title"
}
const _hoisted_5 = { class: "h2" }
const _hoisted_6 = { class: "vehicle-info" }
const _hoisted_7 = { class: "vehicle-info--0" }
const _hoisted_8 = { class: "vehicle-info--1" }
const _hoisted_9 = { class: "vehicle-info--2" }
const _hoisted_10 = { class: "error-messages large" }

import SvgIcon from "@/components/icons/SvgIcon.vue";
import useBook from "@/composables/book/useBook";
import { Direction } from "@/models/Enums";
import { BookRequest } from "@/models/front/BookRequest";
import { useBookingStore } from "@/store/booking";
import { useDepartureStore } from "@/store/departure";
import { useVehiclesStore } from "@/store/vehicles";
import { computed, ref, watch } from "vue";
import DepartureSection from "./DepartureSection.vue";
import { useQueryClient } from "vue-query";


export default /*@__PURE__*/_defineComponent({
  __name: 'Departures',
  setup(__props, { expose: __expose }) {

const bookingStore = useBookingStore();
const isBusiness = computed(() => bookingStore.isBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);
const queryClient = useQueryClient();

const { book } = useBook();

const outwardIsLocked = computed(() => bookingStore.getBooking.outward.isLocked);

const directions = bookingStore.getTrips;
const departureStore = useDepartureStore();
const vehiclesStore = useVehiclesStore();

const error = ref("");

const validateStepAsync = () => {
    const request: BookRequest = {
        identifiers: departureStore.getDepartureIdentifiers(),
        vehicleDetails: vehiclesStore.getVehiclesDetails ?? [],
        bookingNumber: bookingStore.getBookingNumber,
    };

    return new Promise((resolve, reject) => {
        book(request)
            .then(() => {
                resolve(true);
            })
            .catch((err) => {
                error.value = err.message;
                reject();
            })
            .finally(() => {
                queryClient.invalidateQueries("calc");
            });
    });
};

watch(departureStore.getDepartureIdentifiers, () => {
    error.value = "";
});

__expose({
    validateStepAsync,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_2, [
        (isBusiness.value)
          ? (_openBlock(), _createElementBlock("b", _hoisted_3, _toDisplayString(_ctx.$t("agent.title")), 1))
          : _createCommentVNode("", true),
        (isAmend.value)
          ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_ctx.$t("bookingFlow.isAmend")) + " " + _toDisplayString(_unref(bookingStore).bookingNumber), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("departures.title")), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", null, [
          _createElementVNode("li", _hoisted_7, [
            _createVNode(SvgIcon, {
              icon: "carbon/car",
              width: "1.5rem",
              height: "1.5rem"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(`departures.vehicleInfo.0`)), 1)
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createVNode(SvgIcon, {
              icon: "carbon/car",
              width: "1.5rem",
              height: "1.5rem"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(`departures.vehicleInfo.1`)), 1)
          ]),
          _createElementVNode("li", _hoisted_9, [
            _createVNode(SvgIcon, {
              icon: "carbon/car",
              width: "1.5rem",
              height: "1.5rem"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(`departures.vehicleInfo.2`)), 1)
          ])
        ])
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(directions), (direction, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createVNode(DepartureSection, {
          direction: direction,
          disabled: direction === _unref(Direction).Outward && outwardIsLocked.value
        }, null, 8, ["direction", "disabled"]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", null, _toDisplayString(error.value), 1)
        ])
      ]))
    }), 128))
  ]))
}
}

})