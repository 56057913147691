import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vehicle-form" }
const _hoisted_2 = { class: "my-vehicle" }
const _hoisted_3 = {
  key: 0,
  class: "error-messages"
}

import SvgIcon from "@/components/icons/SvgIcon.vue";
import useVehicleInfo, { VehicleInfoResponse } from "@/composables/vehicle-info/useVehicleInfo";
import { MyCoVehicle } from "@/models/front/MyCoVehicle";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@/helpers/validators";
import { helpers } from "@vuelidate/validators";
import { vMaska } from "maska";
import { VBtn, VInput, VCheckbox } from "v-ferry-components";
import { Ref, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useVehiclesQuery from "@/composables/profile/useVehiclesQuery";

interface Props {
    vehicle: MyCoVehicle;
    disabled?: boolean;
    isEditing: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVehicle',
  props: {
    vehicle: {},
    disabled: { type: Boolean },
    isEditing: { type: Boolean }
  },
  emits: ["onRemoveVehicle", "onUpdateVehicle", "onEdit"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const isInitiallyDefaultVehicle = ref(props.vehicle.isDefault);

const isDisabled = ref(props.disabled);
const { t } = useI18n();
const { isLoading } = useVehiclesQuery();

const emit = __emit;

const edit = () => {

	isDisabled.value = false;
	emit('onEdit', state.vehicleId);
};

watch(() => props.isEditing, (newValue) => {
	
	if (props.isEditing === false) {
		state.licenseNumber = props.vehicle.licenseNumber;
		state.length = props.vehicle.length;
		state.width = props.vehicle.width;
		state.isDefault = props.vehicle.isDefault;
		
		isDisabled.value = true;
	}
});

const state = reactive({
    licenseNumber: props.vehicle.licenseNumber ?? "",
    length: props.vehicle.length === 0 ? "" : props.vehicle.length,
    width: props.vehicle.width === 0 ? "" : props.vehicle.width,
    isDefault: props.vehicle.isDefault,
    vehicleId: props.vehicle.vehicleId,
});

const checkLengthWidth = () => {

    if (!registeredVehicleInfo.value || 
    	state.length === undefined || 
    	state.width === undefined) {
    	
    		return;
	}
	
    if (+state.length < registeredVehicleInfo.value?.length) {
        state.length = registeredVehicleInfo.value?.length;
    }

    if (+state.width < registeredVehicleInfo.value?.width) {
        state.width = registeredVehicleInfo.value?.width;
    }
};

const rules = {
    licenseNumber: { required },
    length: {
        required,
    },
    width: {
        required,
    },
    isDefault: {
		v: helpers.withMessage("Ett fordon måste vara standardfordon", 
			(state) => {
    		
    		if (isInitiallyDefaultVehicle.value === true) {
    			if (state === false) {
    				return false;
    			}
    			else {
    				return true;
    			}
    		}
    		
    		return true;
    	})
    },
};

const v$ = useVuelidate(rules, state);

const inputLengthRef = ref();

const { getVehicleInfo, isLoading : isLoadingVehicleInfo } = useVehicleInfo();

const registeredVehicleInfo = ref<VehicleInfoResponse>();

const setVehicleReg = () => {
    
    getVehicleInfo(state.licenseNumber).then((resp) => {
        if (resp) {
        
            state.width = resp.width;
            state.length = resp.length;
            state.licenseNumber = resp.licenseNumber;
			
			inputLengthRef.value.$el.querySelector('input').focus();
			
            registeredVehicleInfo.value = resp;
        }
    });
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(VInput), {
        class: "large",
        modelValue: _unref(v$).licenseNumber.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).licenseNumber.$model) = $event)),
        label: _ctx.$t('myPages.myVehicle.reg'),
        hasError: _unref(v$).licenseNumber.$error,
        disabled: isDisabled.value,
        onFocusout: setVehicleReg
      }, null, 8, ["modelValue", "label", "hasError", "disabled"]),
      _withDirectives(_createVNode(_unref(VInput), {
        ref_key: "inputLengthRef",
        ref: inputLengthRef,
        modelValue: _unref(v$).length.$model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).length.$model) = $event)),
        label: _ctx.$t('fields.vehicleLength.label'),
        hasError: _unref(v$).length.$error,
        "data-maska": "DE.D",
        "data-maska-tokens": "D:[0-9]|E:[0-9]:optional",
        disabled: isDisabled.value || _unref(isLoadingVehicleInfo),
        onFocusout: checkLengthWidth
      }, null, 8, ["modelValue", "label", "hasError", "disabled"]), [
        [_unref(vMaska)]
      ]),
      _withDirectives(_createVNode(_unref(VInput), {
        modelValue: _unref(v$).width.$model,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).width.$model) = $event)),
        label: _ctx.$t('fields.vehicleWidth.label'),
        hasError: _unref(v$).width.$error,
        "data-maska": "DE.D",
        "data-maska-tokens": "D:[0-9]|E:[0-9]:optional",
        disabled: isDisabled.value || _unref(isLoadingVehicleInfo),
        onFocusout: checkLengthWidth
      }, null, 8, ["modelValue", "label", "hasError", "disabled"]), [
        [_unref(vMaska)]
      ]),
      _createVNode(_unref(VCheckbox), {
        id: _ctx.vehicle.vehicleId,
        modelValue: _unref(v$).isDefault.$model,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).isDefault.$model) = $event)),
        hasError: _unref(v$).isDefault.$error,
        disabled: isDisabled.value,
        label: "Standardfordon"
      }, null, 8, ["id", "modelValue", "hasError", "disabled"]),
      (isDisabled.value === true)
        ? (_openBlock(), _createBlock(_unref(VBtn), {
            key: 0,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (edit())),
            class: "edit-btn",
            type: "button",
            color: "ghost"
          }, {
            default: _withCtx(() => [
              _createVNode(SvgIcon, {
                class: "icon",
                icon: "edit"
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(VBtn), {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (emit('onRemoveVehicle', _ctx.vehicle.vehicleId))),
        class: "remove-btn",
        type: "button",
        color: "ghost"
      }, {
        default: _withCtx(() => [
          _createVNode(SvgIcon, {
            class: "icon",
            icon: "minus"
          })
        ]),
        _: 1
      }),
      (isDisabled.value === false)
        ? (_openBlock(), _createBlock(_unref(VBtn), {
            key: 1,
            type: "button",
            class: "save__btn",
            onClick: _cache[6] || (_cache[6] = ($event: any) => {emit('onUpdateVehicle', state);}),
            isLoading: _unref(isLoading)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("myPages.myProfile.save")), 1)
            ]),
            _: 1
          }, 8, ["isLoading"]))
        : _createCommentVNode("", true)
    ]),
    (_unref(v$).$errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).$errors.map((e) => e.$message))], (error) => {
            return (_openBlock(), _createElementBlock("p", {
              key: error.toString()
            }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})