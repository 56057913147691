import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "departure-dates" }
const _hoisted_2 = { class: "departure-dates__radio" }
const _hoisted_3 = ["id", "name", "value", "disabled"]
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "week-day" }
const _hoisted_6 = { class: "day" }
const _hoisted_7 = { class: "month" }

import SvgIcon from "@/components/icons/SvgIcon.vue";
import { MMMM, YYYYMMDD, dd, eeee } from "@/helpers/dateHelpers";
import { Direction } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useDepartureStore } from "@/store/departure";
import { addDays, eachDayOfInterval, startOfDay, startOfToday } from "date-fns";
import { VBtn } from "v-ferry-components";
import { computed } from "vue";

interface Props {
    direction: Direction;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DepartureDates',
  props: {
    direction: {}
  },
  setup(__props: any) {

const props = __props;

const bookingStore = useBookingStore();

const departureStore = useDepartureStore();

const minDate = computed(() => {
    if (props.direction === Direction.Outward) {
        return startOfToday();
    } else {
        const outwardDate = bookingStore.getDate(Direction.Outward);
        return outwardDate ? addDays(new Date(outwardDate), -1) : startOfToday();
    }
});

const bookingDate = computed({
    get() {
        const date = bookingStore.getDate(props.direction);
        return date ? YYYYMMDD(date) : YYYYMMDD(minDate.value);
    },
    set(date?: string) {
        const selectedDate = date ? new Date(date) : undefined;

        if (!selectedDate || selectedDate < minDate.value) return;

        bookingStore.setDate(props.direction, selectedDate);

        if (props.direction === Direction.Outward) {
            const returnDate = bookingStore.getDate(Direction.Return);

            if (!returnDate || returnDate < selectedDate) {
                bookingStore.setDate(Direction.Return, selectedDate);
            }
        }
        departureStore.setDeparture(props.direction, {
            id: "",
            identifier: "",
            date: "",
        });
    },
});

// Gets an array of 5 dates, centered on [date]
function getDateRange(date: string) {
    const selectedDate = startOfDay(new Date(date));

    return eachDayOfInterval({
        start: addDays(selectedDate, -2),
        end: addDays(selectedDate, 2),
    });
}

const dates = computed(() => getDateRange(bookingDate.value));

const setPrevDate = () => {
    bookingDate.value = YYYYMMDD(addDays(new Date(bookingDate.value), -1));
};

const setNextDate = () => {
    bookingDate.value = YYYYMMDD(addDays(new Date(bookingDate.value), 1));
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(VBtn), {
      onClick: setPrevDate,
      class: "v-btn--ghost departure-dates__prev"
    }, {
      default: _withCtx(() => [
        _createVNode(SvgIcon, {
          icon: "carbon/chevron-left",
          width: "1.875rem",
          height: "1.875rem"
        })
      ]),
      _: 1
    }),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dates.value, (date) => {
        return (_openBlock(), _createElementBlock("li", {
          key: _unref(YYYYMMDD)(date)
        }, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("input", {
              type: "radio",
              id: `dd-${_ctx.direction}-${_unref(YYYYMMDD)(date)}`,
              name: `dd-${_ctx.direction}`,
              value: _unref(YYYYMMDD)(date),
              disabled: date < minDate.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((bookingDate).value = $event))
            }, null, 8, _hoisted_3), [
              [_vModelRadio, bookingDate.value]
            ]),
            _createElementVNode("label", {
              for: `dd-${_ctx.direction}-${_unref(YYYYMMDD)(date)}`
            }, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(eeee)(date)), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(dd)(date)), 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(MMMM)(date)), 1)
            ], 8, _hoisted_4)
          ])
        ]))
      }), 128))
    ]),
    _createVNode(_unref(VBtn), {
      onClick: setNextDate,
      class: "v-btn--ghost departure-dates__next"
    }, {
      default: _withCtx(() => [
        _createVNode(SvgIcon, {
          icon: "carbon/chevron-right",
          width: "1.875rem",
          height: "1.875rem"
        })
      ]),
      _: 1
    })
  ]))
}
}

})