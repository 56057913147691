<template>
    <VCard class="boarding-card">
        <BaseLoader v-if="!tickets" />
        <div v-else>
            <h2>{{ $t("thankyou.booking.title") }}</h2>
            <Swiper
                navigation
                :initialSlide="initialSlide"
                :breakpoints="breakpoints"
                :key="initialSlide"
                :centeredSlides="true"
                :pagination="{ clickable: true }"
                :modules="swiperModules"
            >
                <SwiperSlide v-for="(boardingCard, index) in tickets" :key="index" :virtualIndex="index" class="boarding-card__wrapper">
                    <div class="receipt" :class="{ 'disabled' : boardingCard.isValid !== true }">
                        <div class="receipt__qr-code">
                            <QrcodeVue :value="boardingCard.bookingNumber.toString()" :size="100" />
                        </div>
                        
                        <div class="receipt__description">{{ $t("checkin.boardingCard.bookingNr") }}: {{ boardingCard.bookingNumber }}</div>
                        <div class="receipt__expired" v-if="boardingCard.usedAt">{{ $t("checkin.boardingCard.expiredAt") }}: {{ boardingCard.usedAt }}</div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div class="boarding-card__footer">
                <a href="#" @click="print()">{{ $t(`checkin.boardingCard.print`) }}</a>
                <a v-if="tickets.length > 1" href="#" @click="print(true)">{{ $t(`checkin.boardingCard.printAll`) }}</a>
            </div>
        </div>
    </VCard>
</template>

<script lang="ts" setup>
import useRuntimeConfig from "@/composables/useRuntimeConfig";
import { BookingComplete } from "@/models/front/BookingComplete";
import QrcodeVue from "qrcode.vue";
import { A11y, Navigation, Pagination, Virtual } from "swiper/modules";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref, computed } from "vue";
import { VCard } from "v-ferry-components";
import BaseLoader from "@/components/base/BaseLoader.vue";

const swiperModules = [Navigation, A11y, Virtual, Pagination];

interface Props {
    tickets?: BookingComplete[];
}

const props = defineProps<Props>();

const initialSlide = computed(() => {

	return props.tickets?.indexOf(props.tickets?.find(ticket => ticket.isValid === true));
});

const breakpoints = {
    0: { slidesPerView: 1 },
};

const { getRuntimeConfig } = useRuntimeConfig();

const baseApiUrl = ref("");
getRuntimeConfig().then((r) => {
    baseApiUrl.value = r.apiBaseUrl;
});

const print = (printAll = false) => {
    if (printAll) {
        document.getElementsByClassName("swiper-wrapper")[0].classList.add("print-all");
    } else {
        document.getElementsByClassName("swiper-wrapper")[0].classList.remove("print-all");
    }

    window.print();
};
</script>

<style lang="scss" scoped>
.loader {
    height: 250px;
}

.boarding-card {
    $prefix: ".boarding-card";
    max-width: 400px;
    min-height: 300px;
    width: 100%;
    border-radius: 5px;
    padding: 15px;
    margin: 20px auto;

    h2 {
        margin-bottom: 15px;
        text-align: center;
    }
    &__wrapper {
        height: auto;
    }

    &__header {
        margin-bottom: 15px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;

        > a[href] {
            color: var(--c-black);
            text-decoration: underline;
        }

        @media print {
            display: none;
        }
    }
}

.receipt {
    $prefix: ".receipt";
    display: flex;
    flex-direction: column;
    height: calc(100% - 45px);

	&.disabled {
    	opacity: 0.5;
    }

    #{$prefix}__qr-code {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        
        img {
            width: 100px;
            height: 100px;
        }
    }

    #{$prefix}__description {
        margin-top: 30px;
        margin-bottom: 10px;
        text-align: center;
    }
    
    #{$prefix}__expired {
    	font-size: smaller;
    	text-align: center;
    }
}

:deep() .swiper {
    $prefix: ".swiper";

    &-button-prev,
    &-button-next {
        display: none;
    }

    &-pagination {
        position: initial;
        margin-top: 15px;

        #{$prefix}-pagination-bullet {
            margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 5px);
            width: 14px;
            height: 14px;
            background-color: var(--c-primary);

            &-active {
                background-color: var(--c-primary);
            }
        }
    }

    @media print {
        &-wrapper {
            &.print-all {
                display: block !important;
                transform: translate3d(0px, 0px, 0px) !important;
                transition-duration: 0ms !important;

                #{$prefix}-slide {
                    page-break-before: always !important;
                }
            }
        }
    }
}
</style>
