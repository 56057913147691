<template>
    <div class="tickets">
        <div class="prose">
            <div class="subtitle">
                <b v-if="isBusiness" class="agent-title">{{ t("agent.title") }}</b>
                <b v-if="isAmend" class="agent-title">{{ t("bookingFlow.isAmend") }} {{ bookingStore.bookingNumber }}</b>
            </div>
            <h1 class="h2">{{ t("ticketSelect.title") }}</h1>
            <p>{{ t("ticketSelect.info.price") }}</p>
            <p>{{ t("ticketSelect.info.vehicle") }}</p>
        </div>

        <div class="ticket-select">
            <div class="ticket-select__option">
                <TicketsSelectOption
                    class="vehicle-selection"
                    v-model="v$.vehicle.$model"
                    icon="car"
                    :max="1"
                    :title="t('ticketSelect.label.vehicle')"
                    unelevated
                    hideQuantity
                />
                <div class="vehicles">
                    <VCard class="vehicles-item vehicles-item__active car" unelevated bordered>
                        <VehicleSelect :vehicles="data" ref="vehicleRef" :isBusiness="isBusiness" v-if="isLoading === false" />
                    </VCard>
                </div>
            </div>

            <div class="ticket-select__option">
                <TicketsSelectOption
                    class="vehicle-selection"
                    v-model="v$.trailer.$model"
                    icon="carbon/trailer"
                    :max="1"
                    :min="1"
                    :title="t('ticketSelect.label.trailer')"
                    unelevated
                />
                <div class="vehicles">
                    <VCard v-if="state.trailer > 0" class="vehicles-item vehicles-item__active car" unelevated bordered>
                    
                        <VehicleSelect 
                        	ref="trailerRef" 
                        	isTrailer 
                        	:vehicles="data" 
                        	:hasNoLicense="vehiclesStore.trailer.length > 0 && (vehiclesStore.trailer.license?.length ?? 0) === 0"
                        	:isBusiness="isBusiness" 
                        	v-if="isLoading === false" 
                        />
                    </VCard>
                </div>
            </div>

            <div class="ticket-select__option">
                <TicketsSelectOption v-model="v$.adult.$model" icon="person" :title="t('ticketSelect.label.adult')" unelevated />
            </div>
        </div>
        <div v-if="v$.adult.$error" class="error-messages">
            <p v-for="error in [...new Set(v$.adult.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import useVehiclesQuery from "@/composables/profile/useVehiclesQuery";
import { useBookingStore } from "@/store/booking";
import { usePassengersStore } from "@/store/passengers";
import { useVehiclesStore } from "@/store/vehicles";
import useVuelidate from "@vuelidate/core";
import { VCard } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import TicketsSelectOption from "./TicketsSelectOption.vue";
import VehicleSelect from "./VehicleSelect.vue";

const bookingStore = useBookingStore();
const isBusiness = computed(() => bookingStore.isBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const { t } = useI18n();

const passengerStore = usePassengersStore();
const vehiclesStore = useVehiclesStore();

const { data, isLoading } = useVehiclesQuery();

const state = reactive({
    vehicle: 1,
    trailer: vehiclesStore.trailer?.length > 0 ? 1 : 0,
    adult: passengerStore.adult ?? 0,
});

const rules = {
    vehicle: {},
    trailer: {},
    adult: {},
};

const v$ = useVuelidate(rules, state);

const vehicleRef = ref();
const trailerRef = ref();

const persistState = () => {
    const vehicles = {
        car: state.vehicle > 0 ? vehicleRef.value.state : undefined,
        trailer: state.trailer > 0 ? trailerRef.value.state : undefined,
    };

    passengerStore.setPassengers(state.adult);
    vehiclesStore.setVehicles(vehicles);
};

defineExpose({
    persistState,
});
</script>

<style scoped lang="scss">
.error-messages {
    margin-top: 10px;
}

.tickets {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .subtitle {
        display: flex;
        justify-content: space-between;
    }
}

.ticket-select {
    display: flex;
    justify-content: space-between;
    gap: 15px;

    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }

    &__option {
        flex: 1;

        .vehicle-selection.is-active {
            border-radius: 5px 5px 0px 0px;
            border-bottom-color: var(--c-border);
        }

        .vehicles {
            .car {
                border-top: none;
                background-color: var(--c-blue-bg);
                border-radius: 0px 0px 5px 5px;
            }

            .trailer {
                margin-top: 15px;

                :deep(.v-btn) {
                    width: 100%;
                    color: var(--c-prose-body);

                    .v-btn__content {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }

            &-item {
                padding: 0;
                border-radius: 5px;
                border-color: var(--c-border);

                &__active {
                    background-color: var(--c-blue-bg);
                    border-color: var(--c-primary);
                }
            }
        }
    }
}
</style>
