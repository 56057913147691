import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, createBlock as _createBlock, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "site-header"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "banner"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "mobile-only"
}
const _hoisted_6 = {
  key: 2,
  class: "mobile-only"
}
const _hoisted_7 = {
  key: 3,
  class: "mobile-only"
}
const _hoisted_8 = { class: "tablet-link" }
const _hoisted_9 = { class: "menu-dropdown" }
const _hoisted_10 = {
  key: 4,
  class: "mobile-link"
}
const _hoisted_11 = {
  key: 5,
  class: "mobile-link"
}

import SvgIcon from "@/components/icons/SvgIcon.vue";
import SvgSprite from "@/components/icons/SvgSprite.vue";
import { VBtn, VMenu } from "v-ferry-components";
import { computed, ref } from "vue";
import Dialog from "./components/base/Dialog.vue";
import Snackbar from "./components/base/Snackbar.vue";
import useLogout from "./composables/auth/useLogout";
import { useUserStore } from "./store/user";
import { useRoute } from "vue-router";
import { watch } from "vue";
import useRuntimeConfig from "@/composables/useRuntimeConfig";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const banner = ref("");
const { getRuntimeConfig } = useRuntimeConfig();

getRuntimeConfig().then((resp) => {
    banner.value = resp.env;
});

const isApp = computed(() => navigator.userAgent.includes("OrnoApp v1.0"));

const isMenuless = computed(() => navigator.userAgent.includes("OrnoApp v1.0 noMenu"));

const isLoggedIn = computed(() => useUserStore().isLoggedIn);

const isAgent = computed(() => useUserStore().getUserType === 2);

const isMenuOpen = ref(false);

const isAccountMenuOpen = ref(false);

const { logout } = useLogout();

const route = useRoute();

watch(route, () => {
    setTimeout(() => {
        isMenuOpen.value = false;
        isAccountMenuOpen.value = false;
    }, 10);
});

const isTimeTableView = computed(() => route.path === "/timetable");
const isBookingView = computed(() => route.path === "/" || "/company" || "/open-ticket");

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isMenuless.value !== true)
      ? (_openBlock(), _createElementBlock("header", _hoisted_1, [
          _createElementVNode("nav", _hoisted_2, [
            _createVNode(_component_RouterLink, {
              to: isAgent.value ? '/company' : '/',
              class: "logo"
            }, {
              default: _withCtx(() => [
                (banner.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(banner.value), 1))
                  : _createCommentVNode("", true),
                _cache[7] || (_cache[7] = _createTextVNode(" Ornöfärjans Samfällighetsförening"))
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("ul", {
              class: _normalizeClass({ 'mobile-menu': isMenuOpen.value })
            }, [
              _createElementVNode("li", {
                class: _normalizeClass({ 'desktop-only': !isTimeTableView.value })
              }, [
                _createVNode(_component_RouterLink, {
                  to: isAgent.value ? '/company' : '/'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.home")), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ], 2),
              (isApp.value !== true)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                    _createVNode(_component_RouterLink, { to: "/timetable" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.timetable")), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (isBookingView.value && isApp.value !== true)
                ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                    _createVNode(_component_RouterLink, { to: "/open-ticket" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.openTicket")), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (isBookingView.value)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _createVNode(_component_RouterLink, { to: "/company" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.agentBooking")), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (isAgent.value === false && isBookingView.value)
                ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                    _createVNode(_component_RouterLink, { to: "/" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.customerBooking")), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", _hoisted_8, [
                (isLoggedIn.value)
                  ? (_openBlock(), _createBlock(_unref(VMenu), {
                      key: 0,
                      isOpen: isAccountMenuOpen.value,
                      onClose: _cache[1] || (_cache[1] = ($event: any) => (isAccountMenuOpen.value = false)),
                      alignRight: ""
                    }, {
                      trigger: _withCtx(() => [
                        _createVNode(_unref(VBtn), {
                          color: "ghost",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (isAccountMenuOpen.value = !isAccountMenuOpen.value))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(SvgIcon, {
                              height: "1.5rem",
                              width: "1.5rem",
                              icon: "carbon/user"
                            }),
                            _createVNode(SvgIcon, {
                              height: "1.5rem",
                              width: "1.5rem",
                              icon: "carbon/chevron-down"
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_RouterLink, {
                            activeClass: "",
                            to: "/my-pages"
                          }, {
                            default: _withCtx(({ navigate }) => [
                              _createVNode(_unref(VBtn), {
                                onClick: navigate,
                                color: "ghost"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.myPages")), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_unref(VBtn), {
                            onClick: _withModifiers(_unref(logout), ["prevent"]),
                            color: "ghost"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.logout")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["isOpen"]))
                  : (_openBlock(), _createBlock(_component_RouterLink, {
                      key: 1,
                      to: "/login"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.login")), 1)
                      ]),
                      _: 1
                    }))
              ]),
              (isLoggedIn.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("li", null, [
                      _createVNode(_component_RouterLink, { to: "/my-pages" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.myPages")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_unref(logout) && _unref(logout)(...args)), ["prevent"]))
                      }, _toDisplayString(_ctx.$t("menuLinks.logout")), 1)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("li", _hoisted_11, [
                    _createVNode(_component_RouterLink, { to: "/login" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.login")), 1)
                      ]),
                      _: 1
                    })
                  ]))
            ], 2),
            (!isMenuOpen.value)
              ? (_openBlock(), _createBlock(_unref(VBtn), {
                  key: 0,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (isMenuOpen.value = true)),
                  class: "v-btn--ghost menu-toggle"
                }, {
                  default: _withCtx(() => [
                    _createVNode(SvgIcon, {
                      icon: "carbon/menu",
                      width: "2.5rem",
                      height: "2.5rem"
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_unref(VBtn), {
                  key: 1,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (isMenuOpen.value = false)),
                  class: "v-btn--ghost menu-close"
                }, {
                  default: _withCtx(() => [
                    _createVNode(SvgIcon, {
                      icon: "carbon/close",
                      width: "2.5rem",
                      height: "2.5rem"
                    })
                  ]),
                  _: 1
                }))
          ])
        ]))
      : _createCommentVNode("", true),
    (isMenuOpen.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: _cache[5] || (_cache[5] = ($event: any) => (isMenuOpen.value = false)),
          class: "overlay"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("main", {
      onClick: _cache[6] || (_cache[6] = ($event: any) => (isMenuOpen.value = false)),
      class: "site-main"
    }, [
      _createVNode(_component_RouterView),
      _createVNode(SvgSprite)
    ]),
    _cache[8] || (_cache[8] = _createStaticVNode("<footer class=\"site-footer\"><div class=\"site-footer__content container\"><div class=\"section\"><h2>Ornö Sjötrafik AB</h2><div class=\"text\"><span> Brunnsviken </span><span>130 55 Ornö</span></div></div><div class=\"section\"><h2>Kontakt</h2><div class=\"text\"><span>Telefon: 08 – 501 566 00 </span><span>E-post: kontoret@ornosjotrafik.se</span></div></div></div></footer>", 1)),
    _createVNode(Dialog),
    _createVNode(Snackbar)
  ], 64))
}
}

})