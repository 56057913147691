<template>
    <div class="timetable-details">
        <div>
            <h1 class="h3">Turlista</h1>
            <div class="messages">
                <span v-for="(info, index) in timeTable.information" :key="index">{{ info }}</span>
            </div>
        </div>
        <div class="regular">
            <h5>Turlista fr om {{ timeTable.startDate }} t o m {{ timeTable.endDate }}</h5>
            <div class="table">
                <div v-for="(item, index) in timeTable.regularSchedule" :key="index" class="table-item">
                    <div class="table-item__name">{{ item.days }}</div>
                    <div class="table-item__time">
                        <div v-for="(time, index) in item.times" :key="index" class="time">{{ time.time }}<span v-if="time.info">*</span></div>
                    </div>
                </div>
            </div>
            <div class="messages">
                <span v-for="(message, index) in messages" :key="index">* {{ message }}</span>
            </div>
        </div>
        <div class="special">
            <h5>Särskilda turer för {{ timeTable.specialDays.holidayNames }}</h5>
            <div class="table">
                <div v-for="(holiday, index) in timeTable.specialDays.holidays" :key="index" class="table-item">
                    <div class="table-item__name">
                        {{ holiday.days }}
                    </div>
                    <div class="table-item__time">
                        <div v-for="(time, i) in holiday.times" :key="i" class="time">
                            {{ time }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { TimeTable } from "@/models/front/Timetable";

interface Props {
    timeTable: TimeTable;
}

const props = defineProps<Props>();

const messages = computed(() => {
    const array: string[] = [];
    props.timeTable.regularSchedule.forEach((schedule) => {
        schedule.times.forEach((timeEntry) => {
            if (timeEntry.info !== "") {
                array.push(timeEntry.info);
            }
        });
    });
    return array;
});
</script>

<style scoped lang="scss">
.timetable-details {
    display: flex;
    flex-direction: column;
    gap: 50px;

    h5 {
        margin-bottom: 20px;
    }

    .messages {
        margin: 10px 0;
    }

    .table {
        display: flex;
        flex-direction: column;

        &-item {
            padding: 15px 5px;
            display: flex;
            gap: 30px;

            &:nth-child(odd) {
                background-color: var(--c-bg);
            }
            &:not(:last-child) {
                border-bottom: 1px solid #d2d7df;
            }

            &__name {
                min-width: 120px;
            }

            &__time {
                display: flex;
                flex-flow: row wrap;
                width: 100%;

                .time {
                    width: 25%;
                    margin-bottom: 15px;
                }
            }

            @include media-breakpoint-down(sm) {
                &__name {
                    min-width: 100px;
                }
                .time {
                    width: 60px;
                }
            }
        }
    }

    .special {
        .table {
            &-item {
                padding: 10px 5px;
                &__name {
                    min-width: 180px;
                }

                &__time {
                    gap: 10px;
                    .time {
                        width: 30%;
                        margin-bottom: 0px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    &__name {
                        width: 100%;
                        min-width: 130px;
                        max-width: 130px;
                    }
                    .time {
                        width: 60px;
                    }
                }
            }
        }
    }
}
</style>
