<template>
    <div class="alert" role="alert" :class="[`alert--${type}`, { 'alert--no-margin': noMargin }]">
        <SvgIcon :icon="`carbon/${type}`" />
        <span class="sr-only">{{ type }}</span>
        <div>
            <p>
                {{ message }}
            </p>
        </div>
        <VBtn v-if="closable" @click="close" class="close" color="ghost">✕</VBtn>
    </div>
</template>

<script lang="ts" setup>
import SvgIcon from "../icons/SvgIcon.vue";
import { VBtn } from "v-ferry-components";

interface Props {
    message: string;
    type: string;
    closable?: boolean;
    id?: string;
    noMargin?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["close"]);
const close = () => emit("close", props.id);
</script>

<style lang="scss" scoped>
.sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.alert {
    display: flex;
    position: relative;
    margin: 15px 0;
    padding: 1rem;
    background-color: var(--c-base);
    border-radius: 0.5rem;

    p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin-top: 0.125rem;
        margin-right: 20px;
    }

    .icon {
        display: inline;
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 1rem;
    }

    .close {
        position: absolute;
        right: 0px;

        cursor: pointer;
        opacity: 0.5;
        border: none;
        top: 5px;
        bottom: 5px;
    }

    &--danger {
        background: #fef8f7;
        color: darken(#af1b1b, 10);
    }

    &--info {
        background: var(--c-bg);
        color: var(--c-primary);
    }

    &--success {
        background: #f2f6e5;
        color: darken(#119b24, 15);
    }

    &--no-margin {
        margin: 0;
    }
}
</style>
