<template>
    <div class="login-page">
        <div class="login-page__background"></div>
        <div class="login-form">
            <VCard v-if="!createNewAccount">
                <div class="prose">
                    <h1 class="h4">{{ $t("loginPage.title.customer") }}</h1>
                    <p>
                        Logga in för att se, ändra och avboka dina bokningar. Du kan även se aktuella saldon och fylla på dina resekort, samt
                        ändra uppgifter för ditt konto. Vi önskar dig en trevlig resa!
                    </p>
                    <p>Har du inget konto? <a @click.prevent="createNewAccount = true" href="#">Klicka här för att skapa ett</a></p>
                </div>
                <br />
                <LoginForm hideCreateAccount @onLogin="goToBooking" :isAgent="false" />
                
                <hr />
                <span>Är du företagskund? Klicka <router-link to="/agent-login" >här</router-link> för att logga in</span>
            </VCard>
            <VCard v-else>
                <div class="prose">
                    <h1 class="h4">{{ $t("createAccount.title") }}</h1>
                </div>
                <CustomerDetailsForm @submit="submit" forceCreateAccount @abort="createNewAccount = false">
                    <template #header>
                        <div class="prose">
                            <p>
                                {{ $t("customerDetails.haveAccount") }}
                                <a href="#" @click="createNewAccount = false">{{ $t("customerDetails.login") }} </a>
                            </p>
                        </div>
                    </template>
                    <template #footer>
                        <div class="error-messages">
                            <p>{{ error }}</p>
                        </div>
                        <VBtn :isLoading="isLoading">{{ $t("createAccount.btn") }}</VBtn>
                    </template>
                </CustomerDetailsForm>
            </VCard>
        </div>
    </div>
</template>

<script setup lang="ts">
import LoginForm from "@/components/login/LoginForm.vue";
import { VBtn, VCard } from "v-ferry-components";
import { ref } from "vue";
import { useRouter } from "vue-router";
import CustomerDetailsForm, { CustomerDetailsFormState } from "../BookingFlow/components/customerDetails/CustomerDetailsForm.vue";
import useCreateAccount from "@/composables/auth/useCreateAccount";
import { useBookingStore } from "@/store/booking";

const router = useRouter();

const createNewAccount = ref(false);
const bookingStore = useBookingStore();

const { createCustomer, isLoading } = useCreateAccount();

const error = ref("");

const submit = (state: CustomerDetailsFormState) => {
    createCustomer(state)
        .then(() => goToBooking())
        .catch((err) => {
            error.value = err.message;
        });
};

const goToBooking = (state) => {
	
	bookingStore.setRoute(state?.defaultRoute);
	
    router.push(state?.type === 'Agent' ? "/company" : "/");
};
</script>

<style lang="scss" scoped>
.login-page {
    position: relative;
    width: 100%;
    min-height: 95.5vh;
    background: url("@/assets/images/dalaro-skans.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;

    &__background {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.15);
    }

    .login-form {
        margin: 10vh 0;
        width: 90vw;
        max-width: 600px;
        z-index: 1;
        
        .v-card > hr {
    		margin-top: 20px;
    		margin-bottom: 15px;
    	}
    }

    h2 {
        margin-bottom: 20px;
    }
}
</style>
