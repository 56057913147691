<template>
    <header class="site-header" v-if="isMenuless !== true">
        <nav class="container">
            <RouterLink :to="isAgent ? '/company' : '/'" class="logo">
                <span class="banner" v-if="banner">{{ banner }}</span>
                Ornöfärjans Samfällighetsförening</RouterLink
            >
            <ul :class="{ 'mobile-menu': isMenuOpen }">
                <li :class="{ 'desktop-only': !isTimeTableView }">
                    <RouterLink :to="isAgent ? '/company' : '/'">{{ $t("menuLinks.home") }}</RouterLink>
                </li>
                <li v-if="isApp !== true">
                    <RouterLink to="/timetable">{{ $t("menuLinks.timetable") }}</RouterLink>
                </li>
                <li v-if="isBookingView && isApp !== true" class="mobile-only">
                    <RouterLink to="/open-ticket">{{ $t("menuLinks.openTicket") }}</RouterLink>
                </li>
                <li v-if="isBookingView" class="mobile-only">
                    <RouterLink to="/company">{{ $t("menuLinks.agentBooking") }}</RouterLink>
                </li>
                <li v-if="isAgent === false && isBookingView" class="mobile-only">
                    <RouterLink to="/">{{ $t("menuLinks.customerBooking") }}</RouterLink>
                </li>
                <li class="tablet-link">
                    <VMenu v-if="isLoggedIn" :isOpen="isAccountMenuOpen" @close="isAccountMenuOpen = false" alignRight>
                        <template #trigger>
                            <VBtn color="ghost" @click="isAccountMenuOpen = !isAccountMenuOpen">
                                <SvgIcon height="1.5rem" width="1.5rem" icon="carbon/user" />
                                <SvgIcon height="1.5rem" width="1.5rem" icon="carbon/chevron-down" />
                            </VBtn>
                        </template>
                        <div class="menu-dropdown">
                            <RouterLink activeClass="" to="/my-pages">
                                <template #default="{ navigate }">
                                    <VBtn @click="navigate" color="ghost">
                                        {{ $t("menuLinks.myPages") }}
                                    </VBtn>
                                </template>
                            </RouterLink>
                            <VBtn @click.prevent="logout" color="ghost">
                                {{ $t("menuLinks.logout") }}
                            </VBtn>
                        </div>
                    </VMenu>
                    <RouterLink v-else to="/login">{{ $t("menuLinks.login") }}</RouterLink>
                </li>
                <div v-if="isLoggedIn" class="mobile-link">
                    <li>
                        <RouterLink to="/my-pages">{{ $t("menuLinks.myPages") }}</RouterLink>
                    </li>
                    <li>
                        <a href="#" @click.prevent="logout">{{ $t("menuLinks.logout") }}</a>
                    </li>
                </div>
                <li v-else class="mobile-link">
                    <RouterLink to="/login">{{ $t("menuLinks.login") }}</RouterLink>
                </li>
            </ul>
            <VBtn v-if="!isMenuOpen" @click="isMenuOpen = true" class="v-btn--ghost menu-toggle">
                <SvgIcon icon="carbon/menu" width="2.5rem" height="2.5rem" />
            </VBtn>
            <VBtn v-else @click="isMenuOpen = false" class="v-btn--ghost menu-close">
                <SvgIcon icon="carbon/close" width="2.5rem" height="2.5rem" />
            </VBtn>
        </nav>
    </header>
    <div v-if="isMenuOpen" @click="isMenuOpen = false" class="overlay" />
    <main @click="isMenuOpen = false" class="site-main">
        <RouterView />
        <SvgSprite />
    </main>
    <footer class="site-footer">
        <div class="site-footer__content container">
            <div class="section">
                <h2>Ornö Sjötrafik AB</h2>
                <div class="text">
                    <span> Brunnsviken </span>
                    <span>130 55 Ornö</span>
                </div>
            </div>
            <div class="section">
                <h2>Kontakt</h2>
                <div class="text">
                    <span>Telefon: 08 – 501 566 00 </span>
                    <span>E-post: kontoret@ornosjotrafik.se</span>
                </div>
            </div>
        </div>
    </footer>
    <Dialog />
    <Snackbar />
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import SvgSprite from "@/components/icons/SvgSprite.vue";
import { VBtn, VMenu } from "v-ferry-components";
import { computed, ref } from "vue";
import Dialog from "./components/base/Dialog.vue";
import Snackbar from "./components/base/Snackbar.vue";
import useLogout from "./composables/auth/useLogout";
import { useUserStore } from "./store/user";
import { useRoute } from "vue-router";
import { watch } from "vue";
import useRuntimeConfig from "@/composables/useRuntimeConfig";

const banner = ref("");
const { getRuntimeConfig } = useRuntimeConfig();

getRuntimeConfig().then((resp) => {
    banner.value = resp.env;
});

const isApp = computed(() => navigator.userAgent.includes("OrnoApp v1.0"));

const isMenuless = computed(() => navigator.userAgent.includes("OrnoApp v1.0 noMenu"));

const isLoggedIn = computed(() => useUserStore().isLoggedIn);

const isAgent = computed(() => useUserStore().getUserType === 2);

const isMenuOpen = ref(false);

const isAccountMenuOpen = ref(false);

const { logout } = useLogout();

const route = useRoute();

watch(route, () => {
    setTimeout(() => {
        isMenuOpen.value = false;
        isAccountMenuOpen.value = false;
    }, 10);
});

const isTimeTableView = computed(() => route.path === "/timetable");
const isBookingView = computed(() => route.path === "/" || "/company" || "/open-ticket");
</script>

<style lang="scss">
@import "@/css/style.scss";

.site-header {
    position: sticky;
    top: 0;
    padding: var(--site-header__padding);
    width: 100%;
    background-color: var(--c-base);
    box-shadow: var(--box-shadow);
    z-index: 100;
    position: relative;

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    a[href] {
        color: var(--c-primary);
        font-weight: 600;
        text-decoration: none;

        .banner {
            transform: rotate(-45deg);
            display: inline-block;
            position: relative;
            left: 8px;
            top: -8px;
        }
    }

    ul {
        display: none;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        position: relative;

        @include media-breakpoint-up(lg) {
            display: flex;
        }

        .mobile-only {
            display: none;
        }

        .desktop-only {
            display: flex;
        }

        li {
            display: flex;
            align-items: center;
            position: relative;

            > a[href] {
                padding: 10px;
            }

            + li {
                &::before {
                    content: "";
                    display: block;
                    margin: 0 20px;
                    height: 1.25rem;
                    width: 1px;
                    background-color: var(--c-primary);
                }
            }

            .menu-dropdown {
                display: flex;
                flex-direction: column;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                .v-btn {
                    white-space: nowrap;
                    border-radius: 0;
                }
            }
        }

        .mobile-link {
            display: flex;
            flex-direction: column;
            gap: 10px;
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        .tablet-link {
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        .router-link-active {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                border-bottom: 2px solid var(--c-primary);
                left: 10px;
                right: 10px;
                bottom: 4px;
            }
        }
    }

    .mobile-menu {
        @include media-breakpoint-down(lg) {
            position: absolute;
            left: 0;
            top: 100%;
            background-color: var(--c-base);
            width: 100%;
            box-shadow: var(--box-shadow);
            border-top: 1px solid var(--c-border);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            padding: 20px;

            a {
                padding: 10px;
            }

            li {
                &::before {
                    display: none;
                }
            }
        }

        .mobile-only {
            display: flex;
        }
        .desktop-only {
            display: none;
        }
    }

    .menu-toggle {
        padding: 0;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .menu-close {
        padding: 0;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.overlay {
    display: none;
    @include media-breakpoint-down(lg) {
        display: block;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: var(--v-modal-overlay-bg);
        z-index: 11;
    }
}

.site-footer {
    background-color: var(--c-primary);
    padding: 30px;

    * {
        color: var(--c-base);
    }
    &__content {
        padding: 0px;
        display: flex;
        justify-content: space-between;

        max-width: 920px;
        width: 100%;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            width: 80%;
        }
        @include media-breakpoint-down(md) {
            gap: 15px;
            flex-direction: column;
        }

        .section {
            h2 {
                margin-bottom: 8px;
            }
            .text {
                display: flex;
                flex-direction: column;
                gap: 3px;
            }
        }
    }
}
</style>
