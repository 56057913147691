import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "snackbar" }

import { eventBusKey } from "@/models/Symbols/Eventbus";
import { SnackbarMessage, snackbarMessageKey } from "@/models/Symbols/Snackbar";
import { inject, ref } from "vue";
import BaseAlert from "./BaseAlert.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Snackbar',
  setup(__props) {

const messages = ref<SnackbarMessage[]>([]);

const close = (id: string) => (messages.value = messages.value.filter((v) => v.id !== id));

const bus = inject(eventBusKey);

bus?.on(snackbarMessageKey, (event) => {
    const e = event as SnackbarMessage;
    //Avoid duplicate errors (if two api calls fail simultaneously for example)
    if (!messages.value.find((m) => m.message === e.message)) {
        messages.value.push(e);
        setTimeout(() => {
            close(e.id);
        }, 3000);
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "fade" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (message, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "snack",
            key: index
          }, [
            _createVNode(BaseAlert, {
              message: message.message,
              type: message.type,
              closable: true,
              id: message.id,
              onClose: close
            }, null, 8, ["message", "type", "id"])
          ]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}
}

})