import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sr-only" }

import SvgIcon from "../icons/SvgIcon.vue";
import { VBtn } from "v-ferry-components";

interface Props {
    message: string;
    type: string;
    closable?: boolean;
    id?: string;
    noMargin?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseAlert',
  props: {
    message: {},
    type: {},
    closable: { type: Boolean },
    id: {},
    noMargin: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const close = () => emit("close", props.id);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert", [`alert--${_ctx.type}`, { 'alert--no-margin': _ctx.noMargin }]]),
    role: "alert"
  }, [
    _createVNode(SvgIcon, {
      icon: `carbon/${_ctx.type}`
    }, null, 8, ["icon"]),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.type), 1),
    _createElementVNode("div", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
    ]),
    (_ctx.closable)
      ? (_openBlock(), _createBlock(_unref(VBtn), {
          key: 0,
          onClick: close,
          class: "close",
          color: "ghost"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("✕")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 2))
}
}

})