import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "prose" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = {
  key: 0,
  class: "agent-title"
}
const _hoisted_4 = {
  key: 1,
  class: "agent-title"
}
const _hoisted_5 = { class: "h2" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 1,
  class: "row"
}
const _hoisted_9 = {
  key: 2,
  class: "row"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = {
  key: 3,
  class: "error-messages"
}
const _hoisted_13 = {
  key: 4,
  class: "error-messages"
}

import { BookingDetails } from "@/models/front/BookingDetails";
import { useBookingStore } from "@/store/booking";
import useVuelidate from "@vuelidate/core";
import { email, helpers, requiredIf, sameAs } from "@vuelidate/validators";
import { required } from "@/helpers/validators";
import { vMaska } from "maska";
import { VCheckbox, VInput } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
    forceCreateAccount?: boolean;
    createAccount?: boolean;
    isLoggedIn?: boolean;
    details?: BookingDetails;
    newEmail?: string;
}

export type CustomerDetailsFormState = {
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    address: string;
    zip: string;
    city: string;
    mobilePhone: string;
    confirmEmail: string;
    password: string;
    passwordRepeat: string;
    gdprConsent: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerDetailsForm',
  props: {
    forceCreateAccount: { type: Boolean },
    createAccount: { type: Boolean },
    isLoggedIn: { type: Boolean },
    details: {},
    newEmail: {}
  },
  emits: ["abort", "submit"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const bookingStore = useBookingStore();
const isAgent = computed(() => bookingStore.getIsBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const props = __props;

const emit = __emit;

const { t } = useI18n();

const createNewAccount = ref(props.forceCreateAccount || props.createAccount);

const state = reactive<CustomerDetailsFormState>({
    email: props.details?.email ?? props.newEmail ?? "",
    firstName: "",
    lastName: "",
    name: props.details?.name ?? "",
    address: props.details?.address ?? "",
    zip: props.details?.zip ?? "",
    city: props.details?.city ?? "",
    mobilePhone: props.details?.mobilePhone ?? "",
    confirmEmail: props.details?.email ?? "",
    password: "",
    passwordRepeat: "",
    gdprConsent: bookingStore.termsAccepted,
});

const validPassword = () => {
    return (state.password.length <= 10 && state.password.length >= 8) || !createNewAccount.value;
};

const requiredIfNewAccount = requiredIf(() => createNewAccount.value);

const notRequiredIfNewAccount = requiredIf(() => !createNewAccount.value);

const rules = {
    email: {
        required: helpers.withMessage("Du måste ange e-post", required),
        v: helpers.withMessage("E-post måste vara en giltig e-post adress", email),
    },
    confirmEmail: {
        v: helpers.withMessage(
            "E-postadressen måste vara samma i båda fälten",
            (val: string) => props.isLoggedIn === true || (val === state.email && val.toString().length > 0),
        ),
    },
    firstName: { v: helpers.withMessage(t("Förnamn krävs"), requiredIfNewAccount) },
    lastName: { v: helpers.withMessage(t("Efternamn krävs"), requiredIfNewAccount) },
    name: { v: helpers.withMessage(t("Namn krävs"), notRequiredIfNewAccount) },
    address: {
        required: helpers.withMessage(() => "Du måste ange adress", requiredIfNewAccount),
    },
    zip: {
        required: helpers.withMessage(() => "Du måste ange postnummer", requiredIfNewAccount),
        valid: helpers.withMessage(
            () => "Postnummer måste vara 6 tecken långt",
            (val: number) => !createNewAccount.value || val.toString().length === 6,
        ),
    },
    city: {
        required: helpers.withMessage(() => "Du måste ange stad", requiredIfNewAccount),
    },
    mobilePhone: {
        required: helpers.withMessage(
            () => "Du måste ange telefonnummer",
            (val: number) => !createNewAccount.value || val.toString().length > 6,
        ),
    },
    password: { v: helpers.withMessage(t("errorMessages.password"), validPassword) },
    passwordRepeat: { v: helpers.withMessage(t("errorMessages.passwordRepeat"), (val: string) => val === state.password) },
    gdprConsent: { v: helpers.withMessage("Du måste godtycka till att dina personuppgifter sparas", sameAs(true)) },
};

const v$ = useVuelidate(rules, state);

const error = ref("");
const create = async () => {
    const valid = await v$.value.$validate();

    if (!valid) return;
    error.value = "";

    emit("submit", state);
};

__expose({
    createNewAccount,
    state,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _withModifiers(create, ["prevent"]),
    class: "details-form"
  }, [
    _renderSlot(_ctx.$slots, "header", {}, () => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (isAgent.value)
            ? (_openBlock(), _createElementBlock("b", _hoisted_3, _toDisplayString(_ctx.$t("agent.title")), 1))
            : _createCommentVNode("", true),
          (isAmend.value)
            ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_ctx.$t("bookingFlow.isAmend")) + " " + _toDisplayString(_unref(bookingStore).bookingNumber), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("customerDetails.title")), 1),
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("customerDetails.form.welcome")) + " " + _toDisplayString(_ctx.details?.name) + "!", 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.$t("customerDetails.haveAccount")) + " ", 1),
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('abort')))
              }, _toDisplayString(_ctx.$t("customerDetails.login")), 1)
            ]))
      ])
    ]),
    _createVNode(_unref(VInput), {
      modelValue: _unref(v$).email.$model,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).email.$model) = $event)),
      hasError: _unref(v$).email.$error,
      label: _ctx.$t('fields.email.label'),
      type: "email"
    }, null, 8, ["modelValue", "hasError", "label"]),
    (!_ctx.isLoggedIn)
      ? (_openBlock(), _createBlock(_unref(VInput), {
          key: 0,
          modelValue: _unref(v$).confirmEmail.$model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).confirmEmail.$model) = $event)),
          hasError: _unref(v$).confirmEmail.$error,
          label: _ctx.$t('fields.confirmEmail.label'),
          autocomplete: "new-password",
          type: "email"
        }, null, 8, ["modelValue", "hasError", "label"]))
      : _createCommentVNode("", true),
    (createNewAccount.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_unref(VInput), {
            modelValue: _unref(v$).firstName.$model,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).firstName.$model) = $event)),
            hasError: _unref(v$).firstName.$error,
            label: _ctx.$t('fields.firstname.label')
          }, null, 8, ["modelValue", "hasError", "label"]),
          _createVNode(_unref(VInput), {
            modelValue: _unref(v$).lastName.$model,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(v$).lastName.$model) = $event)),
            hasError: _unref(v$).lastName.$error,
            label: _ctx.$t('fields.lastname.label')
          }, null, 8, ["modelValue", "hasError", "label"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_unref(VInput), {
            modelValue: _unref(v$).name.$model,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(v$).name.$model) = $event)),
            hasError: _unref(v$).name.$error,
            label: _ctx.$t('fields.name.label')
          }, null, 8, ["modelValue", "hasError", "label"])
        ])),
    _withDirectives(_createVNode(_unref(VInput), {
      modelValue: _unref(v$).mobilePhone.$model,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(v$).mobilePhone.$model) = $event)),
      hasError: _unref(v$).mobilePhone.$error,
      label: _ctx.$t('fields.phone.label'),
      "data-maska": "#############"
    }, null, 8, ["modelValue", "hasError", "label"]), [
      [_unref(vMaska)]
    ]),
    _createElementVNode("div", null, [
      (!_ctx.isLoggedIn && !_ctx.forceCreateAccount)
        ? (_openBlock(), _createBlock(_unref(VCheckbox), {
            key: 0,
            modelValue: createNewAccount.value,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((createNewAccount).value = $event)),
            label: _ctx.$t('customerDetails.checkboxes.createAccount'),
            id: "createAccount"
          }, null, 8, ["modelValue", "label"]))
        : _createCommentVNode("", true),
      (createNewAccount.value)
        ? (_openBlock(), _createElementBlock("fieldset", {
            key: 1,
            class: _normalizeClass(["create-account", { 'create-account--force': _ctx.forceCreateAccount }])
          }, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("fields.address.label")), 1),
            _createVNode(_unref(VInput), {
              modelValue: _unref(v$).address.$model,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(v$).address.$model) = $event)),
              hasError: _unref(v$).address.$error,
              label: _ctx.$t('fields.address.label')
            }, null, 8, ["modelValue", "hasError", "label"]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_unref(VInput), {
                modelValue: _unref(v$).city.$model,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(v$).city.$model) = $event)),
                hasError: _unref(v$).city.$error,
                label: _ctx.$t('fields.city.label')
              }, null, 8, ["modelValue", "hasError", "label"]),
              _withDirectives(_createVNode(_unref(VInput), {
                modelValue: _unref(v$).zip.$model,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(v$).zip.$model) = $event)),
                hasError: _unref(v$).zip.$error,
                label: _ctx.$t('fields.zip.label'),
                "data-maska": "### ##"
              }, null, 8, ["modelValue", "hasError", "label"]), [
                [_unref(vMaska)]
              ])
            ]),
            _createElementVNode("label", null, _toDisplayString(_ctx.$t("customerDetails.form.password")), 1),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_unref(VInput), {
                modelValue: _unref(v$).password.$model,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(v$).password.$model) = $event)),
                hasError: _unref(v$).password.$error,
                label: _ctx.$t('fields.password.label'),
                type: "password",
                autocomplete: "new-password"
              }, null, 8, ["modelValue", "hasError", "label"]),
              _createVNode(_unref(VInput), {
                modelValue: _unref(v$).passwordRepeat.$model,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(v$).passwordRepeat.$model) = $event)),
                hasError: _unref(v$).passwordRepeat.$error,
                label: _ctx.$t('fields.confirmPassword.label'),
                type: "password"
              }, null, 8, ["modelValue", "hasError", "label"])
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_unref(VCheckbox), {
      modelValue: _unref(v$).gdprConsent.$model,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(v$).gdprConsent.$model) = $event)),
      hasError: _unref(v$).gdprConsent.$error,
      label: _ctx.$t('customerDetails.checkboxes.GDPR'),
      id: "gdpr"
    }, null, 8, ["modelValue", "hasError", "label"]),
    (_unref(v$).$errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).$errors.map((e) => e.$message))], (error) => {
            return (_openBlock(), _createElementBlock("p", {
              key: error.toString()
            }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("p", null, _toDisplayString(error.value), 1)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "footer")
  ], 32))
}
}

})