import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "trip-details" }
const _hoisted_2 = { class: "prose" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = {
  key: 0,
  class: "agent-title"
}
const _hoisted_5 = {
  key: 1,
  class: "agent-title"
}
const _hoisted_6 = { class: "h2" }
const _hoisted_7 = { class: "routes" }
const _hoisted_8 = { class: "dates" }
const _hoisted_9 = { class: "dates-outward" }
const _hoisted_10 = { class: "dates__title" }
const _hoisted_11 = {
  key: 0,
  class: "error-messages"
}
const _hoisted_12 = {
  key: 0,
  class: "dates-return"
}
const _hoisted_13 = { class: "dates__title" }
const _hoisted_14 = {
  key: 0,
  class: "error-messages"
}

import SvgIcon from "@/components/icons/SvgIcon.vue";
import { routes } from "@/config/routes";
import { outwardDateRequired, returnDateRequired } from "@/helpers/validators";
import { useBookingStore } from "@/store/booking";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { VBtn, VDatePicker, VSelect, VToggleSwitch } from "v-ferry-components";
import { computed, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'TripDetails',
  setup(__props, { expose: __expose }) {

const isBusiness = computed(() => bookingStore.getIsBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const { t } = useI18n();

const returnOptions = computed(() => routes.filter((r) => r.value !== state.outwardRoute));

const onOutwardRouteSelect = () => {
    const returnRoutes = routes.filter((r) => r.value !== state.outwardRoute);
    if (returnRoutes.length) {
        state.returnRoute = returnRoutes[0].value;
    }
};

const switchRoutes = () => {
    if (!outwardIsLocked.value) {
        state.outwardRoute = state.returnRoute;
        onOutwardRouteSelect();
    }
};

const bookingStore = useBookingStore();
const hasReturn = ref(bookingStore.getIsReturn);

const outwardIsLocked = computed(() => bookingStore.getBooking.outward.isLocked && bookingStore.getIsAmendment);

const state = reactive({
    outwardRoute: bookingStore.outward.route.length ? bookingStore.outward.route : "DAOR",
    returnRoute: bookingStore.return.route.length ? bookingStore.return.route : "ORDA",
    outwardDate: bookingStore.outward.date ? new Date(bookingStore.outward.date) : undefined,
    returnDate: bookingStore.return.date ? new Date(bookingStore.return.date) : undefined,
});

const rules = {
    outwardRoute: {
        v: () => !!state.outwardRoute,
    },
    returnRoute: {
        v: () => !!state.returnRoute,
    },
    outwardDate: {
        v: helpers.withMessage(
            t("tripDetails.helpers.outwardDate"),
            () => outwardIsLocked.value === true || outwardDateRequired(state.outwardDate),
        ),
    },
    returnDate: {
        v: helpers.withMessage(t("tripDetails.helpers.returnDate"), () =>
            returnDateRequired(hasReturn.value, state.returnDate, state.outwardDate),
        ),
    },
};

const v$ = useVuelidate(rules, state);

const persistState = () => {
    if (state.outwardDate) {
        bookingStore.setTripDetails(state.outwardRoute, state.outwardDate, state.returnRoute, hasReturn.value, state.returnDate);
    }
};

__expose({
    persistState,
});

watch(state, () => {
    if (state.returnDate && state.outwardDate)
        if (state.returnDate < state.outwardDate) {
            state.returnDate = state.outwardDate;
        }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (isBusiness.value)
          ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_unref(t)("agent.title")), 1))
          : _createCommentVNode("", true),
        (isAmend.value)
          ? (_openBlock(), _createElementBlock("b", _hoisted_5, _toDisplayString(_unref(t)("bookingFlow.isAmend")) + " " + _toDisplayString(_unref(bookingStore).bookingNumber), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h1", _hoisted_6, _toDisplayString(_unref(t)("tripDetails.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_unref(VSelect), {
        modelValue: _unref(v$).outwardRoute.$model,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).outwardRoute.$model) = $event)),
        hasError: _unref(v$).outwardRoute.$error,
        label: _unref(t)('tripDetails.label.outwardRoute'),
        options: _unref(routes),
        "onOption:selected": onOutwardRouteSelect,
        disabled: outwardIsLocked.value,
        class: _normalizeClass({ disabled: outwardIsLocked.value }),
        clearable: false
      }, null, 8, ["modelValue", "hasError", "label", "options", "disabled", "class"]),
      _createVNode(_unref(VBtn), {
        onClick: switchRoutes,
        class: "v-btn--ghost"
      }, {
        default: _withCtx(() => [
          _createVNode(SvgIcon, {
            icon: "carbon/arrows-horizontal",
            width: "50px"
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(VSelect), {
        modelValue: _unref(v$).returnRoute.$model,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).returnRoute.$model) = $event)),
        hasError: _unref(v$).returnRoute.$error,
        label: _unref(t)('tripDetails.label.returnRoute'),
        options: returnOptions.value,
        clearable: false
      }, null, 8, ["modelValue", "hasError", "label", "options"])
    ]),
    (_unref(bookingStore).getIsAmendment === false)
      ? (_openBlock(), _createBlock(_unref(VToggleSwitch), {
          key: 0,
          modelValue: hasReturn.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((hasReturn).value = $event)),
          class: _normalizeClass({ disabled: outwardIsLocked.value }),
          id: "tripToggle",
          label: _unref(t)('tripDetails.tripType')
        }, null, 8, ["modelValue", "class", "label"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(t)("tripDetails.label.outwardDate")), 1),
        _createVNode(_unref(VDatePicker), {
          modelValue: _unref(v$).outwardDate.$model,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).outwardDate.$model) = $event)),
          hasError: _unref(v$).outwardDate.$error,
          "min-date": new Date(),
          class: _normalizeClass({ error: _unref(v$).outwardDate.$error, disabled: outwardIsLocked.value }),
          isInline: "",
          weekdaysFormat: "W",
          locale: "sv"
        }, null, 8, ["modelValue", "hasError", "min-date", "class"]),
        (_unref(v$).outwardDate.$error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).outwardDate.$errors.map((e) => e.$message))], (error) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: error.toString()
                }, _toDisplayString(error), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      (hasReturn.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)("tripDetails.label.returnDate")), 1),
            (_openBlock(), _createBlock(_unref(VDatePicker), {
              modelValue: _unref(v$).returnDate.$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(v$).returnDate.$model) = $event)),
              hasError: _unref(v$).returnDate.$error,
              "min-date": state.outwardDate ?? new Date(),
              key: state.outwardDate?.toDateString(),
              class: _normalizeClass({ error: _unref(v$).returnDate.$error }),
              isInline: "",
              weekdaysFormat: "W",
              locale: "sv"
            }, null, 8, ["modelValue", "hasError", "min-date", "class"])),
            (_unref(v$).returnDate.$error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).returnDate.$errors.map((e) => e.$message))], (error) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: error.toString()
                    }, _toDisplayString(error), 1))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})