<template>
    <teleport to="body">
        <transition name="slide" appear mode="out-in">
            <div class="dialog-overlay" v-if="show && data" @click.self="overlayClicked">
                <div class="dialog" :class="`dialog--${className}`">
                    <SvgIcon class="dialog__icon" :icon="`carbon/${iconType}`" />
                    <div class="dialog__content">
                        <header>
                            <h4>{{ data.title }}</h4>
                        </header>
                        <p v-html="data.message"></p>
                        <footer>
                            <VBtn ref="closeBtnRef" v-if="data.no" @click="show = false" :class="'btn--outline'">{{ data.no }}</VBtn>
                            <VBtn :class="{ 'destructive' : data.destructive }" @click.stop="callBack">{{ data.yes }}</VBtn>
                        </footer>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script setup lang="ts">
import { VBtn } from "v-ferry-components";
import useDialog from "@/composables/useDialog";
import { computed, watch, nextTick, ref } from "vue";
import { DialogType } from "@/models/Symbols/Dialog";
import SvgIcon from "../icons/SvgIcon.vue";

const { show, data } = useDialog();

const overlayClicked = () => {
    if (data.value?.type === DialogType.redirect) {
        callBack();
    } else {
        show.value = !show.value;
    }
};

const callBack = () => {
    if (data.value?.callback) {
        data.value.callback();
    }
    show.value = false;
};

const className = computed(() => {
    switch (data.value?.type) {
        case DialogType.warning:
            return "warning";
        case DialogType.danger:
            return "danger";
        case DialogType.redirect:
        case DialogType.info:
        default:
            return "info";
    }
});

const iconType = computed(() => {
    switch (data.value?.type) {
        case DialogType.warning:
        case DialogType.danger:
            return "warning";
        case DialogType.redirect:
        case DialogType.info:
        default:
            return "info";
    }
});

const closeBtnRef = ref();

let previousElement: unknown;

watch(show, (current) => {
    if (current) {
        previousElement = document.activeElement;
        nextTick(() => {
            if (closeBtnRef.value) {
                closeBtnRef.value.$el.focus();
            }
        });
    } else if (previousElement) {
        (previousElement as HTMLElement).focus();
    }
});
</script>

<style lang="scss" scoped>
.dialog {
    display: flex;
    gap: 20px;
    margin: 0 15px;
    padding: 1.5rem;
    width: 100%;
    max-width: 420px;
    background-color: var(--c-base);
    border-radius: 5px;

    &__icon {
        flex-shrink: 0;
        width: 26px;
        height: 26px;
        border-radius: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    header {
        h4 {
            font-size: 1.125rem;
        }
    }

    footer {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        :deep(.v-btn) {
            min-width: 80px;
            
            &.destructive {
            	background-color: var(--c-danger);
            }
        }
    }

    &--info {
        .dialog__icon {
            color: var(--c-primary);
            background-color: var(--c-base);
        }
    }

    &--warning {
        .dialog__icon {
            color: var(--c-primary);
        }
    }

    &--danger {
        .dialog__icon {
            color: var(--c-danger);
        }

        h4 {
            color: var(--c-danger);
        }
    }

    &-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--v-modal-overlay-bg);
        z-index: 10001;
    }
}
</style>
