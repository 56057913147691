import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "error-messages"
}
const _hoisted_3 = {
  key: 1,
  class: "error-messages"
}

import useReset from "@/composables/auth/useReset";
import { UserType } from "@/models/Enums";
import { useUserStore } from "@/store/user";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { required } from "@/helpers/validators";
import { VBtn, VCard, VInput } from "v-ferry-components";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPassword',
  setup(__props) {

const { t } = useI18n();

const state = reactive({
    newPassword: "",
    repeatNewPassword: "",
});

const rules = {
    newPassword: {
    	v: helpers.withMessage(t("errorMessages.password"), (password) => password.length >= 8),
    	v2: helpers.withMessage(t("errorMessages.longPassword"), (password) => password.length <= 12),
    },
    repeatNewPassword: {
        valid: helpers.withMessage(t("errorMessages.passwordRepeat"), (val: string) => val === state.newPassword),
    },
};

const v$ = useVuelidate(rules, state);

const route = useRoute();

const errorMessage = ref();

const token = route.query.user?.toString();

const { reset, isLoading } = useReset();

const resetPassword = async () => {
    const valid = await v$.value.$validate();

    if (!valid) return;
    reset({
        token: token ?? "",
        password: state.newPassword,
        type: UserType.Customer,
    })
        .then((resp) => {
            window.location.href = "/login";
        })
        .catch((err) => {
            errorMessage.value = t(`errorCodes.${err.response.data.errorCode}`);
        });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VCard), { class: "reset" }, {
    default: _withCtx(() => [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t("reset.title")), 1),
      _createElementVNode("form", {
        onSubmit: _withModifiers(resetPassword, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(VInput), {
            type: "password",
            modelValue: _unref(v$).newPassword.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).newPassword.$model) = $event)),
            hasError: _unref(v$).newPassword.$error,
            label: _ctx.$t('fields.newPassword.label')
          }, null, 8, ["modelValue", "hasError", "label"]),
          _createVNode(_unref(VInput), {
            type: "password",
            modelValue: _unref(v$).repeatNewPassword.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).repeatNewPassword.$model) = $event)),
            hasError: _unref(v$).repeatNewPassword.$error,
            label: _ctx.$t('fields.confirmNewPassword.label')
          }, null, 8, ["modelValue", "hasError", "label"])
        ]),
        (_unref(v$).repeatNewPassword.$error || _unref(v$).newPassword.$error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).newPassword.$errors.map((e) => e.$message))], (error) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: error.toString()
                }, _toDisplayString(error), 1))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).repeatNewPassword.$errors.map((e) => e.$message))], (error) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: error.toString()
                }, _toDisplayString(error), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (errorMessage.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(errorMessage.value), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_unref(VBtn), { isLoading: _unref(isLoading) }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("reset.btn")), 1)
          ]),
          _: 1
        }, 8, ["isLoading"])
      ], 32)
    ]),
    _: 1
  }))
}
}

})