import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import SvgIcon from "../icons/SvgIcon.vue";

interface Props {
    alignCenter?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLoader',
  props: {
    alignCenter: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader", { center: _ctx.alignCenter }])
  }, [
    _createVNode(SvgIcon, {
      icon: "loader",
      spin: ""
    })
  ], 2))
}
}

})