<template>
    <VCard class="reset">
        <h2>{{ $t("reset.title") }}</h2>

        <form @submit.prevent="resetPassword">
            <div class="row">
                <VInput
                    type="password"
                    v-model="v$.newPassword.$model"
                    :hasError="v$.newPassword.$error"
                    :label="$t('fields.newPassword.label')"
                />
                <VInput
                    type="password"
                    v-model="v$.repeatNewPassword.$model"
                    :hasError="v$.repeatNewPassword.$error"
                    :label="$t('fields.confirmNewPassword.label')"
                />
            </div>
            <div v-if="v$.repeatNewPassword.$error || v$.newPassword.$error" class="error-messages">
               	<p v-for="error in [...new Set(v$.newPassword.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
                <p v-for="error in [...new Set(v$.repeatNewPassword.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
            </div>
            <div v-if="errorMessage" class="error-messages">
                <p>{{ errorMessage }}</p>
            </div>
            <VBtn :isLoading="isLoading">{{ $t("reset.btn") }}</VBtn>
        </form>
    </VCard>
</template>

<script setup lang="ts">
import useReset from "@/composables/auth/useReset";
import { UserType } from "@/models/Enums";
import { useUserStore } from "@/store/user";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { required } from "@/helpers/validators";
import { VBtn, VCard, VInput } from "v-ferry-components";
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

const { t } = useI18n();

const state = reactive({
    newPassword: "",
    repeatNewPassword: "",
});

const rules = {
    newPassword: {
    	v: helpers.withMessage(t("errorMessages.password"), (password) => password.length >= 8),
    	v2: helpers.withMessage(t("errorMessages.longPassword"), (password) => password.length <= 12),
    },
    repeatNewPassword: {
        valid: helpers.withMessage(t("errorMessages.passwordRepeat"), (val: string) => val === state.newPassword),
    },
};

const v$ = useVuelidate(rules, state);

const route = useRoute();

const errorMessage = ref();

const token = route.query.user?.toString();

const { reset, isLoading } = useReset();

const resetPassword = async () => {
    const valid = await v$.value.$validate();

    if (!valid) return;
    reset({
        token: token ?? "",
        password: state.newPassword,
        type: UserType.Customer,
    })
        .then((resp) => {
            window.location.href = "/login";
        })
        .catch((err) => {
            errorMessage.value = t(`errorCodes.${err.response.data.errorCode}`);
        });
};
</script>

<style scoped lang="scss">
.reset {
    width: 100%;
    max-width: 725px;
    margin: 0 auto;
    margin-top: 40px;

    form {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .row {
            display: flex;
            gap: 10px;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
            .v-input {
                width: 100%;
            }
        }
        .v-btn {
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
            width: 250px;
            align-self: center;
        }
    }
}
</style>
