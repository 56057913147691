<template>
    <VCard class="ticket" :class="{ 'is-active': quantity > 0 }" bordered>
        <div class="ticket__content">
            <SvgIcon class="ticket__icon" :icon="icon" />
            <div class="prose">
                <h4>{{ title }}</h4>
            </div>
        </div>
        <VQuantity color="ghost" :max="max ?? 5" :min="0" v-model="quantity" :class="{ noIcons: hideQuantity }" />
    </VCard>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { VCard, VQuantity } from "v-ferry-components";
import { computed } from "vue";

interface Props {
    modelValue: number;
    icon: string;
    title: string;
    max?: number;
    hideQuantity?: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits(["update:modelValue"]);

const quantity = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emit("update:modelValue", val);
    },
});
</script>

<style scoped lang="scss">
.ticket {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-color: var(--c-border);
    transition: all 0.2s ease-in-out;
    border-radius: 5px;
    padding: 15px;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        h4 {
            color: var(--c-prose-body);
            text-align: center;
            font-weight: 400;
            font-size: 1rem;
        }
    }

    &__icon {
        height: 3rem;
        width: 3rem;
        color: var(--c-primary);
    }

    @include media-breakpoint-down(lg) {
        flex-direction: row;
        gap: 10px;
        padding: 10px;
        --v-quantity-padding: 5px;
        justify-content: space-between;

        &__icon {
            height: 1.5rem;
            width: 1.5rem;
        }

        :deep(.v-quantity) {
            align-items: center;
            input {
                padding: 10px;
                width: 2.5rem;
            }
        }

        &__content {
            flex: 1;
            flex-flow: row;
            max-width: 150px;

            h4 {
                font-size: 0.875rem;
                text-align: left;
            }
        }
    }

    &.is-active {
        background-color: var(--c-blue-bg);
        border-color: var(--c-primary);
    }

    .noIcons {
        :deep(.v-btn) {
            display: none;
        }
    }

    :deep(.v-input) input {
        pointer-events: none;
    }
}
</style>
