<template>
    <VModal @close="emit('close')" :title="$t('myPages.myBooking.item.amendBooking.title')">
        <template #default>
            <BaseLoader v-if="isLoadingOpen" alignCenter />
            <div v-else-if="isOpen" class="modal__body prose">
                <div v-if="amendInfo?.canAmend" class="modal__body-section">
                    <p>{{ $t("myPages.myBooking.item.bookingno") }}: {{ booking.bookingNumber }}</p>

                    <div class="amend-confirm">
                        <VBtn>
                            <RouterLink v-if="isAgent || amendInfo.isBusiness" :to="`/company/?s=1`">{{
                                $t("myPages.myBooking.item.amendment.confirm")
                            }}</RouterLink>
                            <RouterLink v-else :to="`/?s=1`">{{ $t("myPages.myBooking.item.amendment.confirm") }}</RouterLink>
                        </VBtn>
                    </div>
                </div>
                <div v-else>
                    <div class="error-messages">
                        <p>{{ amendInfo?.message }}</p>
                    </div>
                </div>
            </div>
            <div v-else-if="error">
                <div class="error-messages">
                    <p>Någonting gick snett..</p>
                </div>
            </div>
        </template>
    </VModal>
</template>

<script setup lang="ts">
import BaseLoader from "@/components/base/BaseLoader.vue";
import useBookingsQuery from "@/composables/profile/useBookingsQuery";
import { BookingAmendmentResponse } from "@/models/api/BookingAmendResponse";
import { Booking } from "@/models/front/Booking";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { VBtn, VModal } from "v-ferry-components";
import { computed, ref } from "vue";

interface Props {
    booking: Booking;
}

const props = defineProps<Props>();

const emit = defineEmits(["close"]);

const { amendBookingOpen, isLoadingOpen } = useBookingsQuery();

const isAgent = computed(() => useBookingStore().getIsBusiness);

const userStore = useUserStore();

const isOpen = ref(false);

const amendInfo = ref<BookingAmendmentResponse>();

const error = ref(false);

amendBookingOpen({ email: userStore.user.userName, bookingNumber: props.booking.bookingNumber, token: props.booking.token })
    .then((resp) => {
        amendInfo.value = resp;
        isOpen.value = true;
    })
    .catch(() => {
        error.value = true;
    });
</script>

<style lang="scss" scoped>
.modal {
    &__body {
        width: 100%;
        &-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            gap: 10px;
            p {
                color: var(--c-primary);
                font-size: 1.125rem;
            }

            .amend-confirm {
                display: flex;
                gap: 10px;
                flex-flow: row wrap;
                justify-content: flex-end;
                min-width: 300px;

                a {
                    color: var(--c-base);
                    text-decoration: none;
                }
            }
        }
    }
    &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        gap: 10px;

        &-buttons {
            display: flex;
            gap: 10px;
        }
    }
}
</style>
