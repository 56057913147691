import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, Transition as _Transition, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "dialog__content" }
const _hoisted_2 = ["innerHTML"]

import { VBtn } from "v-ferry-components";
import useDialog from "@/composables/useDialog";
import { computed, watch, nextTick, ref } from "vue";
import { DialogType } from "@/models/Symbols/Dialog";
import SvgIcon from "../icons/SvgIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Dialog',
  setup(__props) {

const { show, data } = useDialog();

const overlayClicked = () => {
    if (data.value?.type === DialogType.redirect) {
        callBack();
    } else {
        show.value = !show.value;
    }
};

const callBack = () => {
    if (data.value?.callback) {
        data.value.callback();
    }
    show.value = false;
};

const className = computed(() => {
    switch (data.value?.type) {
        case DialogType.warning:
            return "warning";
        case DialogType.danger:
            return "danger";
        case DialogType.redirect:
        case DialogType.info:
        default:
            return "info";
    }
});

const iconType = computed(() => {
    switch (data.value?.type) {
        case DialogType.warning:
        case DialogType.danger:
            return "warning";
        case DialogType.redirect:
        case DialogType.info:
        default:
            return "info";
    }
});

const closeBtnRef = ref();

let previousElement: unknown;

watch(show, (current) => {
    if (current) {
        previousElement = document.activeElement;
        nextTick(() => {
            if (closeBtnRef.value) {
                closeBtnRef.value.$el.focus();
            }
        });
    } else if (previousElement) {
        (previousElement as HTMLElement).focus();
    }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, {
      name: "slide",
      appear: "",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_unref(show) && _unref(data))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "dialog-overlay",
              onClick: _withModifiers(overlayClicked, ["self"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["dialog", `dialog--${className.value}`])
              }, [
                _createVNode(SvgIcon, {
                  class: "dialog__icon",
                  icon: `carbon/${iconType.value}`
                }, null, 8, ["icon"]),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("header", null, [
                    _createElementVNode("h4", null, _toDisplayString(_unref(data).title), 1)
                  ]),
                  _createElementVNode("p", {
                    innerHTML: _unref(data).message
                  }, null, 8, _hoisted_2),
                  _createElementVNode("footer", null, [
                    (_unref(data).no)
                      ? (_openBlock(), _createBlock(_unref(VBtn), {
                          key: 0,
                          ref_key: "closeBtnRef",
                          ref: closeBtnRef,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (show.value = false)),
                          class: _normalizeClass('btn--outline')
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(data).no), 1)
                          ]),
                          _: 1
                        }, 512))
                      : _createCommentVNode("", true),
                    _createVNode(_unref(VBtn), {
                      class: _normalizeClass({ 'destructive' : _unref(data).destructive }),
                      onClick: _withModifiers(callBack, ["stop"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(data).yes), 1)
                      ]),
                      _: 1
                    }, 8, ["class"])
                  ])
                ])
              ], 2)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})