<template>
    <div class="thank-you" v-if="isOpenTicket === false">
        <SiteHero class="thank-you__hero" />
        <BaseLoader v-if="isLoading" />
        <div v-else-if="data" class="thank-you__section container">
            <VCard class="card">
                <header>
                    <h2>{{ !isCardBooking ? $t("thankyou.booking.title") : $t("thankyou.card.title") }}</h2>
                    <span>
                        {{ $t("thankyou.booking.bookingno") }} <b>{{ booking?.bookingNumber }}</b>
                    </span>
                </header>
                <div v-if="!isCardBooking" class="booking prose">
                    <div class="booking__section">
                        <h4 class="h5">
                            {{ $t("thankyou.booking.travelDocument.title") }}
                        </h4>
                        <p>{{ $t("thankyou.booking.travelDocument.message") }}</p>
                        <p>
                            <b>
                                {{ $t("thankyou.booking.travelDocument.obs") }}
                            </b>
                            {{ $t("thankyou.booking.travelDocument.info") }}
                        </p>
                    </div>
                    <div class="booking__section prose">
                        <h4 class="h5">
                            {{ $t("thankyou.booking.purchase.title") }}
                        </h4>
                        <ul v-for="(section, index) in booking?.departures" :key="index">
                            <li class="heading">
                                <span>{{ $t(`routes.${section.route}`) }}</span>
                                <span class="date">{{ dayDateTime(section.departureDate) }}</span>
                            </li>
                            <li v-for="resource in section.resources" :key="resource.description" class="resource">
                                <div>
                                    {{ resource.amount }}
                                    <span v-if="resource.type">
                                        {{ resource.description }}
                                    </span>
                                    <span v-else>
                                        {{ resource.description }}
                                        {{ resource.license ? `(${resource.license})` : "" }}</span
                                    >
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else class="card-purchase">
                    <ul v-for="(section, index) in booking?.departures" :key="index">
                        <li v-for="resource in section.resources" :key="resource.description" class="resource">
                            <b> {{ resource.amount }} x {{ resource.description }} </b>
                        </li>
                    </ul>
                </div>

                <footer>
                    <span>
                        {{ $t("thankyou.booking.totalPrice") }} <b>{{ booking?.totalPrice }}kr</b>
                    </span>
                </footer>
            </VCard>
        </div>
        <div v-else class="thank-you__section">
            <VCard class="card">
                <header>
                    <h4>{{ $t("errorMessages.error") }}...</h4>
                </header>
            </VCard>
        </div>
    </div>
    <OpenTicket :tickets="tickets" v-else />
</template>

<script setup lang="ts">
import OpenTicket from "./components/OpenTicket.vue";
import BaseLoader from "@/components/base/BaseLoader.vue";
import SiteHero from "@/components/layout/SiteHero.vue";
import useCompleteQuery from "@/composables/thankyou/useCompleteQuery";
import { dayDateTime } from "@/helpers/dateHelpers";
import { UserType } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { VCard } from "v-ferry-components";
import { computed } from "vue";
import { useRoute } from "vue-router";

interface Props {
    isSessionless?: boolean;
}

const props = defineProps<Props>();

const { query } = useRoute();

const { data, isLoading } = useCompleteQuery(query.id?.toString(), props.isSessionless);

const booking = computed(() => data.value?.bookings[0]);

const isOpenTicket = computed(() => data.value?.isOpenEnded);
const isCardBooking = computed(() => data.value?.bookings.some((booking) => booking.departures.some((d) => d.route === "KORT")));

const tickets = computed(() => data.value?.bookings);

const bookingStore = useBookingStore();

bookingStore.resetBookingState();

const userStore = useUserStore();

const userType = userStore.getUserType;
if (userType === UserType.Guest) {
    userStore.logout();
}
</script>

<style lang="scss" scoped>
.thank-you {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 100px;

    &__hero {
        margin-bottom: 20px;
    }

    &__section {
        display: flex;
        justify-content: center;
        gap: 30px;
        max-width: 725px;
        width: 100%;
        margin: 0 auto;
        margin-top: -150px;
        z-index: 1;

        @include media-breakpoint-up(md) {
            margin-top: -100px;

            > *:first-child {
                flex: auto;
                height: 100%;
            }

            > *:last-child {
                flex: 0 1 calc((100% - 30px) / 4);
                min-width: calc((100% - 30px) / 4);
            }
        }

        .card {
            width: 100%;
            flex: auto;
            header {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 20px;
                h2,
                span {
                    text-align: center;
                }
            }

            .booking {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                &__section {
                    a {
                        color: var(--c-base-text);
                    }
                }

                ul {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    margin-bottom: 20px;

                    li {
                        display: flex;
                        justify-content: space-between;
                        max-width: 300px;
                        gap: 5px;

                        &.heading {
                            font-weight: bold;
                            flex-flow: row wrap;

                            .date {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }

            footer {
                margin-top: 20px;
                text-align: center;
            }
        }

        .card-purchase {
            li {
                margin-top: 20px;
                display: flex;
                justify-content: center;
                b {
                    text-align: center;
                }
            }
        }
    }
}
</style>
