<template>
    <div class="vehicle-form">
        <div class="my-vehicle">
            <VInput
                class="large"
                v-model="v$.licenseNumber.$model"
                :label="$t('myPages.myVehicle.reg')"
                :hasError="v$.licenseNumber.$error"
                :disabled="isDisabled"
                @focusout="setVehicleReg"
            />
            <VInput
            	ref="inputLengthRef"
                v-model="v$.length.$model"
                :label="$t('fields.vehicleLength.label')"
                :hasError="v$.length.$error"
                v-maska
                data-maska="DE.D"
                data-maska-tokens="D:[0-9]|E:[0-9]:optional"
                :disabled="isDisabled || isLoadingVehicleInfo"
                @focusout="checkLengthWidth"
            />
            <VInput
                v-model="v$.width.$model"
                :label="$t('fields.vehicleWidth.label')"
                :hasError="v$.width.$error"
                v-maska
                data-maska="DE.D"
                data-maska-tokens="D:[0-9]|E:[0-9]:optional"
                :disabled="isDisabled || isLoadingVehicleInfo"
                @focusout="checkLengthWidth"
            />
            <VCheckbox
            	:id="vehicle.vehicleId"
            	v-model="v$.isDefault.$model"
                :hasError="v$.isDefault.$error"
                :disabled="isDisabled"
                label="Standardfordon"
            >
            </VCheckbox>
            <VBtn v-if="isDisabled === true" @click="edit()" class="edit-btn" type="button" color="ghost">
                <SvgIcon class="icon" icon="edit" />
            </VBtn>
            <VBtn @click="emit('onRemoveVehicle', vehicle.vehicleId)" class="remove-btn" type="button" color="ghost">
                <SvgIcon class="icon" icon="minus" />
            </VBtn>
            <VBtn 
            	type="button" 
            	class="save__btn" @click="emit('onUpdateVehicle', state);" 
            	v-if="isDisabled === false" 
            	:isLoading="isLoading">{{ $t("myPages.myProfile.save") }}</VBtn>
        </div>
        <div v-if="v$.$errors.length" class="error-messages">
            <p v-for="error in [...new Set(v$.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useVehicleInfo, { VehicleInfoResponse } from "@/composables/vehicle-info/useVehicleInfo";
import { MyCoVehicle } from "@/models/front/MyCoVehicle";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@/helpers/validators";
import { helpers } from "@vuelidate/validators";
import { vMaska } from "maska";
import { VBtn, VInput, VCheckbox } from "v-ferry-components";
import { Ref, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useVehiclesQuery from "@/composables/profile/useVehiclesQuery";

interface Props {
    vehicle: MyCoVehicle;
    disabled?: boolean;
    isEditing: boolean;
}

const props = defineProps<Props>();

const isInitiallyDefaultVehicle = ref(props.vehicle.isDefault);

const isDisabled = ref(props.disabled);
const { t } = useI18n();
const { isLoading } = useVehiclesQuery();

const emit = defineEmits(["onRemoveVehicle", "onUpdateVehicle", "onEdit"]);

const edit = () => {

	isDisabled.value = false;
	emit('onEdit', state.vehicleId);
};

watch(() => props.isEditing, (newValue) => {
	
	if (props.isEditing === false) {
		state.licenseNumber = props.vehicle.licenseNumber;
		state.length = props.vehicle.length;
		state.width = props.vehicle.width;
		state.isDefault = props.vehicle.isDefault;
		
		isDisabled.value = true;
	}
});

const state = reactive({
    licenseNumber: props.vehicle.licenseNumber ?? "",
    length: props.vehicle.length === 0 ? "" : props.vehicle.length,
    width: props.vehicle.width === 0 ? "" : props.vehicle.width,
    isDefault: props.vehicle.isDefault,
    vehicleId: props.vehicle.vehicleId,
});

const checkLengthWidth = () => {

    if (!registeredVehicleInfo.value || 
    	state.length === undefined || 
    	state.width === undefined) {
    	
    		return;
	}
	
    if (+state.length < registeredVehicleInfo.value?.length) {
        state.length = registeredVehicleInfo.value?.length;
    }

    if (+state.width < registeredVehicleInfo.value?.width) {
        state.width = registeredVehicleInfo.value?.width;
    }
};

const rules = {
    licenseNumber: { required },
    length: {
        required,
    },
    width: {
        required,
    },
    isDefault: {
		v: helpers.withMessage("Ett fordon måste vara standardfordon", 
			(state) => {
    		
    		if (isInitiallyDefaultVehicle.value === true) {
    			if (state === false) {
    				return false;
    			}
    			else {
    				return true;
    			}
    		}
    		
    		return true;
    	})
    },
};

const v$ = useVuelidate(rules, state);

const inputLengthRef = ref();

const { getVehicleInfo, isLoading : isLoadingVehicleInfo } = useVehicleInfo();

const registeredVehicleInfo = ref<VehicleInfoResponse>();

const setVehicleReg = () => {
    
    getVehicleInfo(state.licenseNumber).then((resp) => {
        if (resp) {
        
            state.width = resp.width;
            state.length = resp.length;
            state.licenseNumber = resp.licenseNumber;
			
			inputLengthRef.value.$el.querySelector('input').focus();
			
            registeredVehicleInfo.value = resp;
        }
    });
};

</script>

<style lang="scss" scoped>
.vehicle-form {
    width: 100%;
    .my-vehicle {
        display: flex;
        gap: 10px;
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
		
		> div {
			flex: 1;
			
			&.large {
				flex: 2;
			}
		}
		
		.save__btn {
			width: 74px;
			
			&:hover {
				background-color: var(--v-btn-bg)!important;
			}
		}
		
        .v-input {
            flex: 1;
        }

        .large {
            flex: 2;
        }

        .remove-btn {
             top: 10px;
            .icon {
                background-color: var(--c-danger);
                color: var(--c-base);
                border-radius: 50px;
            }
        }

        .v-btn {
        	gap: 8px;
            padding: 0;
            &:hover {
                background-color: transparent;
            }
        }
        .v-checkbox {
        	padding: 10px;
        	background-color: #fff;
        }
    }
}
</style>
