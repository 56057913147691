<template>
    <div class="booking-item">
        <header @click="isActive = !isActive" :class="{ interactive: isExpansion }">
            <div class="booking-number">
                <span>{{ t("myPages.myBooking.item.bookingno") }}</span>
                <span>
                    {{ booking.bookingNumber }}
                </span>
            </div>

            <div class="departures-container">
                <div class="departure" :class="{ hasPast: new Date(booking.departure.departureDate) < new Date() && !pastBooking }">
                    <span>{{ routeOutward?.outwardRoute }} -> {{ routeOutward?.arrivalRoute }}</span>
                    <span>{{ dayDateTime(booking.departure.departureDate) }}</span>
                </div>
                <div v-if="booking.return" class="departure">
                    <span>{{ routeReturn?.outwardRoute }} -> {{ routeReturn?.arrivalRoute }}</span>
                    <span>{{ dayDateTime(booking.return.departureDate) }}</span>
                </div>
            </div>

            <SvgIcon v-if="isExpansion" class="chevron" :class="{ 'chevron-active': isActive }" icon="chevron" width="15px" />
        </header>

        <div class="booking-item__content" :class="{ 'booking-item__content-active': isActive || !isExpansion }">
            <div class="resources">
                <p v-for="(resource, index) in booking.departure.resources" :key="index">
                    {{ resource.amount }} x
                    <span v-if="resource.type">
                        {{ resource.description }}
                    </span>
                    <span v-else>
                        {{ resource.description }}
                        {{ resource.license ? `(${resource.license})` : "" }}</span
                    >
                </p>
            </div>

            <div v-if="!pastBooking" class="buttons">
                <VBtn @click="showCancel = true" class="cancel">{{ t("myPages.myBooking.item.cancel") }}</VBtn>
                <VBtn @click="showAmend = true" class="reschedule">{{ t("myPages.myBooking.item.reschedule") }}</VBtn>
            </div>
        </div>
    </div>

    <PrepareCancelModal v-if="showCancel" @close="showCancel = false" :booking="booking" isClosable />
    <AmendBookingModal v-if="showAmend" @close="closeAmend" :booking="booking" isClosable />
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { departureRoutes } from "@/config/routes";
import { VBtn } from "v-ferry-components";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import AmendBookingModal from "./AmendBookingModal.vue";
import PrepareCancelModal from "./PrepareCancelModal.vue";
import { Booking } from "@/models/front/Booking";
import useUnloadBookings from "@/composables/useUnloadBookings";
import { dayDateTime } from "@/helpers/dateHelpers";

interface Props {
    booking: Booking;
    expanded?: boolean;
    pastBooking?: boolean;
    isExpansion?: boolean;
}

const showCancel = ref(false);
const showAmend = ref(false);

const { unload } = useUnloadBookings();

const closeAmend = () => {
    showAmend.value = false;
    unload();
};

const props = defineProps<Props>();

const { t } = useI18n();

const isActive = ref(props.expanded);

const routeOutward = departureRoutes.find((r) => r.value === props.booking.departure.route);
const routeReturn = departureRoutes.find((r) => r.value === props.booking.return?.route);
</script>

<style lang="scss" scoped>
.booking-item {
    border: 1px solid var(--c-border);
    border-radius: 5px;
    overflow: hidden;
    .interactive {
        cursor: pointer;
        justify-content: flex-start;

        padding-right: 45px;
    }

    > header {
        display: flex;
        align-items: flex-start;
        position: relative;
        padding: 20px;
        gap: 40px;
        color: var(--c-primary);
        font-size: 1.125rem;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        .departures-container {
            display: flex;
            gap: 70px;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
                gap: 20px;
            }

            .hasPast {
                opacity: 0.5;
            }
        }

        .booking-number,
        .departure {
            min-width: 25%;
            > span {
                display: block;
                line-height: 1.4;

                &:first-child {
                    font-size: 0.875rem;
                }
                &:last-child {
                    font-weight: 800;
                }
            }
        }

        .chevron {
            top: 50%;
            transform: translateY(-50%);

            position: absolute;
            right: 25px;
        }

        .chevron-active {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    &__content {
        display: none;
        background-color: #fafafa;
        padding: 20px;
    }

    &__content-active {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        .resources {
            color: var(--c-primary);
            min-width: 25%;
        }

        .buttons {
            display: flex;
            align-items: center;
            gap: 15px;

            @include media-breakpoint-down(md) {
                align-self: center;
            }

            .cancel {
                background-color: var(--c-danger);

                &:hover {
                    background-color: var(--c-danger-darker);
                }
            }

            .reschedule {
                background-color: var(--c-secondary);
            }
        }
    }
}
</style>
