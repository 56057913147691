<template>
    <div class="checkout">
        <PaymentDetails :bookingReference="reference" isCardPurchase />
        <BookingBasket :sections="data?.sections" :total="data?.totalPrice" :isLoading="isLoading" isCardPurchase />
    </div>
</template>

<script setup lang="ts">
import useCalculateQuery from "@/composables/useCalculateQuery";
import BookingBasket from "@/modules/BookingFlow/components/BookingBasket.vue";
import PaymentDetails from "@/modules/BookingFlow/components/paymentDetails/PaymentDetails.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const { query } = useRoute();

const reference = computed(() => {
    return query.reference?.toString();
});

const { data, isLoading } = useCalculateQuery(reference.value);
</script>

<style lang="scss" scoped>
.checkout {
    display: flex;
    justify-content: center;
    gap: 30px;
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;

    > *:first-child {
        width: 100%;
    }
    @include media-breakpoint-up(md) {
        > *:first-child {
            width: 80%;
        }
    }
}
</style>
