<template>
    <li class="departure" :class="{ disabled: isDisabled }">
        <div class="departure__radio" :class="{ active: modelValue === departure.id }">
            <VRadio
                :id="`departure-${departure.id}`"
                :name="`departure-${departure.route}`"
                :value="departure.id"
                v-model="inputValue"
                :disabled="isDisabled"
            >
                <span class="departure__radio--departure">{{ HHmm(departure.departureDate) }}</span>
                <span v-if="departure.capacity == VehicleCapacity.None">{{ $t(`departures.vehicleInfo.2`) }}</span>
                <SvgIcon v-else icon="carbon/arrow-right-long" class="arrow" width="3.75rem" />
                <div>
                    <span class="departure__radio--arrival">{{ HHmm(departure.arrivalDate) }}</span>
                    <SvgIcon
                        icon="carbon/car"
                        :class="`vehicle-info vehicle-info--${departure.capacity.toString().toLowerCase()}`"
                        height="1.5rem"
                    />
                </div>
            </VRadio>
        </div>
    </li>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { HHmm, dateAndTime } from "@/helpers/dateHelpers";
import { Direction, VehicleCapacity } from "@/models/Enums";
import { DepartureModel } from "@/models/front/DepartureModel";
import { useDepartureStore } from "@/store/departure";
import { VRadio } from "v-ferry-components";
import { computed } from "vue";

interface Props {
    modelValue?: string;
    departure: DepartureModel;
    direction: Direction;
}

const props = defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);
const departureStore = useDepartureStore();
const isDisabled = computed(() => {
	
	if (props.departure.isBookable === false) {
		return true;
	}
	
    if (props.departure.capacity === VehicleCapacity.None) {
        return true;
    }

    if (props.direction === Direction.Outward) {
        return false;
    }

    return dateAndTime(props.departure.departureDate) < departureStore.getDeparture(Direction.Outward).date;
});

const inputValue = computed({
    get() {
        return props.modelValue;
    },
    set(value?: string) {
        emit("update:modelValue", value);
    },
});

if (props.departure.id === inputValue.value) {
    const selectedDeparture = departureStore.getDeparture(props.direction);
    departureStore.setDeparture(props.direction, { ...selectedDeparture, identifier: props.departure.identifier });
}
</script>

<style scoped lang="scss">
.departure {
    &__radio {
        position: relative;
        border-bottom: 1px solid var(--c-border);
        padding: 5px 20px;

        :deep(label) {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;

            div {
                display: flex;
                align-items: center;
                gap: 15px;

                @include media-breakpoint-down(md) {
                    gap: 10px;
                }
            }
        }

        &--departure {
            padding-left: 27px;

            @include media-breakpoint-down(md) {
                padding-left: 5px;
            }
        }
        &--arrival {
            padding-right: 15px;
            @include media-breakpoint-down(md) {
                padding-right: 0px;
            }
        }
    }

    .active {
        background-color: var(--c-success);
    }

    .vehicle-info {
        flex: 0 0 auto;

        &--plenty {
            color: #56bd66;
        }

        &--limited {
            color: #f7cc70;
        }

        &--none {
            color: #f77a70;
        }
    }
}
.disabled {
    opacity: 0.6;
}
</style>
