<template>
    <form @submit.prevent="create" class="details-form">
        <slot name="header">
            <div class="prose">
                <div class="subtitle">
                    <b v-if="isAgent" class="agent-title">{{ $t("agent.title") }}</b>
                    <b v-if="isAmend" class="agent-title">{{ $t("bookingFlow.isAmend") }} {{ bookingStore.bookingNumber }}</b>
                </div>
                <h1 class="h2">{{ $t("customerDetails.title") }}</h1>
                <p v-if="isLoggedIn">{{ $t("customerDetails.form.welcome") }} {{ details?.name }}!</p>
                <p v-else>
                    {{ $t("customerDetails.haveAccount") }} <a href="#" @click="emit('abort')">{{ $t("customerDetails.login") }} </a>
                </p>
            </div>
        </slot>
        <VInput v-model="v$.email.$model" :hasError="v$.email.$error" :label="$t('fields.email.label')" type="email" />
        <VInput
            v-model="v$.confirmEmail.$model"
            v-if="!isLoggedIn"
            :hasError="v$.confirmEmail.$error"
            :label="$t('fields.confirmEmail.label')"
            autocomplete="new-password"
            type="email"
        />
        <div class="row" v-if="createNewAccount">
            <VInput v-model="v$.firstName.$model" :hasError="v$.firstName.$error" :label="$t('fields.firstname.label')" />
            <VInput v-model="v$.lastName.$model" :hasError="v$.lastName.$error" :label="$t('fields.lastname.label')" />
        </div>
        <div class="row" v-else>
            <VInput v-model="v$.name.$model" :hasError="v$.name.$error" :label="$t('fields.name.label')" />
        </div>
        <VInput
            v-model="v$.mobilePhone.$model"
            :hasError="v$.mobilePhone.$error"
            :label="$t('fields.phone.label')"
            v-maska
            data-maska="#############"
        />
        <div>
            <VCheckbox
                v-if="!isLoggedIn && !forceCreateAccount"
                v-model="createNewAccount"
                :label="$t('customerDetails.checkboxes.createAccount')"
                id="createAccount"
            />
            <fieldset v-if="createNewAccount" class="create-account" :class="{ 'create-account--force': forceCreateAccount }">
                <label>{{ $t("fields.address.label") }}</label>
                <VInput v-model="v$.address.$model" :hasError="v$.address.$error" :label="$t('fields.address.label')" />
                <div class="row">
                    <VInput v-model="v$.city.$model" :hasError="v$.city.$error" :label="$t('fields.city.label')" />
                    <VInput v-model="v$.zip.$model" :hasError="v$.zip.$error" :label="$t('fields.zip.label')" v-maska data-maska="### ##" />
                </div>
                <label>{{ $t("customerDetails.form.password") }}</label>
                <div class="row">
                    <VInput
                        v-model="v$.password.$model"
                        :hasError="v$.password.$error"
                        :label="$t('fields.password.label')"
                        type="password"
                        autocomplete="new-password"
                    />
                    <VInput
                        v-model="v$.passwordRepeat.$model"
                        :hasError="v$.passwordRepeat.$error"
                        :label="$t('fields.confirmPassword.label')"
                        type="password"
                    />
                </div>
            </fieldset>
        </div>
        <VCheckbox v-model="v$.gdprConsent.$model" :hasError="v$.gdprConsent.$error" :label="$t('customerDetails.checkboxes.GDPR')" id="gdpr" />

        <div v-if="v$.$errors.length" class="error-messages">
            <p v-for="error in [...new Set(v$.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
        </div>
        <div v-if="error" class="error-messages">
            <p>{{ error }}</p>
        </div>
        <slot name="footer"></slot>
    </form>
</template>

<script setup lang="ts">
import { BookingDetails } from "@/models/front/BookingDetails";
import { useBookingStore } from "@/store/booking";
import useVuelidate from "@vuelidate/core";
import { email, helpers, requiredIf, sameAs } from "@vuelidate/validators";
import { required } from "@/helpers/validators";
import { vMaska } from "maska";
import { VCheckbox, VInput } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
    forceCreateAccount?: boolean;
    createAccount?: boolean;
    isLoggedIn?: boolean;
    details?: BookingDetails;
    newEmail?: string;
}

export type CustomerDetailsFormState = {
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    address: string;
    zip: string;
    city: string;
    mobilePhone: string;
    confirmEmail: string;
    password: string;
    passwordRepeat: string;
    gdprConsent: boolean;
};

const bookingStore = useBookingStore();
const isAgent = computed(() => bookingStore.getIsBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const props = defineProps<Props>();

const emit = defineEmits<{
    (e: "abort"): void;
    (e: "submit", value: CustomerDetailsFormState): void;
}>();

const { t } = useI18n();

const createNewAccount = ref(props.forceCreateAccount || props.createAccount);

const state = reactive<CustomerDetailsFormState>({
    email: props.details?.email ?? props.newEmail ?? "",
    firstName: "",
    lastName: "",
    name: props.details?.name ?? "",
    address: props.details?.address ?? "",
    zip: props.details?.zip ?? "",
    city: props.details?.city ?? "",
    mobilePhone: props.details?.mobilePhone ?? "",
    confirmEmail: props.details?.email ?? "",
    password: "",
    passwordRepeat: "",
    gdprConsent: bookingStore.termsAccepted,
});

const validPassword = () => {
    return (state.password.length <= 10 && state.password.length >= 8) || !createNewAccount.value;
};

const requiredIfNewAccount = requiredIf(() => createNewAccount.value);

const notRequiredIfNewAccount = requiredIf(() => !createNewAccount.value);

const rules = {
    email: {
        required: helpers.withMessage("Du måste ange e-post", required),
        v: helpers.withMessage("E-post måste vara en giltig e-post adress", email),
    },
    confirmEmail: {
        v: helpers.withMessage(
            "E-postadressen måste vara samma i båda fälten",
            (val: string) => props.isLoggedIn === true || (val === state.email && val.toString().length > 0),
        ),
    },
    firstName: { v: helpers.withMessage(t("Förnamn krävs"), requiredIfNewAccount) },
    lastName: { v: helpers.withMessage(t("Efternamn krävs"), requiredIfNewAccount) },
    name: { v: helpers.withMessage(t("Namn krävs"), notRequiredIfNewAccount) },
    address: {
        required: helpers.withMessage(() => "Du måste ange adress", requiredIfNewAccount),
    },
    zip: {
        required: helpers.withMessage(() => "Du måste ange postnummer", requiredIfNewAccount),
        valid: helpers.withMessage(
            () => "Postnummer måste vara 6 tecken långt",
            (val: number) => !createNewAccount.value || val.toString().length === 6,
        ),
    },
    city: {
        required: helpers.withMessage(() => "Du måste ange stad", requiredIfNewAccount),
    },
    mobilePhone: {
        required: helpers.withMessage(
            () => "Du måste ange telefonnummer",
            (val: number) => !createNewAccount.value || val.toString().length > 6,
        ),
    },
    password: { v: helpers.withMessage(t("errorMessages.password"), validPassword) },
    passwordRepeat: { v: helpers.withMessage(t("errorMessages.passwordRepeat"), (val: string) => val === state.password) },
    gdprConsent: { v: helpers.withMessage("Du måste godtycka till att dina personuppgifter sparas", sameAs(true)) },
};

const v$ = useVuelidate(rules, state);

const error = ref("");
const create = async () => {
    const valid = await v$.value.$validate();

    if (!valid) return;
    error.value = "";

    emit("submit", state);
};

defineExpose({
    createNewAccount,
    state,
});
</script>

<style scoped lang="scss">
.details-form {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .subtitle {
        display: flex;
        justify-content: space-between;
    }
    .row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 10px;

        > * {
            flex: 1;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
            }
        }
    }

    .create-account {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px;
        background-color: #fafafa;
        border: none;

        &--force {
            padding: 0;
            background-color: transparent;
        }
    }

    .isLoginPage {
        background-color: inherit;
        padding: 0;
    }
}
</style>
