<template>
    <form @submit.prevent="submit" class="details" :key="user?.email">
        <div class="row">
            <VInput v-model="v$.firstName.$model" :label="$t('fields.firstname.label')" :hasError="v$.firstName.$error" />
            <VInput v-model="v$.lastName.$model" :label="$t('fields.lastname.label')" :hasError="v$.lastName.$error" />
        </div>

        <div class="row">
            <VInput v-model="v$.email.$model" :label="$t('fields.email.label')" :hasError="v$.email.$error" />
            <VInput v-model="v$.mobilePhone.$model" :label="$t('fields.phone.label')" :hasError="v$.mobilePhone.$error" />
        </div>
        <div class="row">
            <VInput v-model="v$.address.$model" :label="$t('fields.address.label')" :hasError="v$.address.$error" />
            <VInput v-model="v$.city.$model" :label="$t('fields.city.label')" :hasError="v$.city.$error" />
            <VInput v-model="v$.zip.$model" :label="$t('fields.zip.label')" :hasError="v$.zip.$error" />
        </div>
        <div class="row">
            <VInput
                type="password"
                v-model="v$.password.$model"
                :label="$t('fields.password.label')"
                :hasError="v$.password.$error"
                :outerLabel="$t('myPages.myDetails.enterPassword')"
            />
        </div>
        <VBtn :isLoading="isLoading">{{ $t("myPages.myProfile.save") }}</VBtn>
    </form>
    <form @submit.prevent="resetPassword" class="reset">
        <h5>{{ $t("myPages.myProfile.reset") }}</h5>
        <div class="reset-info">
            <p>{{ $t("myPages.myProfile.resetInfo") }}</p>
            <VBtn :isLoading="isLoadingForgot">{{ $t("myPages.myProfile.sendLink") }}</VBtn>
        </div>
    </form>
</template>

<script setup lang="ts">
import useLogin from "@/composables/auth/useLogin";
import useProfileDetails from "@/composables/profile/useProfileDetails";
import { useUserStore } from "@/store/user";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { VBtn, VInput } from "v-ferry-components";
import { computed, reactive } from "vue";
import useSnackbar from "@/composables/useSnackbar";

const userStore = useUserStore();

const user = computed(() => userStore.getUserDetails);

const { updateDetails, isLoading } = useProfileDetails();

const state = reactive({
    firstName: user.value?.firstName ?? "",
    lastName: user.value?.lastName ?? "",
    email: user.value?.email ?? "",
    mobilePhone: user.value?.mobilePhone ?? "",
    address: user.value?.address ?? "",
    city: user.value?.city ?? "",
    zip: user.value?.zip ?? 0,
    password: "",
});

const rules = {
    firstName: { required },
    lastName: { required },
    email: { required },
    mobilePhone: { required },
    address: { required },
    city: { required },
    zip: { required },
    password: { required },
};

const v$ = useVuelidate(rules, state, { $scope: false });

const { displaySnack } = useSnackbar();

const submit = async () => {
    const isValid = await v$.value.$validate();

    if (!isValid) return;

    updateDetails(state)
        .then(() => {
            displaySnack({
                message: "Dina personuppgifter har blivit uppdaterade.",
                type: "success",
                id: `${user.value?.email}newDetails`,
            });
        })
        .catch((err) => {
            displaySnack({
                message: err.response.data.Message,
                type: "danger",
                id: `${user.value?.email}newDetailsFail`,
            });
        });
};

const { sendForgottenPassword, isLoading: isLoadingForgot } = useLogin();

const resetPassword = () => {
    sendForgottenPassword(userStore.getUser.userName);
};
</script>

<style lang="scss" scoped>
.details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    max-width: 600px;

    .row {
        display: flex;
        flex-flow: row wrap;

        gap: 10px;
        width: 100%;

        .v-input {
            flex: 1;
            min-width: 200px;
        }
    }

    .pass {
        margin-top: 30px;
    }

    :deep(.v-btn) {
        align-self: flex-end;
    }
}

.reset {
    background-color: var(--c-bg);
    padding: 15px;
    max-width: 600px;

    border-radius: 5px;
    h5 {
        font-size: 1rem;
        margin-bottom: 5px;
    }

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 15px;

        .v-btn {
            align-self: center;
        }
    }
}
</style>
