import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "open-ticket"
}
const _hoisted_2 = { class: "open-ticket__section" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "h3" }
const _hoisted_5 = { class: "subtitle prose" }
const _hoisted_6 = { class: "ticket-select" }
const _hoisted_7 = {
  key: 0,
  class: "error-messages"
}
const _hoisted_8 = { class: "open-ticket__section" }
const _hoisted_9 = { class: "title" }
const _hoisted_10 = { class: "h3" }
const _hoisted_11 = { class: "subtitle prose" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = {
  key: 0,
  class: "error-messages"
}
const _hoisted_14 = { class: "error-messages large" }

import BaseLoader from "@/components/base/BaseLoader.vue";
import useBook from "@/composables/book/useBook";
import useOpenTicketsQuery from "@/composables/open-tickets/useOpenTicketsQuery";
import { required } from "@/helpers/validators";
import { BookRequest } from "@/models/front/BookRequest";
import useVuelidate from "@vuelidate/core";
import { vMaska } from "maska";
import { VInput } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import TicketsSelectOption from "../ticketSelection/TicketsSelectOption.vue";
import { useOpenTicketStore } from "@/store/openTicket";
import { helpers } from "@vuelidate/validators";


export default /*@__PURE__*/_defineComponent({
  __name: 'OpenTicket',
  setup(__props, { expose: __expose }) {

const { data, isLoading } = useOpenTicketsQuery();

const { book } = useBook();

const openTicketStore = useOpenTicketStore();

const ticketAmount = computed({
    get() {
        return openTicketStore.getNumberOfTickets ?? 1;
    },
    set(value: number) {
        openTicketStore.setNumberOfTickets(+value);
    },
});

const state = reactive({
    tickets: ticketAmount.value,
    mobilePhone: "",
    confirmMobilePhone: "",
});

const rules = {
    tickets: {
        v: helpers.withMessage("Du behöver välja antal biljetter", (v: number) => v > 0),
        required,
    },
    mobilePhone: {
        required,
    },
    confirmMobilePhone: {
    	v: helpers.withMessage("Telefonnumren matchar inte. Ange ditt telefonnummer igen för att bekräfta.", (v: string) => v === state.mobilePhone),
        required,
    },
};

const v$ = useVuelidate(rules, state);

const error = ref("");

const validateStepAsync = () => {
    const identifier = openTicketStore.getIdentifier;

    if (!identifier) return;

    const request: BookRequest = {
        identifiers: identifier.split("&"),
        vehicleDetails: [],
        bookingNumber: -1,
        phoneNumber: state.mobilePhone
    };

    return new Promise((resolve, reject) => {
        book(request)
            .then(() => {
                resolve(true);
            })
            .catch((err) => {
                error.value = err.message;
                reject();
            });
    });
};

__expose({
    validateStepAsync,
});

return (_ctx: any,_cache: any) => {
  return (_unref(isLoading))
    ? (_openBlock(), _createBlock(BaseLoader, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("openTicket.title")), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("openTicket.subtitle")), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(TicketsSelectOption, {
              modelValue: ticketAmount.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ticketAmount).value = $event)),
              icon: "person",
              title: _ctx.$t('openTicket.ticket.label'),
              max: 20
            }, null, 8, ["modelValue", "title"])
          ]),
          (_unref(v$).tickets.$error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).tickets.$errors.map((e) => e.$message))], (error) => {
                  return (_openBlock(), _createElementBlock("p", {
                    key: error.toString()
                  }, _toDisplayString(error), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t("openTicket.form.title")), 1),
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("openTicket.form.subtitle")), 1)
          ]),
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_12, [
              _withDirectives(_createVNode(_unref(VInput), {
                modelValue: _unref(v$).mobilePhone.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).mobilePhone.$model) = $event)),
                hasError: _unref(v$).mobilePhone.$error,
                label: _ctx.$t('fields.phone.label'),
                autocomplete: "tel",
                type: "tel",
                "data-maska": "#############"
              }, null, 8, ["modelValue", "hasError", "label"]), [
                [_unref(vMaska)]
              ]),
              _withDirectives(_createVNode(_unref(VInput), {
                modelValue: _unref(v$).confirmMobilePhone.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).confirmMobilePhone.$model) = $event)),
                hasError: _unref(v$).confirmMobilePhone.$error,
                label: _ctx.$t('fields.confirmPhone.label'),
                autocomplete: "tel",
                type: "tel",
                "data-maska": "#############"
              }, null, 8, ["modelValue", "hasError", "label"]), [
                [_unref(vMaska)]
              ])
            ]),
            (_unref(v$).confirmMobilePhone.$error)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).confirmMobilePhone.$errors.map((e) => e.$message))], (error) => {
                    return (_openBlock(), _createElementBlock("p", {
                      key: error.toString()
                    }, _toDisplayString(error), 1))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 32)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("p", null, _toDisplayString(error.value), 1)
        ])
      ]))
}
}

})