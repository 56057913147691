<template>
    <div :class="{ 'booking-basket': !isMobile, 'mobile-basket': isMobile }">
        <div class="booking-basket__header">
            <h5 v-if="isAmend">{{ $t("bookingFlow.isAmend") }} {{ bookingStore.getBookingNumber }}</h5>
            <h2>{{ $t("basket.title") }}</h2>
            <h5 v-if="isAgent">{{ $t("agent.title") }}</h5>
            <div class="basket-divider">
                <div class="basket-divider__overlay"></div>
            </div>
        </div>
        <BaseLoader v-if="isLoading" />
        <div v-else class="booking-basket__content">
            <div v-if="isFetching" class="loading-overlay">
                <BaseLoader />
            </div>
            <ul v-for="(section, index) in sections" :key="index">
                <li v-if="!isCardPurchase" class="heading">
                    <span>{{ $t(`routes.${section.route}`) }}</span>
                </li>
                <li v-if="!isCardPurchase" class="heading date">{{ dayDateTime(section.departureDate) }}</li>
                <BasketResources v-if="section.resources" :resources="section.resources" />
            </ul>
            <p v-if="isAgent">{{ $t("basket.agentVAT") }} 25%</p>
            <ul class="booking-basket__summary" v-if="showSummary === true">
                <li class="nonrefund" v-if="amd > 0">
                    <span>{{ $t("basket.amendmentFee") }}</span>
                    <span>{{ amd }}kr</span>
                </li>
                <li>
                    <span>{{ $t("basket.total") }}</span>
                    <span>{{ total }}kr</span>
                </li>
                <li v-if="paid">
                    <span>{{ $t("basket.paid") }}</span>
                    <span>{{ paid }}kr</span>
                </li>
                <li v-if="paid && total && paid > (total + (nonRefundable ?? 0))">
                    <span>{{ $t("basket.refund") }}</span>
                    <span>{{ total - paid }}kr</span>
                </li>
                <li v-else-if="total && paid && (total - paid) > 0">
                    <span>{{ $t("basket.topay") }}</span>
                    <span>{{ total - paid }}kr</span>
                </li>
            </ul>
            <ul v-else>
            	<li class="title">
                    <span>{{ $t("basket.unknown") }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseLoader from "@/components/base/BaseLoader.vue";
import { dayDateTime } from "@/helpers/dateHelpers";
import { BookingDeparture } from "@/models/front/BookingDeparture";
import { useBookingStore } from "@/store/booking";
import { computed } from "vue";
import BasketResources from "./BasketResources.vue";

const bookingStore = useBookingStore();
const isAgent = computed(() => bookingStore.getIsBusiness);

const isAmend = computed(() => bookingStore.getIsAmendment);

interface Props {
    sections?: BookingDeparture[];
    total?: number;
    paid?: number;
    amd?: number;
    isLoading?: boolean;
    isMobile?: boolean;
    isCardPurchase?: boolean;
    isFetching?: boolean;
    showSummary: boolean;
}

const props = defineProps<Props>();

</script>

<style lang="scss" scoped>
.mobile-basket {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}
.booking-basket {
    $prefix: ".booking-basket";
    flex-direction: column;
    display: none;
    gap: 10px;
    padding: 30px;
    width: 280px;
    height: fit-content;
    background-color: var(--c-base);
    color: var(--c-primary);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);

    @include media-breakpoint-up(lg) {
        display: flex;
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h2 {
            font-size: 1.25rem;
            color: inherit;
            text-align: center;
        }

        h5 {
            text-align: center;
        }
    }

    .basket-divider {
        position: relative;
        left: -35px;
        width: calc(100% + 70px);
        height: 40px;
        background-image: linear-gradient(to right, var(--c-primary) 14px, rgba(255, 255, 255, 0) 0%);
        background-position: 10px center;
        background-size: 32px 1px;
        background-repeat: repeat-x;
        overflow: hidden;

        &::before,
        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            width: 40px;
            height: 40px;
            background-color: var(--c-bg);
            box-shadow: inset var(--box-shadow);
            border-radius: 100%;
        }

        &::before {
            left: 5px;
            transform: translate3d(-50%, 0, 0);
        }

        &::after {
            right: 5px;
            transform: translate3d(50%, 0, 0);
        }

        &__overlay {
            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                width: 5px;
                height: 40px;
                background-color: var(--c-bg);
                z-index: 1;
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 30px;

        ul {
            list-style: none;

            li {
                display: flex;
                justify-content: space-between;

                &.heading {
                    font-weight: bold;
                    justify-content: flex-start;
                    gap: 5px;

                    :deep(+ .price) {
                        margin-top: 10px;
                    }
                }

                &.date {
                    text-transform: capitalize;
                }
            }
        }
    }

    &__summary {
        margin-top: auto;
        padding-top: 30px;
        border-top: 1px solid var(--c-primary);
		
        li {
            font-weight: bold;
                
            &:nth-of-type(2) {
            	margin-top: 8px;
            }
        }
    }
}
</style>
