import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "timetable-details" }
const _hoisted_2 = { class: "messages" }
const _hoisted_3 = { class: "regular" }
const _hoisted_4 = { class: "table" }
const _hoisted_5 = { class: "table-item__name" }
const _hoisted_6 = { class: "table-item__time" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "messages" }
const _hoisted_9 = { class: "special" }
const _hoisted_10 = { class: "table" }
const _hoisted_11 = { class: "table-item__name" }
const _hoisted_12 = { class: "table-item__time" }

import { computed } from "vue";
import { TimeTable } from "@/models/front/Timetable";

interface Props {
    timeTable: TimeTable;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TimeTable',
  props: {
    timeTable: {}
  },
  setup(__props: any) {

const props = __props;

const messages = computed(() => {
    const array: string[] = [];
    props.timeTable.regularSchedule.forEach((schedule) => {
        schedule.times.forEach((timeEntry) => {
            if (timeEntry.info !== "") {
                array.push(timeEntry.info);
            }
        });
    });
    return array;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "h3" }, "Turlista", -1)),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeTable.information, (info, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(info), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", null, "Turlista fr om " + _toDisplayString(_ctx.timeTable.startDate) + " t o m " + _toDisplayString(_ctx.timeTable.endDate), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeTable.regularSchedule, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "table-item"
          }, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(item.days), 1),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.times, (time, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "time"
                }, [
                  _createTextVNode(_toDisplayString(time.time), 1),
                  (time.info)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "*"))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messages.value, (message, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, "* " + _toDisplayString(message), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("h5", null, "Särskilda turer för " + _toDisplayString(_ctx.timeTable.specialDays.holidayNames), 1),
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeTable.specialDays.holidays, (holiday, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "table-item"
          }, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(holiday.days), 1),
            _createElementVNode("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(holiday.times, (time, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  class: "time"
                }, _toDisplayString(time), 1))
              }), 128))
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})