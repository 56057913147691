import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "payment" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = {
  key: 0,
  class: "agent-title"
}
const _hoisted_4 = {
  key: 1,
  class: "agent-title"
}
const _hoisted_5 = {
  key: 0,
  class: "h2"
}
const _hoisted_6 = {
  key: 1,
  class: "h2"
}
const _hoisted_7 = {
  key: 2,
  class: "loading-overlay"
}
const _hoisted_8 = {
  key: 3,
  class: "payment-details"
}
const _hoisted_9 = {
  key: 0,
  class: "payment-details__options"
}
const _hoisted_10 = {
  key: 1,
  class: "payment-details__options"
}
const _hoisted_11 = {
  key: 0,
  class: "leftToPay"
}
const _hoisted_12 = {
  key: 1,
  class: "leftToPay"
}
const _hoisted_13 = {
  key: 3,
  class: "error-messages"
}
const _hoisted_14 = {
  key: 4,
  class: "error-messages"
}
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "payment-details__terms" }
const _hoisted_17 = { class: "payment-details__footer" }

import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseLoader from "@/components/base/BaseLoader.vue";
import usePaymentQuery, { StartPaymentRequest } from "@/composables/payment/usePaymentQuery";
import { getQueryParamValue } from "@/helpers/urlHelper";
import { required } from "@/helpers/validators";
import { useBookingStore } from "@/store/booking";
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import { VBtn, VCheckbox, VModal, VInput } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import PaymentOption from "./PaymentOption.vue";
import TravelTerms from "./TravelTerms.vue";

interface Props {
    bookingReference?: string;
    isCardPurchase?: boolean;
}

const checked = true;


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentDetails',
  props: {
    bookingReference: {},
    isCardPurchase: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;

const bookingStore = useBookingStore();
const isBusiness = computed(() => bookingStore.isBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const { t } = useI18n();

const paymentAborted = getQueryParamValue("abort") === "true";
const paymentFailed = getQueryParamValue("failed") === "true";

const reference = computed(() => props.bookingReference ?? undefined);

const { data: paymentAlternatives, isLoading, startPayment, isInitializingPayment } = usePaymentQuery(reference.value);

const showModal = ref(false);

const state = reactive({
    paymentMethod: "",
    terms: false,
    invoiceRef: "",
});

const leftToPay = computed(() => paymentAlternatives?.value?.leftToPay ?? 0);

const rules = {
    paymentMethod: {
        required: helpers.withMessage(
            t("paymentDetails.requiredMethod"),
            requiredIf(function () {
                return leftToPay.value > 0;
            }),
        ),
    },
    terms: { required, val: (val: boolean) => !!val },
    invoiceRef: {
    	required: helpers.withMessage(
            () => t("fields.invoiceRef.missing"),
            (val: string) => (showInvoiceRef.value === false || (showInvoiceRef.value === true && val.length > 0))
        ),
    }
};

const v$ = useVuelidate(rules, state);

const errorMessage = ref("");

const payCard = async () => {
		
    const v$ = useVuelidate(rules, state);

    const isValid = await v$.value.$validate();

    if (!isValid) {
        return;
    }
    
	validateStepAsync();
};

const validateStepAsync = () => {
	
    errorMessage.value = "";
    const paymentTokens: StartPaymentRequest[] = [{ token: state.paymentMethod, reference: state.invoiceRef }];

    return startPayment(paymentTokens).catch((err) => {
        errorMessage.value = t(`errorCodes.${err.response.data.ErrorCode}`);
    });
};

const nothingToPay = computed(() => leftToPay?.value === 0);
const showInvoiceRef = computed(() => paymentAlternatives?.value?.alternatives?.some((alt) => alt.type === 'Invoice' && alt.token === state.paymentMethod) ?? false);

__expose({
    validateStepAsync,
    nothingToPay,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (isBusiness.value)
        ? (_openBlock(), _createElementBlock("b", _hoisted_3, _toDisplayString(_ctx.$t("agent.title")), 1))
        : _createCommentVNode("", true),
      (isAmend.value)
        ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_ctx.$t("bookingFlow.isAmend")) + " " + _toDisplayString(_unref(bookingStore).bookingNumber), 1))
        : _createCommentVNode("", true)
    ]),
    (nothingToPay.value === false)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_unref(t)("paymentDetails.title.default")), 1))
      : (_openBlock(), _createElementBlock("h1", _hoisted_6, _toDisplayString(_unref(t)("paymentDetails.title.confirm")), 1)),
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(BaseLoader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_unref(paymentAlternatives)?.usedCards.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("h5", null, _toDisplayString(_ctx.$t("paymentDetails.usedCards")), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(paymentAlternatives)?.usedCards, (card, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "payment-option"
                  }, [
                    _createVNode(_unref(VCheckbox), {
                      id: card.token,
                      label: _ctx.$t(`paymentDetails.paymentMethods.${card.description}.${card.travelCardType}`),
                      modelValue: checked,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checked) = $event)),
                      disabled: ""
                    }, null, 8, ["id", "label"])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (leftToPay.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (leftToPay.value >= 0 && !_ctx.isCardPurchase)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("p", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t("paymentDetails.leftToPay")) + " ", 1),
                        _createElementVNode("b", null, _toDisplayString(leftToPay.value) + "kr", 1)
                      ])
                    ]))
                  : (!_ctx.isCardPurchase)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("p", null, [
                          _createTextVNode(_toDisplayString(_ctx.$t("paymentDetails.refund")) + " ", 1),
                          _createElementVNode("b", null, _toDisplayString(Math.abs(leftToPay.value)) + "kr", 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(paymentAlternatives)?.alternatives, (alternative) => {
                  return (_openBlock(), _createBlock(PaymentOption, {
                    modelValue: _unref(v$).paymentMethod.$model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).paymentMethod.$model) = $event)),
                    label: _ctx.$t(`paymentDetails.paymentMethods.${alternative.description}.label`),
                    value: alternative.token,
                    key: alternative.description,
                    icon: alternative.icon,
                    hasError: _unref(v$).paymentMethod.$error
                  }, null, 8, ["modelValue", "label", "value", "icon", "hasError"]))
                }), 128)),
                (showInvoiceRef.value)
                  ? (_openBlock(), _createBlock(_unref(VInput), {
                      key: 2,
                      modelValue: _unref(v$).invoiceRef.$model,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).invoiceRef.$model) = $event)),
                      hasError: _unref(v$).invoiceRef.$error,
                      label: _ctx.$t('fields.invoiceRef.label')
                    }, null, 8, ["modelValue", "hasError", "label"]))
                  : _createCommentVNode("", true),
                (_unref(v$).paymentMethod.$error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).paymentMethod.$errors.map((e) => e.$message))], (error) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: error.toString()
                        }, _toDisplayString(error), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_unref(v$).invoiceRef.$error)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).invoiceRef.$errors.map((e) => e.$message))], (error) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: error.toString()
                        }, _toDisplayString(error), 1))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                paymentAborted
                  ? (_openBlock(), _createBlock(BaseAlert, {
                      key: 5,
                      type: "danger",
                      message: _unref(t)('paymentDetails.cancelled')
                    }, null, 8, ["message"]))
                  : _createCommentVNode("", true),
                paymentFailed
                  ? (_openBlock(), _createBlock(BaseAlert, {
                      key: 6,
                      type: "danger",
                      message: _unref(t)('paymentDetails.failed')
                    }, null, 8, ["message"]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t("paymentDetails.nothingToPay")), 1)),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_unref(VCheckbox), {
              id: "terms",
              class: "terms",
              label: _ctx.$t('paymentDetails.terms.text'),
              modelValue: _unref(v$).terms.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).terms.$model) = $event)),
              hasError: _unref(v$).terms.$error
            }, null, 8, ["label", "modelValue", "hasError"]),
            _createVNode(_unref(VBtn), {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (showModal.value = true)),
              color: "ghost"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("paymentDetails.terms.link")), 1)
              ]),
              _: 1
            }),
            (showModal.value)
              ? (_openBlock(), _createBlock(_unref(VModal), {
                  key: 0,
                  onClose: _cache[5] || (_cache[5] = ($event: any) => (showModal.value = false)),
                  title: _ctx.$t('paymentDetails.terms.modal.title'),
                  isClosable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(TravelTerms)
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          (errorMessage.value?.length > 0)
            ? (_openBlock(), _createBlock(BaseAlert, {
                key: 3,
                type: "danger",
                message: errorMessage.value
              }, null, 8, ["message"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", null, [
              _createElementVNode("span", null, _toDisplayString(_unref(t)("paymentDetails.total")), 1),
              _createElementVNode("b", null, _toDisplayString(`${leftToPay.value < 0 ? "-" : ""}${Math.abs(leftToPay.value)}`) + "kr ", 1)
            ]),
            (_ctx.isCardPurchase === true)
              ? (_openBlock(), _createBlock(_unref(VBtn), {
                  key: 0,
                  onClick: payCard,
                  isLoading: _unref(isInitializingPayment)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(leftToPay.value > 0 ? _unref(t)("paymentDetails.pay") : _unref(t)("paymentDetails.confirm")), 1)
                  ]),
                  _: 1
                }, 8, ["isLoading"]))
              : _createCommentVNode("", true)
          ])
        ]))
  ]))
}
}

})