<template>
    <div class="payment-option" :class="{ 'payment-option--error': hasError, 'payment-option--active': inputValue === value }">
        <VRadio :id="value" :name="value" v-model="inputValue" :value="value" :label="label" :disabled="disabled">
            <template #default="{ label }">
                <div class="payment-option__label">
                    {{ label }}
                    <div v-if="isSwish" class="icon-swish"></div>
                    <div v-else class="icon">
                        <SvgIcon v-if="icon" :icon="icon" />
                    </div>
                </div>
            </template>
        </VRadio>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { VRadio } from "v-ferry-components";
import { computed } from "vue";

interface Props {
    value: string;
    modelValue?: string;
    icon?: string;
    disabled?: boolean;
    hasError?: boolean;
    label: string;
}

const props = defineProps<Props>();

const emit = defineEmits(["update:modelValue"]);

const inputValue = computed({
    get() {
        return props.modelValue;
    },
    set(val: string | undefined) {
        emit("update:modelValue", val);
    },
});

const isSwish = computed(() => props.label.toLocaleLowerCase().includes("swish"));
</script>

<style lang="scss">
.payment-option {
    display: flex;
    border: 1px solid var(--c-border);
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.2s ease;

    &__label {
        padding: 20px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .icon {
            &-swish {
                width: 2rem;
                height: 2rem;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("@/assets/icons/swish.svg");
            }
        }
    }

    &--error {
        background: #fff8f8;
        border-color: #ceadad;
    }

    &--active {
        background: transparentize(#526d99, 0.85);
        border-color: var(--c-primary);
    }

    .v-radio {
        width: 100%;
        label {
            width: 100%;
            padding-left: 40px !important;

            &::after {
                left: 20px !important;
            }

            &::before {
                left: 15px !important;
            }
        }
    }
}
</style>
