import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timetable" }
const _hoisted_2 = { class: "timetable-view" }
const _hoisted_3 = { class: "card-tabs" }

import { VCard } from "v-ferry-components";
import { computed, ref } from "vue";
import TimeTable from "./TimeTable.vue";
import ornoData from "./config/orno-timetable.json";
import dalaroData from "./config/dalaro-timetable.json";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const isOrno = ref(false);
const selectedTimetable = computed(() => (isOrno.value ? ornoData.orno : dalaroData.dalaro));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "timetable-background" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(VCard), null, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (isOrno.value = false)),
                class: _normalizeClass({ 'router-link-active': !isOrno.value })
              }, _toDisplayString(_ctx.$t("timetable.dalaro")), 3)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (isOrno.value = true)),
                class: _normalizeClass({ 'router-link-active': isOrno.value })
              }, _toDisplayString(_ctx.$t("timetable.orno")), 3)
            ])
          ]),
          _createVNode(TimeTable, { timeTable: selectedTimetable.value }, null, 8, ["timeTable"])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})