import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "checkout" }

import useCalculateQuery from "@/composables/useCalculateQuery";
import BookingBasket from "@/modules/BookingFlow/components/BookingBasket.vue";
import PaymentDetails from "@/modules/BookingFlow/components/paymentDetails/PaymentDetails.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { query } = useRoute();

const reference = computed(() => {
    return query.reference?.toString();
});

const { data, isLoading } = useCalculateQuery(reference.value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PaymentDetails, {
      bookingReference: reference.value,
      isCardPurchase: ""
    }, null, 8, ["bookingReference"]),
    _createVNode(BookingBasket, {
      sections: _unref(data)?.sections,
      total: _unref(data)?.totalPrice,
      isLoading: _unref(isLoading),
      isCardPurchase: ""
    }, null, 8, ["sections", "total", "isLoading"])
  ]))
}
}

})