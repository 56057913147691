import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "modal__body prose"
}
const _hoisted_2 = {
  key: 0,
  class: "modal__body-section"
}
const _hoisted_3 = { class: "amend-confirm" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "error-messages" }
const _hoisted_6 = { key: 2 }

import BaseLoader from "@/components/base/BaseLoader.vue";
import useBookingsQuery from "@/composables/profile/useBookingsQuery";
import { BookingAmendmentResponse } from "@/models/api/BookingAmendResponse";
import { Booking } from "@/models/front/Booking";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { VBtn, VModal } from "v-ferry-components";
import { computed, ref } from "vue";

interface Props {
    booking: Booking;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AmendBookingModal',
  props: {
    booking: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { amendBookingOpen, isLoadingOpen } = useBookingsQuery();

const isAgent = computed(() => useBookingStore().getIsBusiness);

const userStore = useUserStore();

const isOpen = ref(false);

const amendInfo = ref<BookingAmendmentResponse>();

const error = ref(false);

amendBookingOpen({ email: userStore.user.userName, bookingNumber: props.booking.bookingNumber, token: props.booking.token })
    .then((resp) => {
        amendInfo.value = resp;
        isOpen.value = true;
    })
    .catch(() => {
        error.value = true;
    });

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_unref(VModal), {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('close'))),
    title: _ctx.$t('myPages.myBooking.item.amendBooking.title')
  }, {
    default: _withCtx(() => [
      (_unref(isLoadingOpen))
        ? (_openBlock(), _createBlock(BaseLoader, {
            key: 0,
            alignCenter: ""
          }))
        : (isOpen.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (amendInfo.value?.canAmend)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("myPages.myBooking.item.bookingno")) + ": " + _toDisplayString(_ctx.booking.bookingNumber), 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_unref(VBtn), null, {
                        default: _withCtx(() => [
                          (isAgent.value || amendInfo.value.isBusiness)
                            ? (_openBlock(), _createBlock(_component_RouterLink, {
                                key: 0,
                                to: `/company/?s=1`
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("myPages.myBooking.item.amendment.confirm")), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_RouterLink, {
                                key: 1,
                                to: `/?s=1`
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("myPages.myBooking.item.amendment.confirm")), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("p", null, _toDisplayString(amendInfo.value?.message), 1)
                    ])
                  ]))
            ]))
          : (error.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
                _createElementVNode("div", { class: "error-messages" }, [
                  _createElementVNode("p", null, "Någonting gick snett..")
                ], -1)
              ])))
            : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})