import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "thank-you"
}
const _hoisted_2 = {
  key: 1,
  class: "thank-you__section container"
}
const _hoisted_3 = {
  key: 0,
  class: "booking prose"
}
const _hoisted_4 = { class: "booking__section" }
const _hoisted_5 = { class: "h5" }
const _hoisted_6 = { class: "booking__section prose" }
const _hoisted_7 = { class: "h5" }
const _hoisted_8 = { class: "heading" }
const _hoisted_9 = { class: "date" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 1,
  class: "card-purchase"
}
const _hoisted_13 = {
  key: 2,
  class: "thank-you__section"
}

import OpenTicket from "./components/OpenTicket.vue";
import BaseLoader from "@/components/base/BaseLoader.vue";
import SiteHero from "@/components/layout/SiteHero.vue";
import useCompleteQuery from "@/composables/thankyou/useCompleteQuery";
import { dayDateTime } from "@/helpers/dateHelpers";
import { UserType } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { VCard } from "v-ferry-components";
import { computed } from "vue";
import { useRoute } from "vue-router";

interface Props {
    isSessionless?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  props: {
    isSessionless: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { query } = useRoute();

const { data, isLoading } = useCompleteQuery(query.id?.toString(), props.isSessionless);

const booking = computed(() => data.value?.bookings[0]);

const isOpenTicket = computed(() => data.value?.isOpenEnded);
const isCardBooking = computed(() => data.value?.bookings.some((booking) => booking.departures.some((d) => d.route === "KORT")));

const tickets = computed(() => data.value?.bookings);

const bookingStore = useBookingStore();

bookingStore.resetBookingState();

const userStore = useUserStore();

const userType = userStore.getUserType;
if (userType === UserType.Guest) {
    userStore.logout();
}

return (_ctx: any,_cache: any) => {
  return (isOpenTicket.value === false)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(SiteHero, { class: "thank-you__hero" }),
        (_unref(isLoading))
          ? (_openBlock(), _createBlock(BaseLoader, { key: 0 }))
          : (_unref(data))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_unref(VCard), { class: "card" }, {
                  default: _withCtx(() => [
                    _createElementVNode("header", null, [
                      _createElementVNode("h2", null, _toDisplayString(!isCardBooking.value ? _ctx.$t("thankyou.booking.title") : _ctx.$t("thankyou.card.title")), 1),
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t("thankyou.booking.bookingno")) + " ", 1),
                        _createElementVNode("b", null, _toDisplayString(booking.value?.bookingNumber), 1)
                      ])
                    ]),
                    (!isCardBooking.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.$t("thankyou.booking.travelDocument.title")), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t("thankyou.booking.travelDocument.message")), 1),
                            _createElementVNode("p", null, [
                              _createElementVNode("b", null, _toDisplayString(_ctx.$t("thankyou.booking.travelDocument.obs")), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("thankyou.booking.travelDocument.info")), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$t("thankyou.booking.purchase.title")), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(booking.value?.departures, (section, index) => {
                              return (_openBlock(), _createElementBlock("ul", { key: index }, [
                                _createElementVNode("li", _hoisted_8, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.$t(`routes.${section.route}`)), 1),
                                  _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(dayDateTime)(section.departureDate)), 1)
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.resources, (resource) => {
                                  return (_openBlock(), _createElementBlock("li", {
                                    key: resource.description,
                                    class: "resource"
                                  }, [
                                    _createElementVNode("div", null, [
                                      _createTextVNode(_toDisplayString(resource.amount) + " ", 1),
                                      (resource.type)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(resource.description), 1))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(resource.description) + " " + _toDisplayString(resource.license ? `(${resource.license})` : ""), 1))
                                    ])
                                  ]))
                                }), 128))
                              ]))
                            }), 128))
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(booking.value?.departures, (section, index) => {
                            return (_openBlock(), _createElementBlock("ul", { key: index }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.resources, (resource) => {
                                return (_openBlock(), _createElementBlock("li", {
                                  key: resource.description,
                                  class: "resource"
                                }, [
                                  _createElementVNode("b", null, _toDisplayString(resource.amount) + " x " + _toDisplayString(resource.description), 1)
                                ]))
                              }), 128))
                            ]))
                          }), 128))
                        ])),
                    _createElementVNode("footer", null, [
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t("thankyou.booking.totalPrice")) + " ", 1),
                        _createElementVNode("b", null, _toDisplayString(booking.value?.totalPrice) + "kr", 1)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(_unref(VCard), { class: "card" }, {
                  default: _withCtx(() => [
                    _createElementVNode("header", null, [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t("errorMessages.error")) + "...", 1)
                    ])
                  ]),
                  _: 1
                })
              ]))
      ]))
    : (_openBlock(), _createBlock(OpenTicket, {
        key: 1,
        tickets: tickets.value
      }, null, 8, ["tickets"]))
}
}

})