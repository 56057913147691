<template>
    <div class="booking-stepper">
        <div class="container">
            <slot v-if="showSlot"></slot>
            <nav class="booking-stepper__nav">
                <VBtn @click="prevStep()" class="v-btn--back" color="ghost" :disabled="currentStep.number <= 1">
                    <span>{{ $t("bookingFlow.back") }}</span>
                </VBtn>
                <ul>
                    <li
                        v-for="step in steps"
                        :key="step.number"
                        :class="[{ active: step.number === currentStep.number }, { disabled: isDisabled(step.number) }]"
                    >
                        <RouterLink :to="{ query: { s: step.number } }">{{ $t("bookingFlow.step") }} {{ step.number }}</RouterLink>
                    </li>
                </ul>
                <VBtn @click="showSlot = !showSlot" class="v-btn--ghost cart">
                    <div class="cart-content">
                        <SvgIcon class="chevron" :class="{ isOpen: showSlot }" height="0.875rem" width="0.875rem" icon="chevron" />
                        <div class="cart-icon">
                            <SvgIcon icon="carbon/cart" height="1.25rem" />
                            <span v-if="basketSections" class="cart-icon__amount">{{ basketSections }}</span>
                        </div>
                    </div>
                </VBtn>
                <VBtn
                    @click="nextStep(currentStep.number + 1)"
                    :disabled="nextStepLoading || isLoading"
                    :isLoading="nextStepLoading || isLoading"
                >
                    <span> {{ currentStep.number === 5 ? (nothingToPay ?? false) ? "Bekräfta" : "Betala" : "Nästa" }}</span>
                </VBtn>
            </nav>
        </div>
    </div>
</template>

<script setup lang="ts">
import { VBtn } from "v-ferry-components";
import useBookingNavigation from "../composables/useBookingNavigation";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { ref } from "vue";

interface Props {
    nextStepLoading?: boolean;
    basketSections?: number;
    isLoading?: boolean;
    nothingToPay?: boolean;
    isOpenTicket?: boolean;
}

const props = defineProps<Props>();

const { steps, currentStep, prevStep } = useBookingNavigation(props.isOpenTicket);

const showSlot = ref(false);

const emit = defineEmits(["continue"]);

const nextStep = async (step: number) => {
    emit("continue", step);
};

const isDisabled = (step: number) => {
    return step > currentStep.value.number;
};
</script>

<style lang="scss" scoped>
.booking-stepper {
    position: sticky;
    bottom: 0;
    padding: 10px 0;
    background-color: var(--booking-stepper__bg);
    z-index: 10;
    width: 100%;
    box-shadow: var(--box-shadow);

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
            display: none;
            padding: 0;
            list-style: none;

            @include media-breakpoint-up(lg) {
                display: flex;
            }

            li {
                display: flex;
                align-items: center;
                cursor: pointer;

                a {
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-color: var(--booking-stepper__step-bg);
                    border-radius: 100%;
                    font-size: 0;
                }

                &.active {
                    a {
                        background-color: var(--booking-stepper__step-bg--active);
                    }
                }

                &.disabled {
                    pointer-events: none;
                }

                + li {
                    &::before {
                        content: "";
                        display: block;
                        margin: 0 20px;
                        width: 6px;
                        height: 6px;
                        background-color: var(--booking-stepper__step-bg);
                        border-radius: 100%;
                    }
                }
            }
        }

        .cart {
            display: none;
            padding: 5px;
            @include media-breakpoint-down(lg) {
                display: flex;
            }

            &-content {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 2px;

                .cart-icon {
                    position: relative;
                    &__amount {
                        position: absolute;
                        padding: 2px 5px;
                        position: absolute;
                        border-radius: 50%;
                        right: -10px;
                        top: -5px;
                        font-weight: bold;
                        font-size: 0.875rem;
                        border: var(--c-primary) 1px solid;
                        background-color: var(--booking-stepper__bg);
                    }
                }
            }
            .chevron {
                color: var(--c-primary);
                transform: rotate(180deg);
            }
            .isOpen {
                transform: rotate(0deg);
            }
        }
    }

    :deep(.v-btn) {
        width: 120px;
    }
}
</style>
