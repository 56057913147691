import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "booking-stepper" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "booking-stepper__nav" }
const _hoisted_4 = { class: "cart-content" }
const _hoisted_5 = { class: "cart-icon" }
const _hoisted_6 = {
  key: 0,
  class: "cart-icon__amount"
}

import { VBtn } from "v-ferry-components";
import useBookingNavigation from "../composables/useBookingNavigation";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { ref } from "vue";

interface Props {
    nextStepLoading?: boolean;
    basketSections?: number;
    isLoading?: boolean;
    nothingToPay?: boolean;
    isOpenTicket?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingStepper',
  props: {
    nextStepLoading: { type: Boolean },
    basketSections: {},
    isLoading: { type: Boolean },
    nothingToPay: { type: Boolean },
    isOpenTicket: { type: Boolean }
  },
  emits: ["continue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { steps, currentStep, prevStep } = useBookingNavigation(props.isOpenTicket);

const showSlot = ref(false);

const emit = __emit;

const nextStep = async (step: number) => {
    emit("continue", step);
};

const isDisabled = (step: number) => {
    return step > currentStep.value.number;
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (showSlot.value)
        ? _renderSlot(_ctx.$slots, "default", { key: 0 })
        : _createCommentVNode("", true),
      _createElementVNode("nav", _hoisted_3, [
        _createVNode(_unref(VBtn), {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(prevStep)())),
          class: "v-btn--back",
          color: "ghost",
          disabled: _unref(currentStep).number <= 1
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("bookingFlow.back")), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(steps), (step) => {
            return (_openBlock(), _createElementBlock("li", {
              key: step.number,
              class: _normalizeClass([{ active: step.number === _unref(currentStep).number }, { disabled: isDisabled(step.number) }])
            }, [
              _createVNode(_component_RouterLink, {
                to: { query: { s: step.number } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("bookingFlow.step")) + " " + _toDisplayString(step.number), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ], 2))
          }), 128))
        ]),
        _createVNode(_unref(VBtn), {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (showSlot.value = !showSlot.value)),
          class: "v-btn--ghost cart"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(SvgIcon, {
                class: _normalizeClass(["chevron", { isOpen: showSlot.value }]),
                height: "0.875rem",
                width: "0.875rem",
                icon: "chevron"
              }, null, 8, ["class"]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(SvgIcon, {
                  icon: "carbon/cart",
                  height: "1.25rem"
                }),
                (_ctx.basketSections)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.basketSections), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_unref(VBtn), {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (nextStep(_unref(currentStep).number + 1))),
          disabled: _ctx.nextStepLoading || _ctx.isLoading,
          isLoading: _ctx.nextStepLoading || _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_unref(currentStep).number === 5 ? (_ctx.nothingToPay ?? false) ? "Bekräfta" : "Betala" : "Nästa"), 1)
          ]),
          _: 1
        }, 8, ["disabled", "isLoading"])
      ])
    ])
  ]))
}
}

})