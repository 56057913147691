import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

import { BookingResource } from "@/models/front/BookingResource";

interface Props {
    resources: BookingResource[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BasketResources',
  props: {
    resources: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resources, (resource) => {
    return (_openBlock(), _createElementBlock("li", {
      key: resource.description,
      class: "price"
    }, [
      _createElementVNode("div", null, [
        _createTextVNode(_toDisplayString(resource.amount) + " ", 1),
        (resource.type)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t(`myPages.myBooking.item.passengerType.${resource.type}`)), 1))
          : (resource.isCard)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t(`myPages.myCards.cards.${resource.cardType}.${resource.cardSubType}`)), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(resource.description), 1))
      ]),
      _createElementVNode("span", null, _toDisplayString(resource.price) + "kr", 1)
    ]))
  }), 128))
}
}

})