<template>
    <div class="departure-dates">
        <VBtn @click="setPrevDate" class="v-btn--ghost departure-dates__prev">
            <SvgIcon icon="carbon/chevron-left" width="1.875rem" height="1.875rem" />
        </VBtn>
        <ul>
            <li v-for="date in dates" :key="YYYYMMDD(date)">
                <div class="departure-dates__radio">
                    <input
                        type="radio"
                        :id="`dd-${direction}-${YYYYMMDD(date)}`"
                        :name="`dd-${direction}`"
                        :value="YYYYMMDD(date)"
                        :disabled="date < minDate"
                        v-model="bookingDate"
                    />
                    <label :for="`dd-${direction}-${YYYYMMDD(date)}`">
                        <span class="week-day">{{ eeee(date) }}</span>
                        <span class="day">{{ dd(date) }}</span>
                        <span class="month">{{ MMMM(date) }}</span>
                    </label>
                </div>
            </li>
        </ul>
        <VBtn @click="setNextDate" class="v-btn--ghost departure-dates__next">
            <SvgIcon icon="carbon/chevron-right" width="1.875rem" height="1.875rem" />
        </VBtn>
    </div>
</template>

<script lang="ts" setup>
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { MMMM, YYYYMMDD, dd, eeee } from "@/helpers/dateHelpers";
import { Direction } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useDepartureStore } from "@/store/departure";
import { addDays, eachDayOfInterval, startOfDay, startOfToday } from "date-fns";
import { VBtn } from "v-ferry-components";
import { computed } from "vue";

interface Props {
    direction: Direction;
}

const props = defineProps<Props>();

const bookingStore = useBookingStore();

const departureStore = useDepartureStore();

const minDate = computed(() => {
    if (props.direction === Direction.Outward) {
        return startOfToday();
    } else {
        const outwardDate = bookingStore.getDate(Direction.Outward);
        return outwardDate ? addDays(new Date(outwardDate), -1) : startOfToday();
    }
});

const bookingDate = computed({
    get() {
        const date = bookingStore.getDate(props.direction);
        return date ? YYYYMMDD(date) : YYYYMMDD(minDate.value);
    },
    set(date?: string) {
        const selectedDate = date ? new Date(date) : undefined;

        if (!selectedDate || selectedDate < minDate.value) return;

        bookingStore.setDate(props.direction, selectedDate);

        if (props.direction === Direction.Outward) {
            const returnDate = bookingStore.getDate(Direction.Return);

            if (!returnDate || returnDate < selectedDate) {
                bookingStore.setDate(Direction.Return, selectedDate);
            }
        }
        departureStore.setDeparture(props.direction, {
            id: "",
            identifier: "",
            date: "",
        });
    },
});

// Gets an array of 5 dates, centered on [date]
function getDateRange(date: string) {
    const selectedDate = startOfDay(new Date(date));

    return eachDayOfInterval({
        start: addDays(selectedDate, -2),
        end: addDays(selectedDate, 2),
    });
}

const dates = computed(() => getDateRange(bookingDate.value));

const setPrevDate = () => {
    bookingDate.value = YYYYMMDD(addDays(new Date(bookingDate.value), -1));
};

const setNextDate = () => {
    bookingDate.value = YYYYMMDD(addDays(new Date(bookingDate.value), 1));
};
</script>

<style lang="scss" scoped>
.departure-dates {
    $prefix: ".departure-dates";

    display: flex;
    position: relative;

    ul {
        display: flex;
        align-items: center;
        gap: 10px;
        flex: 1;

        li {
            flex: 0 1 calc(100% / 3);

            @include media-breakpoint-up(md) {
                flex: 0 1 calc(100% / 5);
            }

            &:first-of-type,
            &:last-of-type {
                display: none;

                @include media-breakpoint-up(md) {
                    display: flex;
                }
            }

            &:nth-child(3) {
                #{$prefix}__radio {
                    label {
                        padding: 10px;
                    }
                }
            }
        }
    }

    &__prev,
    &__next {
        position: absolute;
        top: 50%;
        padding: 0;
        width: 30px;
        height: 30px;
        background-color: var(--c-base);
        border-radius: 100%;
        z-index: 1;

        &:hover {
            background-color: var(--c-base);
        }

        :deep(.v-btn__content) {
            display: flex;
        }
    }

    &__prev {
        left: 0;
        transform: translate3d(-50%, -50%, 0);
    }

    &__next {
        right: 0;
        transform: translate3d(50%, -50%, 0);
    }

    &__radio {
        position: relative;
        flex: 20%;

        input[type="radio"] {
            position: absolute;
            left: -9999px;
            opacity: 0;

            &:not(:disabled) {
                + label {
                    cursor: pointer;
                }

                &:checked {
                    + label {
                        background-color: var(--c-primary);
                        color: var(--c-primary-text);
                    }
                }

                &:focus-visible {
                    + label {
                        outline: 2px solid var(--c-focus);
                        outline-offset: -1px;
                    }
                }
            }

            &:disabled {
                + label {
                    opacity: var(--disabled);
                }
            }
        }

        label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--c-primary);
            padding: 5px 10px;
            border-radius: var(--border-radius);

            .week-day,
            .month {
                font-size: 1rem;
                text-transform: capitalize;
            }

            .day {
                font-size: 1.125rem;
                font-weight: bold;
            }
        }
    }
}
</style>
