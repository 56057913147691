<template>
    <div class="login-page">
        <div class="login-page__background"></div>
        <div class="login-form">
            <VCard>
                <div class="prose">
                    <h1 class="h4">{{ $t("loginPage.title.agent") }}</h1>
                    <p>
                        Logga in för att se, ändra och avboka dina bokningar som <b>företagskund</b>.
                        Vi önskar dig en trevlig resa!
                    </p>
                </div>
                <br />
                <LoginForm hideCreateAccount @onLogin="goToBooking" :isAgent="true" />
            </VCard>
        </div>
    </div>
</template>

<script setup lang="ts">
import LoginForm from "@/components/login/LoginForm.vue";
import { VBtn, VCard } from "v-ferry-components";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useBookingStore } from "@/store/booking";

const router = useRouter();

const bookingStore = useBookingStore();

const error = ref("");

const goToBooking = (state) => {
	
	bookingStore.setRoute(state?.defaultRoute);
	
    router.push(state?.type === 'Agent' ? "/company" : "/");
};
</script>

<style lang="scss" scoped>
.login-page {
    position: relative;
    width: 100%;
    min-height: 95.5vh;
    background: url("@/assets/images/dalaro-skans.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;

    &__background {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.15);
    }

    .login-form {
        margin: 10vh 0;
        width: 90vw;
        max-width: 600px;
        z-index: 1;
    }

    h2 {
        margin-bottom: 20px;
    }
}
</style>
