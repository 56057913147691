<template>
    <div class="timetable">
        <div class="timetable-background"></div>
        <div class="timetable-view">
            <VCard>
                <ul class="card-tabs">
                    <li>
                        <a @click="isOrno = false" :class="{ 'router-link-active': !isOrno }">{{ $t("timetable.dalaro") }}</a>
                    </li>
                    <li>
                        <a @click="isOrno = true" :class="{ 'router-link-active': isOrno }">{{ $t("timetable.orno") }}</a>
                    </li>
                </ul>
                <TimeTable :timeTable="selectedTimetable" />
            </VCard>
        </div>
    </div>
</template>

<script setup lang="ts">
import { VCard } from "v-ferry-components";
import { computed, ref } from "vue";
import TimeTable from "./TimeTable.vue";
import ornoData from "./config/orno-timetable.json";
import dalaroData from "./config/dalaro-timetable.json";

const isOrno = ref(false);
const selectedTimetable = computed(() => (isOrno.value ? ornoData.orno : dalaroData.dalaro));
</script>

<style scoped lang="scss">
.timetable {
    position: relative;
    width: 100%;
    min-height: 95.5vh;
    background: url("@/assets/images/dalaro-skans.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;

    &-background {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.15);
    }

    .v-card {
        position: relative;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        @include media-breakpoint-down(sm) {
            border-top-right-radius: 0px;
        }
    }

    &-view {
        margin-top: 90px;
        display: flex;
        align-items: center;

        ul {
            a {
                cursor: pointer;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include media-breakpoint-down(sm) {
                    font-size: 0.875rem;
                }
            }
        }
    }
}
</style>
