<template>
    <div class="departures">
        <header>
            <div class="subtitle">
                <b v-if="isBusiness" class="agent-title">{{ $t("agent.title") }}</b>
                <b v-if="isAmend" class="agent-title">{{ $t("bookingFlow.isAmend") }} {{ bookingStore.bookingNumber }}</b>
            </div>

            <h1 class="h2">{{ $t("departures.title") }}</h1>
            <div class="vehicle-info">
                <ul>
                    <li class="vehicle-info--0">
                        <SvgIcon icon="carbon/car" width="1.5rem" height="1.5rem" />
                        <span>{{ $t(`departures.vehicleInfo.0`) }}</span>
                    </li>
                    <li class="vehicle-info--1">
                        <SvgIcon icon="carbon/car" width="1.5rem" height="1.5rem" />
                        <span>{{ $t(`departures.vehicleInfo.1`) }}</span>
                    </li>
                    <li class="vehicle-info--2">
                        <SvgIcon icon="carbon/car" width="1.5rem" height="1.5rem" />
                        <span>{{ $t(`departures.vehicleInfo.2`) }}</span>
                    </li>
                </ul>
            </div>
        </header>
        <div v-for="(direction, index) in directions" :key="index">
            <DepartureSection :direction="direction" :disabled="direction === Direction.Outward && outwardIsLocked" />
            <div class="error-messages large">
                <p>{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useBook from "@/composables/book/useBook";
import { Direction } from "@/models/Enums";
import { BookRequest } from "@/models/front/BookRequest";
import { useBookingStore } from "@/store/booking";
import { useDepartureStore } from "@/store/departure";
import { useVehiclesStore } from "@/store/vehicles";
import { computed, ref, watch } from "vue";
import DepartureSection from "./DepartureSection.vue";
import { useQueryClient } from "vue-query";

const bookingStore = useBookingStore();
const isBusiness = computed(() => bookingStore.isBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);
const queryClient = useQueryClient();

const { book } = useBook();

const outwardIsLocked = computed(() => bookingStore.getBooking.outward.isLocked);

const directions = bookingStore.getTrips;
const departureStore = useDepartureStore();
const vehiclesStore = useVehiclesStore();

const error = ref("");

const validateStepAsync = () => {
    const request: BookRequest = {
        identifiers: departureStore.getDepartureIdentifiers(),
        vehicleDetails: vehiclesStore.getVehiclesDetails ?? [],
        bookingNumber: bookingStore.getBookingNumber,
    };

    return new Promise((resolve, reject) => {
        book(request)
            .then(() => {
                resolve(true);
            })
            .catch((err) => {
                error.value = err.message;
                reject();
            })
            .finally(() => {
                queryClient.invalidateQueries("calc");
            });
    });
};

watch(departureStore.getDepartureIdentifiers, () => {
    error.value = "";
});

defineExpose({
    validateStepAsync,
});
</script>

<style scoped lang="scss">
.departures {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 50px;

    .subtitle {
        display: flex;
        justify-content: space-between;
    }

    header {
        .vehicle-info {
            margin-top: 20px;
            ul {
                display: flex;
                flex-flow: row wrap;
                gap: 10px;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    gap: 5px;
                }

                li {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    font-size: var(--fs-sm);
                }
            }

            &--0 {
                :deep(.icon) {
                    color: #56bd66;
                }
            }

            &--1 {
                :deep(.icon) {
                    color: #f7cc70;
                }
            }

            &--2 {
                :deep(.icon) {
                    color: #f77a70;
                }
            }
        }
    }
}
</style>
