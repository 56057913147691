<template>
    <div class="vehicle-form">
        <div :class="{ row: hasVehicles }">
            <div @focusout="setVehicleReg">
                <VInput
                    v-model="v$.license.$model"
                    :hasError="v$.license.$error"
                    :label="t('ticketSelect.vehicleForm.license')"
                    :disabled="noLicense"
                />
            </div>

            <div v-if="hasVehicles" class="vehicles-container">
                <VBtn @click="isActive = !isActive">
                    <SvgIcon icon="carbon/car-front" />
                </VBtn>
                <div v-if="isActive" class="dropdown">
                    <ul>
                        <li v-for="vehicle in options" :key="vehicle.value">
                            <input
                                type="radio"
                                :name="vehicle.value"
                                :id="vehicle.value"
                                :value="vehicle.value"
                                @input="(e: any) => setVehicle(e.target?.value)"
                            />
                            <label :for="vehicle.value">{{ vehicle.label }}</label>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="size">
            <div @focusout="checkLengthWidth">
                <VInput
                    v-model="v$.length.$model"
                    :hasError="v$.length.$error"
                    :label="t('ticketSelect.vehicleForm.length')"
                    v-maska
                    data-maska="DE.DE"
                    data-maska-tokens="D:[0-9]|E:[0-9]:optional"
                />
            </div>
            <div @focusout="checkLengthWidth">
                <VInput
                    v-model="v$.width.$model"
                    :hasError="v$.width.$error"
                    :label="t('ticketSelect.vehicleForm.width')"
                    v-maska
                    data-maska="DE.DE"
                    data-maska-tokens="D:[0-9]|E:[0-9]:optional"
                />
            </div>
        </div>

        <VCheckbox v-if="isTrailer" v-model="noLicense" label="Släp har inget registeringsnr" id="trailerLicense" />
		<VCheckbox v-if="isTrailer !== true" v-model="noLicense" label="Hyrbil eller jag vet ej registeringsnr" id="vehicleLicense" />

        <div class="info">
            {{ $t("ticketSelect.vehicle.info") }}
        </div>
        <div v-if="v$.$errors.length" class="error-messages">
            <p v-for="error in [...new Set(v$.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useVehicleInfo, { VehicleInfoResponse } from "@/composables/vehicle-info/useVehicleInfo";
import { OptionItem } from "@/config/routes";
import { MyCoVehicle } from "@/models/front/MyCoVehicle";
import { useVehiclesStore } from "@/store/vehicles";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, alphaNum, requiredIf } from "@vuelidate/validators";
import { required } from "@/helpers/validators";
import { vMaska } from "maska";
import { VBtn, VCheckbox, VInput } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { watch } from "vue";

interface Props {
    title?: string;
    isTrailer?: boolean;
    vehicles?: MyCoVehicle[];
    isBusiness: boolean;
    hasNoLicense?: boolean;
}

const props = defineProps<Props>();

const options = computed(() => {
    if (props.vehicles) {
        return props.vehicles.map((v) => {
            return { label: v.licenseNumber, value: v.licenseNumber } as OptionItem;
        });
    } else {
        return [];
    }
});

const { t } = useI18n();

const hasVehicles = ref(options.value.length);

const isActive = ref(false);

const setVehicle = (val: string) => {
    const vehicle = props.vehicles?.find((v) => v.licenseNumber === val);
    isActive.value = false;

    if (vehicle) {
        state.license = vehicle.licenseNumber;
        state.length = vehicle.length;
        state.width = vehicle.width;

        registeredVehicleInfo.value = {
            licenseNumber: vehicle.licenseNumber,
            length: vehicle.length,
            width: vehicle.width,
        };
    }
};

const vehicleStore = useVehiclesStore();

const car = computed(() => vehicleStore.car);
const trailer = computed(() => vehicleStore.trailer);

const vehicleInfo = computed(() => (props.isTrailer ? trailer.value : car.value));

const noLicense = ref(props.hasNoLicense ?? false);

watch(noLicense, (current) => {
    if (current) {
    	
        state.license = props.isTrailer ? "" : "HYRBIL";
        
        if (props.isTrailer === false) {
	        registeredVehicleInfo.value = {
	            licenseNumber: 'HYRBIL',
	            length: 5,
	            width: 2,
	        };
    	}
    }
});

const state = reactive({
    license: vehicleInfo.value?.license ?? "",
    length: vehicleInfo.value?.length !== 0 ? vehicleInfo.value?.length : undefined,
    width: vehicleInfo.value?.width !== 0 ? vehicleInfo.value?.width : undefined
});

const checkLengthWidth = () => {
    if (!registeredVehicleInfo.value || state.length === undefined || state.width === undefined) return;

    if (+state.length < registeredVehicleInfo.value?.length) {
        state.length = registeredVehicleInfo.value?.length;
    }

    if (+state.width < registeredVehicleInfo.value?.width) {
        state.width = registeredVehicleInfo.value?.width;
    }
};

const licenseIsRequired = () => {
    return !noLicense.value;
};

const rules = {
    license: {
        v: helpers.withMessage("Registreringsnummer måste vara minst sex tecken och inga specialtecken", minLength(4)),
        v2: helpers.withMessage("Registreringsnummer måste vara minst sex tecken och inga specialtecken", alphaNum),
        required: requiredIf(licenseIsRequired),
    },
    length: {
        v: helpers.withMessage(
            "Maxlängd är 8 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 8 || props.isTrailer === true || props.isBusiness === true,
        ),
        v2: helpers.withMessage(
            "Maxlängd är 26 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 26 || props.isTrailer === true || props.isBusiness === false,
        ),
        v3: helpers.withMessage(
            "Maxlängd är 10 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 10 || props.isTrailer === false,
        ),
        required,
    },
    width: {
        v: helpers.withMessage(
            "Maxbredd är 3.5 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 3.5 || props.isTrailer === true || props.isBusiness === true,
        ),
        v2: helpers.withMessage(
            "Maxbredd är 4 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 4 || props.isTrailer === true || props.isBusiness === false,
        ),
        v3: helpers.withMessage(
            "Maxbredd är 2.5 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 2.5 || props.isTrailer === false,
        ),
        required,
    },
};

const v$ = useVuelidate(rules, state);

const registeredVehicleInfo = ref<VehicleInfoResponse>();

const { getVehicleInfo } = useVehicleInfo();

const setVehicleReg = () => {
    getVehicleInfo(state.license).then((resp) => {
        if (resp) {
            state.width = resp.width;
            state.length = resp.length;
            state.license = resp.licenseNumber;
            registeredVehicleInfo.value = resp;
        }
    });
};

const defaultVehicle = computed(() => props.vehicles?.find((v) => v.isDefault));

// set default vehicle
watch (defaultVehicle, (vehicle) => {
	if (!state.license && props.vehicles?.length > 0) {
		if (vehicle) {
			
			state.license ||= vehicle?.licenseNumber;
			state.width ||= vehicle?.width;
			state.length ||= vehicle?.length;
		}
	}
}, { immediate: true });

defineExpose({ state });
</script>

<style scoped lang="scss">
.vehicle-form {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 15px;

    .row {
        display: flex;
        position: relative;

        .v-btn {
            --v-btn-padding: 8px 10px;
            --v-btn-br: 0px 6px 6px 0px;
        }

        .v-input {
            --v-input-br: 6px 0px 0px 6px;
        }
    }

    .size {
        display: flex;
        gap: 5px;

        > * {
            width: 100%;
        }
    }

    .vehicles-container {
        .dropdown {
            position: absolute;
            width: 100%;
            z-index: 999;
            left: 0;
            background-color: white;
            border-radius: 6px;
            box-shadow: var(--box-shadow);

            ul {
                max-height: 200px;
                overflow-x: auto;
                z-index: 11;
            }

            li {
                &:hover {
                    background-color: var(--c-primary);
                    color: white;
                }

                &:first-child {
                    border-radius: 6px 6px 0px 0px;
                }

                &:last-child {
                    border-radius: 0px 0px 6px 6px;
                }

                & + li {
                    border-top: 1px solid var(--c-bg);
                }

                label {
                    padding: 10px;

                    width: 100%;
                    height: 100%;
                    display: block;
                    cursor: pointer;
                }
                input {
                    display: none;
                }
            }
        }
    }

    .info {
        font-size: 0.785rem;
    }
    .info + .error-messages {
        margin-top: 5px;
    }
}

.v-checkbox {
    background-color: transparent;
    padding: 2px 0px;
    font-size: 0.8rem;
}
</style>
