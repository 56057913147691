import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vehicle-form" }
const _hoisted_2 = {
  key: 0,
  class: "vehicles-container"
}
const _hoisted_3 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_4 = ["name", "id", "value"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "size" }
const _hoisted_7 = { class: "info" }
const _hoisted_8 = {
  key: 2,
  class: "error-messages"
}

import SvgIcon from "@/components/icons/SvgIcon.vue";
import useVehicleInfo, { VehicleInfoResponse } from "@/composables/vehicle-info/useVehicleInfo";
import { OptionItem } from "@/config/routes";
import { MyCoVehicle } from "@/models/front/MyCoVehicle";
import { useVehiclesStore } from "@/store/vehicles";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, alphaNum, requiredIf } from "@vuelidate/validators";
import { required } from "@/helpers/validators";
import { vMaska } from "maska";
import { VBtn, VCheckbox, VInput } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { watch } from "vue";

interface Props {
    title?: string;
    isTrailer?: boolean;
    vehicles?: MyCoVehicle[];
    isBusiness: boolean;
    hasNoLicense?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VehicleSelect',
  props: {
    title: {},
    isTrailer: { type: Boolean },
    vehicles: {},
    isBusiness: { type: Boolean },
    hasNoLicense: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {

const props = __props;

const options = computed(() => {
    if (props.vehicles) {
        return props.vehicles.map((v) => {
            return { label: v.licenseNumber, value: v.licenseNumber } as OptionItem;
        });
    } else {
        return [];
    }
});

const { t } = useI18n();

const hasVehicles = ref(options.value.length);

const isActive = ref(false);

const setVehicle = (val: string) => {
    const vehicle = props.vehicles?.find((v) => v.licenseNumber === val);
    isActive.value = false;

    if (vehicle) {
        state.license = vehicle.licenseNumber;
        state.length = vehicle.length;
        state.width = vehicle.width;

        registeredVehicleInfo.value = {
            licenseNumber: vehicle.licenseNumber,
            length: vehicle.length,
            width: vehicle.width,
        };
    }
};

const vehicleStore = useVehiclesStore();

const car = computed(() => vehicleStore.car);
const trailer = computed(() => vehicleStore.trailer);

const vehicleInfo = computed(() => (props.isTrailer ? trailer.value : car.value));

const noLicense = ref(props.hasNoLicense ?? false);

watch(noLicense, (current) => {
    if (current) {
    	
        state.license = props.isTrailer ? "" : "HYRBIL";
        
        if (props.isTrailer === false) {
	        registeredVehicleInfo.value = {
	            licenseNumber: 'HYRBIL',
	            length: 5,
	            width: 2,
	        };
    	}
    }
});

const state = reactive({
    license: vehicleInfo.value?.license ?? "",
    length: vehicleInfo.value?.length !== 0 ? vehicleInfo.value?.length : undefined,
    width: vehicleInfo.value?.width !== 0 ? vehicleInfo.value?.width : undefined
});

const checkLengthWidth = () => {
    if (!registeredVehicleInfo.value || state.length === undefined || state.width === undefined) return;

    if (+state.length < registeredVehicleInfo.value?.length) {
        state.length = registeredVehicleInfo.value?.length;
    }

    if (+state.width < registeredVehicleInfo.value?.width) {
        state.width = registeredVehicleInfo.value?.width;
    }
};

const licenseIsRequired = () => {
    return !noLicense.value;
};

const rules = {
    license: {
        v: helpers.withMessage("Registreringsnummer måste vara minst sex tecken och inga specialtecken", minLength(4)),
        v2: helpers.withMessage("Registreringsnummer måste vara minst sex tecken och inga specialtecken", alphaNum),
        required: requiredIf(licenseIsRequired),
    },
    length: {
        v: helpers.withMessage(
            "Maxlängd är 8 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 8 || props.isTrailer === true || props.isBusiness === true,
        ),
        v2: helpers.withMessage(
            "Maxlängd är 26 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 26 || props.isTrailer === true || props.isBusiness === false,
        ),
        v3: helpers.withMessage(
            "Maxlängd är 10 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 10 || props.isTrailer === false,
        ),
        required,
    },
    width: {
        v: helpers.withMessage(
            "Maxbredd är 3.5 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 3.5 || props.isTrailer === true || props.isBusiness === true,
        ),
        v2: helpers.withMessage(
            "Maxbredd är 4 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 4 || props.isTrailer === true || props.isBusiness === false,
        ),
        v3: helpers.withMessage(
            "Maxbredd är 2.5 meter, kontakta bokningskontoret per telefon för mer information",
            (v: number) => v <= 2.5 || props.isTrailer === false,
        ),
        required,
    },
};

const v$ = useVuelidate(rules, state);

const registeredVehicleInfo = ref<VehicleInfoResponse>();

const { getVehicleInfo } = useVehicleInfo();

const setVehicleReg = () => {
    getVehicleInfo(state.license).then((resp) => {
        if (resp) {
            state.width = resp.width;
            state.length = resp.length;
            state.license = resp.licenseNumber;
            registeredVehicleInfo.value = resp;
        }
    });
};

const defaultVehicle = computed(() => props.vehicles?.find((v) => v.isDefault));

// set default vehicle
watch (defaultVehicle, (vehicle) => {
	if (!state.license && props.vehicles?.length > 0) {
		if (vehicle) {
			
			state.license ||= vehicle?.licenseNumber;
			state.width ||= vehicle?.width;
			state.length ||= vehicle?.length;
		}
	}
}, { immediate: true });

__expose({ state });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({ row: hasVehicles.value })
    }, [
      _createElementVNode("div", { onFocusout: setVehicleReg }, [
        _createVNode(_unref(VInput), {
          modelValue: _unref(v$).license.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).license.$model) = $event)),
          hasError: _unref(v$).license.$error,
          label: _unref(t)('ticketSelect.vehicleForm.license'),
          disabled: noLicense.value
        }, null, 8, ["modelValue", "hasError", "label", "disabled"])
      ], 32),
      (hasVehicles.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_unref(VBtn), {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (isActive.value = !isActive.value))
            }, {
              default: _withCtx(() => [
                _createVNode(SvgIcon, { icon: "carbon/car-front" })
              ]),
              _: 1
            }),
            (isActive.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (vehicle) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: vehicle.value
                      }, [
                        _createElementVNode("input", {
                          type: "radio",
                          name: vehicle.value,
                          id: vehicle.value,
                          value: vehicle.value,
                          onInput: _cache[2] || (_cache[2] = (e) => setVehicle(e.target?.value))
                        }, null, 40, _hoisted_4),
                        _createElementVNode("label", {
                          for: vehicle.value
                        }, _toDisplayString(vehicle.label), 9, _hoisted_5)
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", { onFocusout: checkLengthWidth }, [
        _withDirectives(_createVNode(_unref(VInput), {
          modelValue: _unref(v$).length.$model,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).length.$model) = $event)),
          hasError: _unref(v$).length.$error,
          label: _unref(t)('ticketSelect.vehicleForm.length'),
          "data-maska": "DE.DE",
          "data-maska-tokens": "D:[0-9]|E:[0-9]:optional"
        }, null, 8, ["modelValue", "hasError", "label"]), [
          [_unref(vMaska)]
        ])
      ], 32),
      _createElementVNode("div", { onFocusout: checkLengthWidth }, [
        _withDirectives(_createVNode(_unref(VInput), {
          modelValue: _unref(v$).width.$model,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(v$).width.$model) = $event)),
          hasError: _unref(v$).width.$error,
          label: _unref(t)('ticketSelect.vehicleForm.width'),
          "data-maska": "DE.DE",
          "data-maska-tokens": "D:[0-9]|E:[0-9]:optional"
        }, null, 8, ["modelValue", "hasError", "label"]), [
          [_unref(vMaska)]
        ])
      ], 32)
    ]),
    (_ctx.isTrailer)
      ? (_openBlock(), _createBlock(_unref(VCheckbox), {
          key: 0,
          modelValue: noLicense.value,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((noLicense).value = $event)),
          label: "Släp har inget registeringsnr",
          id: "trailerLicense"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.isTrailer !== true)
      ? (_openBlock(), _createBlock(_unref(VCheckbox), {
          key: 1,
          modelValue: noLicense.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((noLicense).value = $event)),
          label: "Hyrbil eller jag vet ej registeringsnr",
          id: "vehicleLicense"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("ticketSelect.vehicle.info")), 1),
    (_unref(v$).$errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).$errors.map((e) => e.$message))], (error) => {
            return (_openBlock(), _createElementBlock("p", {
              key: error.toString()
            }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})