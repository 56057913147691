import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "my-bookings" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header-title" }
const _hoisted_4 = { class: "bookings" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  class: "bookings"
}
const _hoisted_8 = { key: 2 }

import BaseLoader from "@/components/base/BaseLoader.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useBookingsQuery from "@/composables/profile/useBookingsQuery";
import { useBookingStore } from "@/store/booking";
import { VBtn } from "v-ferry-components";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BookingItem from "./BookingItem.vue";
import { ref } from "vue";
import useUnloadBookings from "@/composables/useUnloadBookings";
import { useUserStore } from "@/store/user";
import { Booking } from "@/models/front/Booking";
import { UserType } from "@/models/Enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyBookings',
  setup(__props) {

const { t } = useI18n();

const { data: bookings, isLoading, getPastBookings } = useBookingsQuery();
const bookingStore = useBookingStore();
const userStore = useUserStore();

const isAgent = userStore.getUserType === UserType.Agent;

const { unload } = useUnloadBookings(false);

const showPast = ref(false);

const pastBookings = ref<Booking[]>([]);

const isLoadingPast = ref(false);

const error = ref("");

const isResetting = ref(false);

const newBooking = () => {
    isResetting.value = true;
    unload()
        .then(() => {
            if (isAgent) {
                bookingStore.setIsBusiness(true);
                window.location.href = "/company";
            } else {
                bookingStore.setIsBusiness(false);
                window.location.href = "/";
            }
        })
        .finally(() => {
            isResetting.value = false;
        });
};

const showPastBookings = () => {
    showPast.value = !showPast.value;
    if (!pastBookings.value.length) {
        isLoadingPast.value = true;

        getPastBookings()
            .then((resp) => {
                pastBookings.value = resp;
            })
            .catch((err) => {
                error.value = err.message;
            })
            .finally(() => {
                isLoadingPast.value = false;
            });
    }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("header", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(SvgIcon, { icon: "carbon/user" }),
          _createElementVNode("h3", null, _toDisplayString(_unref(t)("myPages.myBooking.currentBookings")), 1)
        ]),
        _createVNode(_unref(VBtn), {
          isLoading: isResetting.value,
          onClick: newBooking
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)("myPages.myBooking.newBooking")), 1)
          ]),
          _: 1
        }, 8, ["isLoading"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_unref(isLoading))
          ? (_openBlock(), _createBlock(BaseLoader, { key: 0 }))
          : (!_unref(bookings)?.length)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_unref(t)("myPages.myBooking.noBooking.current")), 1))
            : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bookings), (booking) => {
          return (_openBlock(), _createBlock(BookingItem, {
            booking: booking,
            key: booking.bookingNumber
          }, null, 8, ["booking"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("header", null, [
        _createVNode(SvgIcon, { icon: "carbon/user" }),
        _createElementVNode("h3", null, _toDisplayString(_unref(t)("myPages.myBooking.pastBookings")), 1)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_unref(VBtn), {
          onClick: showPastBookings,
          class: "header-button",
          type: "button",
          color: "secondary"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(showPast.value ? _ctx.$t("myPages.myBookings.showPastBookings.hide") : _ctx.$t("myPages.myBookings.showPastBookings.show")), 1)
          ]),
          _: 1
        })
      ]),
      (showPast.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (isLoadingPast.value)
              ? (_openBlock(), _createBlock(BaseLoader, { key: 0 }))
              : (pastBookings.value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pastBookings.value, (booking) => {
                      return (_openBlock(), _createBlock(BookingItem, {
                        booking: booking,
                        key: booking.bookingNumber,
                        pastBooking: "",
                        isExpansion: ""
                      }, null, 8, ["booking"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_unref(t)("myPages.myBooking.noBooking.past")), 1))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})