<template>
    <div class="my-profile">
        <div class="my-profile__section">
            <header>
                <SvgIcon icon="carbon/user" />
                <h3>{{ $t("myPages.myProfile.customerDetails.title") }}</h3>
            </header>

            <MyDetails />
        </div>
        <div class="my-profile__section">
            <header>
                <SvgIcon icon="car" />
                <h3>{{ $t("myPages.myProfile.vehicles.title") }}</h3>
            </header>
            <form class="vehicles-form">
                <div v-if="isLoadingVehicles || isLoadingVehicleInfo" class="loader">
                    <BaseLoader />
                </div>
                <div v-if="data?.length">
                    <MyVehicle
                        v-for="vehicle in data"
                        :key="`${vehicle.vehicleId}-${vehicle.isDefault}`"
                        :vehicle="vehicle"
                        class="vehicles"
                        disabled
                        @onUpdateVehicle="(vehicle) => updateVehicle(vehicle)"
                        :isEditing="editedVehicle?.vehicleId === vehicle.vehicleId"
                        @onEdit="onEdit(vehicle)"
                        @onRemoveVehicle="removeVehicle"
                    />
                </div>
                <span class="no-vehicle" v-if="!isLoadingVehicles && (data?.length ?? 0) === 0">
                    {{ $t("myPages.myProfile.vehicles.noVehicles") }}
                </span>
                <div v-if="editedVehicle !== undefined && editedVehicle.vehicleId == 0">
                	
                	<div class="hr" />
                	
                    <MyVehicle
                        :key="editedVehicle.vehicleId"
                        :vehicle="editedVehicle"
                        @onRemoveVehicle="editedVehicle = undefined"
                        @onUpdateVehicle="(vehicle) => updateVehicle(vehicle)"
                        :isEditing="true"
                        @onEdit=""
                        class="vehicles"
                    />
                </div>
                <div v-if="error" class="error-messages">
                    <p>
                        {{ $t("errorMessages.error") }}
                    </p>
                </div>
                <div class="button-group">
                    <VBtn @click="addVehicle" type="button" class="add" color="ghost" :disabled="isLoadingVehicles || editedVehicle?.vehicleId == 0">
                        <SvgIcon icon="add" />
                        <span>
                            {{ $t("myPages.myVehicle.addVehicle") }}
                        </span>
                    </VBtn>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseLoader from "@/components/base/BaseLoader.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useVehiclesQuery from "@/composables/profile/useVehiclesQuery";
import { MyCoVehicle } from "@/models/front/MyCoVehicle";
import useVuelidate from "@vuelidate/core";
import { VBtn } from "v-ferry-components";
import { ref, watch } from "vue";
import MyDetails from "./MyDetails.vue";
import MyVehicle from "./MyVehicle.vue";
import useDialog from "@/composables/useDialog";
import { DialogType } from "@/models/Symbols/Dialog";
import { useI18n } from "vue-i18n";
import useVehicleInfo from "@/composables/vehicle-info/useVehicleInfo";

const { t } = useI18n();
const { displayDialog } = useDialog();
const { data, addNewVehicleMutation, isLoading: isLoadingVehicles, removeVehicleMutation, updateVehicleMutation } = useVehiclesQuery();
const { isLoading : isLoadingVehicleInfo } = useVehicleInfo();

watch (isLoadingVehicleInfo, () => {
console.log('erup')
})

const editedVehicle = ref<MyCoVehicle | undefined>(undefined);

const v$ = useVuelidate();

const error = ref(false);

const onEdit = (vehicle: MyCoVehicle | undefined) => 
{ 
	editedVehicle.value = vehicle; 
};

const removeVehicle = (id: number) => {
    const vehicle = data.value?.find((v) => v.vehicleId === id);
    if (!vehicle) return;
    
    displayDialog({
        title: t("messages.removeVehicle.title"),
        message: t("messages.removeVehicle.body"),
        yes: t("button.remove"),
        no: t("button.no"),
        destructive: true,
        callback: () => {
        	removeVehicleMutation.mutateAsync({ 'vehicleId' : vehicle.vehicleId });
        },
        type: DialogType.danger,
    });
};

const updateVehicle = async (vehicle: MyCoVehicle) => {
	
	error.value = false;

    const isValid = await v$.value.$validate();
    if (!isValid) return;

	if (vehicle.vehicleId === 0) {
		await addNewVehicleMutation
		      .mutateAsync(new MyCoVehicle({
            				licenseNumber: vehicle.licenseNumber,
            				width: vehicle.width,
            				length: vehicle.length,
            				isDefault: vehicle.isDefault,
        	}))
        	.catch((err) => {
                console.log(err);
                error.value = true;
            })
            .finally(() => editedVehicle.value = undefined)
	}
	else {
    	await updateVehicleMutation
    		.mutateAsync(new MyCoVehicle({
            				licenseNumber: vehicle.licenseNumber,
            				width: +vehicle.width,
            				length: +vehicle.length,
            				vehicleId: vehicle.vehicleId,
            				isDefault: vehicle.isDefault
            			}))
        	.catch((err) => {
                console.log(err);
                error.value = true;
                editedVehicle.value = undefined;
            })
            .finally(() => {
            	editedVehicle.value = undefined;
            });
    }
}

const addVehicle = () => {
	
    editedVehicle.value = new MyCoVehicle({ vehicleId: 0 });
};
</script>

<style lang="scss" scoped>
.my-profile {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__section {
        display: flex;
        flex-direction: column;
        max-width: 75ch;

        + .my-profile__section {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 20px;
        }

        header {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-bottom: 30px;
        }

        .vehicles-form {
            display: flex;
            flex-direction: column;
            gap: 7px;
            position: relative;

            @include media-breakpoint-down(sm) {
                gap: 20px;
            }
            .no-vehicle {
                margin-bottom: 20px;
            }
			
			.hr {
				margin-bottom: 12px;
				border-bottom: var(--v-tabs-border-bottom, 1px solid #ebebee)
			}
			
            .loader {
                position: absolute;
                width: 100%;
                height: 111%;
                right: -16px;
                top: -10px;
                z-index: 1;
            }
        }

        .button-group {
            display: flex;
            justify-content: space-between;

            .add {
                padding: 0;

                :deep(.v-btn__content) {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .icon {
                        border-radius: 50px;
                        background-color: var(--c-primary);
                        color: var(--c-base);
                    }
                }
            }
        }
    }
}
</style>
