import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-page" }
const _hoisted_2 = { class: "login-form" }
const _hoisted_3 = { class: "prose" }
const _hoisted_4 = { class: "h4" }
const _hoisted_5 = { class: "prose" }
const _hoisted_6 = { class: "h4" }
const _hoisted_7 = { class: "prose" }
const _hoisted_8 = { class: "error-messages" }

import LoginForm from "@/components/login/LoginForm.vue";
import { VBtn, VCard } from "v-ferry-components";
import { ref } from "vue";
import { useRouter } from "vue-router";
import CustomerDetailsForm, { CustomerDetailsFormState } from "../BookingFlow/components/customerDetails/CustomerDetailsForm.vue";
import useCreateAccount from "@/composables/auth/useCreateAccount";
import { useBookingStore } from "@/store/booking";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const router = useRouter();

const createNewAccount = ref(false);
const bookingStore = useBookingStore();

const { createCustomer, isLoading } = useCreateAccount();

const error = ref("");

const submit = (state: CustomerDetailsFormState) => {
    createCustomer(state)
        .then(() => goToBooking())
        .catch((err) => {
            error.value = err.message;
        });
};

const goToBooking = (state) => {
	
	bookingStore.setRoute(state?.defaultRoute);
	
    router.push(state?.type === 'Agent' ? "/company" : "/");
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "login-page__background" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      (!createNewAccount.value)
        ? (_openBlock(), _createBlock(_unref(VCard), { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("loginPage.title.customer")), 1),
                _cache[4] || (_cache[4] = _createElementVNode("p", null, " Logga in för att se, ändra och avboka dina bokningar. Du kan även se aktuella saldon och fylla på dina resekort, samt ändra uppgifter för ditt konto. Vi önskar dig en trevlig resa! ", -1)),
                _createElementVNode("p", null, [
                  _cache[3] || (_cache[3] = _createTextVNode("Har du inget konto? ")),
                  _createElementVNode("a", {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (createNewAccount.value = true), ["prevent"])),
                    href: "#"
                  }, "Klicka här för att skapa ett")
                ])
              ]),
              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
              _createVNode(LoginForm, {
                hideCreateAccount: "",
                onOnLogin: goToBooking,
                isAgent: false
              }),
              _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
              _createElementVNode("span", null, [
                _cache[6] || (_cache[6] = _createTextVNode("Är du företagskund? Klicka ")),
                _createVNode(_component_router_link, { to: "/agent-login" }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("här")
                  ])),
                  _: 1
                }),
                _cache[7] || (_cache[7] = _createTextVNode(" för att logga in"))
              ])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_unref(VCard), { key: 1 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t("createAccount.title")), 1)
              ]),
              _createVNode(CustomerDetailsForm, {
                onSubmit: submit,
                forceCreateAccount: "",
                onAbort: _cache[2] || (_cache[2] = ($event: any) => (createNewAccount.value = false))
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", null, [
                      _createTextVNode(_toDisplayString(_ctx.$t("customerDetails.haveAccount")) + " ", 1),
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (createNewAccount.value = false))
                      }, _toDisplayString(_ctx.$t("customerDetails.login")), 1)
                    ])
                  ])
                ]),
                footer: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("p", null, _toDisplayString(error.value), 1)
                  ]),
                  _createVNode(_unref(VBtn), { isLoading: _unref(isLoading) }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("createAccount.btn")), 1)
                    ]),
                    _: 1
                  }, 8, ["isLoading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ])
  ]))
}
}

})