<template>
    <div v-if="isLoggedIn" class="my-pages">
        <h2>{{ $t("myPages.title") }}</h2>
        <VTabs btnColor="ghost" class="tabs">
            <VTab class="tabs__tab" :title="$t('myPages.myProfile.label')">
                <MyProfile />
            </VTab>
            <VTab class="tabs__tab" :title="$t('myPages.myCards.label')" v-if="userStore.getUserType !== UserType.Agent">
                <MyCards />
            </VTab>
            <VTab class="tabs__tab" :title="$t('myPages.myBooking.label')">
                <MyBookings />
            </VTab>
        </VTabs>
    </div>
    <LoginPage v-else />
</template>

<script lang="ts" setup>
import useUnloadBookings from "@/composables/useUnloadBookings";
import { UserType } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { VTab, VTabs } from "v-ferry-components";
import { computed } from "vue";
import LoginPage from "../Login/LoginPage.vue";
import MyBookings from "./MyBookings/MyBookings.vue";
import MyCards from "./MyCards/MyCards.vue";
import MyProfile from "./MyProfile/MyProfile.vue";

const userStore = useUserStore();

const isLoggedIn = computed(() => userStore.isLoggedIn);

const bookingStore = useBookingStore();

const { unload } = useUnloadBookings();

if (bookingStore.isAmendment) {
    unload();
}
</script>

<style lang="scss" scoped>
.my-pages {
    width: 100%;
    max-width: 1125px;
    margin: 0 auto;
    background-color: var(--c-base);
    padding: 30px;

    h2 {
        margin-bottom: 20px;
    }

    h5 {
        text-align: center;
        font-weight: normal;
    }

    .tabs {
        &__tab {
            margin-top: 20px;
        }
    }
}
</style>
