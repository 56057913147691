import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "departure-section__header" }
const _hoisted_2 = { class: "departure-table" }
const _hoisted_3 = { class: "departure-table__header" }
const _hoisted_4 = { class: "departure-table__content" }
const _hoisted_5 = {
  key: 0,
  class: "loading-overlay"
}
const _hoisted_6 = {
  key: 1,
  class: "departure-table__empty"
}
const _hoisted_7 = {
  key: 0,
  class: "error-messages"
}

import BaseLoader from "@/components/base/BaseLoader.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { dateAndTime } from "@/helpers/dateHelpers";
import { Direction } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useDepartureStore } from "@/store/departure";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import useDepartureQuery from "../../composables/useDepartureQuery";
import Departure from "./Departure.vue";
import DepartureDates from "./DepartureDates.vue";

interface Props {
    direction: Direction;
    disabled: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DepartureSection',
  props: {
    direction: {},
    disabled: { type: Boolean }
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const bookingStore = useBookingStore();
const departureStore = useDepartureStore();

const route = bookingStore.getRoute(props.direction);

const { data, isFetching } = useDepartureQuery(props.direction);
const selectedDeparture = computed({
    get() {
        return departureStore.getDeparture(props.direction).id;
    },
    set(departureId: string) {
        const departure = data.value?.find((departure) => departure.id === departureId);

        if (departure) {
            departureStore.setDeparture(props.direction, {
                id: departure.id,
                identifier: departure.identifier,
                date: dateAndTime(departure.departureDate),
            });
        }
        if (props.direction === Direction.Outward) {
            if (departureStore.getDeparture(Direction.Return).date < departureStore.getDeparture(Direction.Outward).date) {
                departureStore.setDeparture(Direction.Return, {
                    id: "",
                    identifier: "",
                    date: "",
                });
            }
        }
    },
});

const state = reactive({
    selectedDeparture: selectedDeparture.value,
});

const isDepartureSelected = () => {
    return data.value?.some((d) => d.id === selectedDeparture.value) ?? false;
};

const rules = {
    selectedDeparture: {
        v: helpers.withMessage(t("departures.errors.selectedDeparture"), () => isDepartureSelected()),
    },
};

const v$ = useVuelidate(rules, state);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(["departure-section", { disabled: _ctx.disabled }])
  }, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t(`directions.${_ctx.direction}`)), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t(`routes.${_unref(route)}`)), 1)
    ]),
    _createVNode(DepartureDates, { direction: _ctx.direction }, null, 8, ["direction"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t(`departures.labels.departure`)), 1)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t(`departures.labels.arrival`)), 1)
        ])
      ]),
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data), (departure) => {
          return (_openBlock(), _createBlock(Departure, {
            key: departure.identifier,
            departure: departure,
            modelValue: selectedDeparture.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedDeparture).value = $event)),
            direction: _ctx.direction
          }, null, 8, ["departure", "modelValue", "direction"]))
        }), 128))
      ]),
      (_unref(isFetching))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(BaseLoader)
          ]))
        : (!_unref(data)?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(SvgIcon, {
                icon: "carbon/info",
                height: "1.5rem"
              }),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t(`departures.noDepartures`)), 1)
            ]))
          : _createCommentVNode("", true)
    ]),
    (_unref(v$).selectedDeparture.$error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...new Set(_unref(v$).selectedDeparture.$errors.map((e) => e.$message))], (error) => {
            return (_openBlock(), _createElementBlock("p", {
              key: error.toString()
            }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})