<template>
    <div class="payment">
        <div class="subtitle">
            <b v-if="isBusiness" class="agent-title">{{ $t("agent.title") }}</b>
            <b v-if="isAmend" class="agent-title">{{ $t("bookingFlow.isAmend") }} {{ bookingStore.bookingNumber }}</b>
        </div>
        <h1 v-if="nothingToPay === false" class="h2">{{ t("paymentDetails.title.default") }}</h1>
        <h1 v-else class="h2">{{ t("paymentDetails.title.confirm") }}</h1>
        <div v-if="isLoading" class="loading-overlay">
            <BaseLoader />
        </div>
        <div v-else class="payment-details">
            <div v-if="paymentAlternatives?.usedCards.length" class="payment-details__options">
                <h5>{{ $t("paymentDetails.usedCards") }}</h5>
                <div v-for="(card, index) in paymentAlternatives?.usedCards" :key="index" class="payment-option">
                    <VCheckbox
                        :id="card.token"
                        :label="$t(`paymentDetails.paymentMethods.${card.description}.${card.travelCardType}`)"
                        v-model="checked"
                        disabled
                    />
                </div>
            </div>

            <div class="payment-details__options" v-if="leftToPay">
                <div v-if="leftToPay >= 0 && !isCardPurchase" class="leftToPay">
                    <p>
                        {{ $t("paymentDetails.leftToPay") }} <b>{{ leftToPay }}kr</b>
                    </p>
                </div>
                <div v-else-if="!isCardPurchase" class="leftToPay">
                    <p>
                        {{ $t("paymentDetails.refund") }} <b>{{ Math.abs(leftToPay) }}kr</b>
                    </p>
                </div>
                
                <PaymentOption
                    v-for="alternative in paymentAlternatives?.alternatives"
                    v-model="v$.paymentMethod.$model"
                    :label="$t(`paymentDetails.paymentMethods.${alternative.description}.label`)"
                    :value="alternative.token"
                    :key="alternative.description"
                    :icon="alternative.icon"
                    :hasError="v$.paymentMethod.$error"
                />
                
 				<VInput
 					v-if="showInvoiceRef"
		            v-model="v$.invoiceRef.$model"
		            :hasError="v$.invoiceRef.$error"
		            :label="$t('fields.invoiceRef.label')"
		        />
		        
                <div v-if="v$.paymentMethod.$error" class="error-messages">
                    <p v-for="error in [...new Set(v$.paymentMethod.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
                </div>
                <div v-if="v$.invoiceRef.$error" class="error-messages">
                    <p v-for="error in [...new Set(v$.invoiceRef.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
                </div>
                <BaseAlert v-if="paymentAborted" type="danger" :message="t('paymentDetails.cancelled')" />
                <BaseAlert v-if="paymentFailed" type="danger" :message="t('paymentDetails.failed')" />
            </div>
            <div v-else>
                {{ $t("paymentDetails.nothingToPay") }}
            </div>
            <div class="payment-details__terms">
                <VCheckbox
                    id="terms"
                    class="terms"
                    :label="$t('paymentDetails.terms.text')"
                    v-model="v$.terms.$model"
                    :hasError="v$.terms.$error"
                />

                <VBtn @click="showModal = true" color="ghost">{{ $t("paymentDetails.terms.link") }}</VBtn>

                <VModal v-if="showModal" @close="showModal = false" :title="$t('paymentDetails.terms.modal.title')" isClosable>
                    <TravelTerms />
                </VModal>
            </div>
            <BaseAlert v-if="errorMessage?.length > 0" type="danger" :message="errorMessage" />
            <div class="payment-details__footer">
                <div>
                    <span>
                        {{ t("paymentDetails.total") }}
                    </span>
                    <b> {{ `${leftToPay < 0 ? "-" : ""}${Math.abs(leftToPay)}` }}kr </b>
                </div>
                <VBtn v-if="isCardPurchase === true" @click="payCard" :isLoading="isInitializingPayment">{{
                    leftToPay > 0 ? t("paymentDetails.pay") : t("paymentDetails.confirm")
                }}</VBtn>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseAlert from "@/components/base/BaseAlert.vue";
import BaseLoader from "@/components/base/BaseLoader.vue";
import usePaymentQuery, { StartPaymentRequest } from "@/composables/payment/usePaymentQuery";
import { getQueryParamValue } from "@/helpers/urlHelper";
import { required } from "@/helpers/validators";
import { useBookingStore } from "@/store/booking";
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";
import { VBtn, VCheckbox, VModal, VInput } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import PaymentOption from "./PaymentOption.vue";
import TravelTerms from "./TravelTerms.vue";

interface Props {
    bookingReference?: string;
    isCardPurchase?: boolean;
}

const props = defineProps<Props>();

const bookingStore = useBookingStore();
const isBusiness = computed(() => bookingStore.isBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const { t } = useI18n();

const paymentAborted = getQueryParamValue("abort") === "true";
const paymentFailed = getQueryParamValue("failed") === "true";

const reference = computed(() => props.bookingReference ?? undefined);

const { data: paymentAlternatives, isLoading, startPayment, isInitializingPayment } = usePaymentQuery(reference.value);

const checked = true;

const showModal = ref(false);

const state = reactive({
    paymentMethod: "",
    terms: false,
    invoiceRef: "",
});

const leftToPay = computed(() => paymentAlternatives?.value?.leftToPay ?? 0);

const rules = {
    paymentMethod: {
        required: helpers.withMessage(
            t("paymentDetails.requiredMethod"),
            requiredIf(function () {
                return leftToPay.value > 0;
            }),
        ),
    },
    terms: { required, val: (val: boolean) => !!val },
    invoiceRef: {
    	required: helpers.withMessage(
            () => t("fields.invoiceRef.missing"),
            (val: string) => (showInvoiceRef.value === false || (showInvoiceRef.value === true && val.length > 0))
        ),
    }
};

const v$ = useVuelidate(rules, state);

const errorMessage = ref("");

const payCard = async () => {
		
    const v$ = useVuelidate(rules, state);

    const isValid = await v$.value.$validate();

    if (!isValid) {
        return;
    }
    
	validateStepAsync();
};

const validateStepAsync = () => {
	
    errorMessage.value = "";
    const paymentTokens: StartPaymentRequest[] = [{ token: state.paymentMethod, reference: state.invoiceRef }];

    return startPayment(paymentTokens).catch((err) => {
        errorMessage.value = t(`errorCodes.${err.response.data.ErrorCode}`);
    });
};

const nothingToPay = computed(() => leftToPay?.value === 0);
const showInvoiceRef = computed(() => paymentAlternatives?.value?.alternatives?.some((alt) => alt.type === 'Invoice' && alt.token === state.paymentMethod) ?? false);

defineExpose({
    validateStepAsync,
    nothingToPay,
});
</script>
<style scoped lang="scss">
.payment {
    position: relative;
    min-height: 400px;
}

.subtitle {
    display: flex;
    justify-content: space-between;
}

.payment-details {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__options {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .payment-option {
            .v-checkbox {
                width: 100%;
            }
        }
    }

    &__terms {
        display: flex;
        align-items: center;
        gap: 3px;

        --v-btn-padding: 0px;
        .v-checkbox {
            padding: 0;
            background-color: transparent;
        }
        .v-checkbox--error + .v-btn {
            color: var(--c-danger-darker);
        }

        .v-btn {
            font-weight: bold;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        font-size: 1.125rem;

        .v-btn {
            padding: 10px 60px;
        }
    }
}
</style>
