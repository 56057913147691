import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "modal__body prose"
}
const _hoisted_2 = {
  key: 2,
  class: "modal__body error"
}
const _hoisted_3 = {
  key: 0,
  class: "modal__footer"
}
const _hoisted_4 = {
  key: 0,
  class: "modal__footer-section"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "buttons" }
const _hoisted_7 = { key: 1 }

import BaseLoader from "@/components/base/BaseLoader.vue";
import useCancelBooking from "@/composables/profile/useCancelBooking";
import useSnackbar from "@/composables/useSnackbar";
import { PrepareCancelResponse } from "@/models/api/BookingModelResponse";
import { Booking } from "@/models/front/Booking";
import { VBtn, VModal } from "v-ferry-components";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
    booking: Booking;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PrepareCancelModal',
  props: {
    booking: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { prepareCancel, confirmCancel, isLoading } = useCancelBooking();

const { displaySnack } = useSnackbar();

const { t } = useI18n();

const prepareInfo = ref<PrepareCancelResponse>();

const error = ref("");

prepareCancel(props.booking.bookingNumber)
    .then((data) => {
        prepareInfo.value = data;
    })
    .catch((err) => {
        error.value = t(`errorCodes.${err.response.data.errorCode}`);
    });

const confirmCancelBooking = () => {
    confirmCancel
        .mutateAsync(props.booking.bookingNumber)
        .then(() => {
            emit("close");
            displaySnack({
                message: t("myPages.myBooking.item.prepareCancel.success"),
                type: "success",
                id: `${props.booking.bookingNumber}`,
            });
        })
        .catch(() => {
            displaySnack({
                message: t("myPages.myBooking.item.prepareCancel.error"),
                type: "danger",
                id: `${props.booking.bookingNumber}`,
            });
        });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VModal), {
    onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('close'))),
    title: _ctx.$t('myPages.myBooking.item.prepareCancel.title')
  }, {
    default: _withCtx(() => [
      (_unref(isLoading))
        ? (_openBlock(), _createBlock(BaseLoader, {
            key: 0,
            alignCenter: ""
          }))
        : (prepareInfo.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("myPages.myBooking.item.bookingno")) + ": " + _toDisplayString(_ctx.booking.bookingNumber), 1)
            ]))
          : (error.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(error.value), 1)
              ]))
            : _createCommentVNode("", true)
    ]),
    footer: _withCtx(() => [
      (prepareInfo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (prepareInfo.value.canCancel)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(prepareInfo.value.messages, (message) => {
                      return (_openBlock(), _createElementBlock("li", { key: message }, [
                        _createElementVNode("b", null, _toDisplayString(message), 1)
                      ]))
                    }), 128))
                  ]),
                  (prepareInfo.value.refundAmount > 0)
                    ? (_openBlock(), _createElementBlock("b", _hoisted_5, _toDisplayString(_ctx.$t("myPages.myBooking.item.prepareCancel.refund")) + " " + _toDisplayString(prepareInfo.value.refundAmount) + "kr", 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_unref(VBtn), {
                      onClick: confirmCancelBooking,
                      class: "cancel",
                      isLoading: _unref(confirmCancel).isLoading.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("myPages.myBooking.item.prepareCancel.confirmCancel")), 1)
                      ]),
                      _: 1
                    }, 8, ["isLoading"]),
                    _createVNode(_unref(VBtn), {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("myPages.myBooking.item.prepareCancel.abort")), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(prepareInfo.value.messages, (message) => {
                      return (_openBlock(), _createElementBlock("li", { key: message }, [
                        _createElementVNode("b", null, _toDisplayString(message), 1)
                      ]))
                    }), 128))
                  ])
                ]))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})