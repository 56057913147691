<template>
    <div class="booking-flow">
        <div class="booking-step container" :class="{ hasBasket: showBasket }" :key="activeComponent.__name">
            <div>
                <VCard>
                    <ul class="card-tabs">
                        <li v-if="!isAgent">
                            <RouterLink to="/">{{ $t("menuLinks.customerBooking") }}</RouterLink>
                        </li>
                        <li>
                            <RouterLink to="/company">{{ $t("menuLinks.agentBooking") }}</RouterLink>
                        </li>
                        <li v-if="allowOpenTickets">
                            <RouterLink to="/open-ticket">{{ $t("menuLinks.openTicket") }}</RouterLink>
                        </li>
                    </ul>
                    <component ref="stepComponentRef" :is="activeComponent" />
                    <div class="unload-container">
                        <VBtn color="ghost" @click="unloadBooking">Avbryt bokning</VBtn>
                    </div>
                </VCard>
                <BookingStepper
                    @continue="onNextStep"
                    :nextStepLoading="isLoading"
                    :showBasket="showBasket"
                    :nothingToPay="stepComponentRef?.nothingToPay"
                    :basketSections="data?.sections.length"
                    :isLoading="isLoading"
                    :isOpenTicket="isOpenTicket"
                >
                    <BookingBasket
                        :sections="data?.sections"
                        :total="data?.totalPrice"
                        :paid="data?.paid"
                        :amd="data?.amd"
                        :showSummary="showSummary"
                        :isLoading="loadingBasket"
                        :isFetching="isFetching"
                        isMobile
                    />
                </BookingStepper>
            </div>
            <BookingBasket
                v-if="showBasket"
                :sections="data?.sections"
                :total="data?.totalPrice"
                :paid="data?.paid"
                :amd="data?.amd"
                :showSummary="showSummary"
                :isLoading="loadingBasket"
                :isFetching="isFetching"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBookingStore } from "@/store/booking";
import useVuelidate from "@vuelidate/core";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import useBookingNavigation from "../composables/useBookingNavigation";
import BookingBasket from "./BookingBasket.vue";
import BookingStepper from "./BookingStepper.vue";
import CustomerDetails from "./customerDetails/CustomerDetails.vue";
import Departures from "./departures/Departures.vue";
import PaymentDetails from "./paymentDetails/PaymentDetails.vue";
import TicketsSelect from "./ticketSelection/TicketsSelect.vue";
import TripDetails from "./tripDetails/TripDetails.vue";
import useCalculateQuery from "@/composables/useCalculateQuery";
import useUnloadBookings from "@/composables/useUnloadBookings";
import { VBtn, VCard } from "v-ferry-components";
import OpenTicket from "./OpenTicket/OpenTicket.vue";
import useRuntimeConfig from "@/composables/useRuntimeConfig";
import { useUserStore } from "@/store/user";

interface Props {
    isBusinessBooking?: boolean;
    isOpenTicket?: boolean;
}

const props = defineProps<Props>();

const banner = ref("");
const allowOpenTickets = ref(false);

const { getRuntimeConfig } = useRuntimeConfig();
const isAgent = computed(() => useUserStore().getUserType === 2);

const isApp = computed(() => navigator.userAgent.includes("OrnoApp v1.0"));
const isBookingView = computed(() => route.path === "/" || "/company" || "/open-ticket");

getRuntimeConfig().then((resp) => {
    banner.value = resp.env;
    allowOpenTickets.value = isApp !== true && isBookingView;
});

const bookingStore = useBookingStore();

const route = useRoute();
bookingStore.setIsBusiness(props.isBusinessBooking);

const showBasket = computed(() => {
    if (props.isOpenTicket) {
        return true;
    } else if (route.query.s) {
        return +route.query.s > 2;
    } else {
        return false;
    }
});

const { data, isLoading: loadingBasket, isFetching } = useCalculateQuery(undefined, props.isOpenTicket);

const showSummary = computed(() => {
    return bookingStore.isAmendment === false || (route.query.s && +route.query.s > 4);
});

const activeComponent = computed(() => {
    if (props.isOpenTicket) {
        switch (route.query.s) {
            case "2":
                return PaymentDetails;
            default:
                return OpenTicket;
        }
    } else {
        switch (route.query.s) {
            case "2":
                return TicketsSelect;
            case "3":
                return Departures;
            case "4":
                return CustomerDetails;
            case "5":
                return PaymentDetails;
            default:
                return TripDetails;
        }
    }
});

const { unload } = useUnloadBookings();

if (!route.query.s && bookingStore.isAmendment) {
    await unload();
}

const unloadBooking = () => {
    unload().then(() => {
        switch (props.isOpenTicket ?? false) {
            case false:
                window.location.href = "/?s=1";
                break;
            default:
                window.location.href = "/open-ticket";
                break;
        }
    });
};

const stepComponentRef = ref();

const v$ = useVuelidate();

const { goToStep } = useBookingNavigation();

const isLoading = ref(false);

const onNextStep = async (step: number) => {
    const valid = await v$.value.$validate();

    if (valid) {
        if (stepComponentRef.value?.persistState) {
            stepComponentRef.value.persistState();
            goToStep(step);
        }

        if (stepComponentRef.value?.validateStepAsync) {
            isLoading.value = true;
            stepComponentRef.value
                ?.validateStepAsync()
                .then(() => {
                    goToStep(step);
                })
                .catch(() => {
                    return;
                })
                .finally(() => {
                    isLoading.value = false;
                });
        } else {
            goToStep(step);
        }
    }
};
</script>

<style lang="scss" scoped>
.booking-flow {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: -50px;
    padding-bottom: 100px;

    @include media-breakpoint-down(md) {
        margin-top: -150px;
    }

    .card-tabs {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.v-card {
    position: relative;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include media-breakpoint-up(lg) {
        border-top-left-radius: 0;
    }
}

.booking-step {
    display: flex;
    justify-content: center;
    gap: 30px;
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;

    > *:first-child {
        width: 100%;
    }
    @include media-breakpoint-up(md) {
        > *:first-child {
            width: 80%;
        }
    }
}

.hasBasket {
    @include media-breakpoint-up(md) {
        > *:first-child {
            flex: auto;
            height: 100%;
        }

        > *:last-child {
            flex: 0 1 calc((100% - 30px) / 4);
            min-width: calc((100% - 30px) / 4);
        }
    }
}

.unload-container {
    margin-top: 30px;
    --v-btn-padding: 0px;
    display: flex;
    justify-content: flex-end;
}
</style>
