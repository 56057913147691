import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "login" }
const _hoisted_2 = {
  key: 0,
  class: "error-messages"
}
const _hoisted_3 = { class: "buttons" }

import useLogin from "@/composables/auth/useLogin";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { VBtn, VInput, VModal } from "v-ferry-components";
import { reactive, ref } from "vue";
import ForgotPassword from "./ForgotPassword.vue";
import { useQueryClient } from "vue-query";

interface Props {
    hideCreateAccount?: boolean;
    isAgent?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginForm',
  props: {
    hideCreateAccount: { type: Boolean },
    isAgent: { type: Boolean }
  },
  emits: ["createAccount", "onLogin"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const showForgotPassword = ref(false);
const queryClient = useQueryClient();

const { customerLogin, isLoading } = useLogin();

const state = reactive({
    email: "",
    password: "",
});

const rules = {
    email: { required },
    password: { required },
};

const v$ = useVuelidate(rules, state);

const errorMessage = ref("");

const submitForm = async () => {
    errorMessage.value = "";
    const isValid = await v$.value.$validate();
    if (!isValid) return;

    customerLogin(state.email, state.password, (props.isAgent ?? false) ? 'agent' : 'customer')
        .then((resp) => {
            emit("onLogin", { defaultRoute: resp?.defaultRoute, type: resp?.type });
        })
        .catch((error) => {
            errorMessage.value = error.message;
        })
		.finally(() => {
        	queryClient.invalidateQueries("calc");
        });;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showForgotPassword.value)
      ? (_openBlock(), _createBlock(_unref(VModal), {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (showForgotPassword.value = false)),
          isClosable: ""
        }, {
          default: _withCtx(() => [
            _createVNode(ForgotPassword, {
              onClose: _cache[0] || (_cache[0] = ($event: any) => (showForgotPassword.value = false)),
              email: _unref(v$).email.$model
            }, null, 8, ["email"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      onSubmit: _withModifiers(submitForm, ["prevent"])
    }, [
      (errorMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(errorMessage.value), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isAgent === false)
        ? (_openBlock(), _createBlock(_unref(VInput), {
            key: 1,
            modelValue: _unref(v$).email.$model,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).email.$model) = $event)),
            label: _ctx.$t('fields.emailOrCustomerNo.label')
          }, null, 8, ["modelValue", "label"]))
        : (_openBlock(), _createBlock(_unref(VInput), {
            key: 2,
            modelValue: _unref(v$).email.$model,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).email.$model) = $event)),
            label: _ctx.$t('fields.customerNo.label')
          }, null, 8, ["modelValue", "label"])),
      _createVNode(_unref(VInput), {
        modelValue: _unref(v$).password.$model,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(v$).password.$model) = $event)),
        label: _ctx.$t('fields.password.label'),
        type: "password"
      }, null, 8, ["modelValue", "label"]),
      _createElementVNode("div", null, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (showForgotPassword.value = true), ["prevent"])),
          class: "link"
        }, _toDisplayString(_ctx.$t("customerDetails.forgotPassword")), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.hideCreateAccount)
          ? (_openBlock(), _createBlock(_unref(VBtn), {
              key: 0,
              type: "button",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (emit('createAccount'))),
              color: "secondary",
              disabled: _ctx.isAgent
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("customerDetails.buttons.create")), 1)
              ]),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true),
        _createVNode(_unref(VBtn), { isLoading: _unref(isLoading) }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("customerDetails.buttons.login")), 1)
          ]),
          _: 1
        }, 8, ["isLoading"])
      ])
    ], 32)
  ]))
}
}

})