import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flow" }
const _hoisted_2 = { class: "loader" }

import BaseLoader from "@/components/base/BaseLoader.vue";
import BookingFlow from "./BookingFlow.vue";
import SiteHero from "@/components/layout/SiteHero.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'OpenTicketFlow',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SiteHero),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_Suspense, null, {
          default: _withCtx(() => [
            _createVNode(BookingFlow, { isOpenTicket: "" })
          ]),
          fallback: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(BaseLoader)
            ])
          ]),
          _: 1
        }))
      ]),
      _: 1
    })
  ]))
}
}

})