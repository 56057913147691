<template>
    <div class="my-bookings">
        <div>
            <header class="header">
                <div class="header-title">
                    <SvgIcon icon="carbon/user" />
                    <h3>{{ t("myPages.myBooking.currentBookings") }}</h3>
                </div>
                <VBtn :isLoading="isResetting" @click="newBooking">{{ t("myPages.myBooking.newBooking") }}</VBtn>
            </header>
            <div class="bookings">
                <BaseLoader v-if="isLoading" />
                <p v-else-if="!bookings?.length">{{ t("myPages.myBooking.noBooking.current") }}</p>
                <BookingItem v-for="booking in bookings" :booking="booking" :key="booking.bookingNumber" />
            </div>
        </div>
        <div>
            <header>
                <SvgIcon icon="carbon/user" />
                <h3>{{ t("myPages.myBooking.pastBookings") }}</h3>
            </header>
            <div>
                <VBtn @click="showPastBookings" class="header-button" type="button" color="secondary">
                    {{ showPast ? $t("myPages.myBookings.showPastBookings.hide") : $t("myPages.myBookings.showPastBookings.show") }}
                </VBtn>
            </div>
            <div v-if="showPast">
                <BaseLoader v-if="isLoadingPast" />
                <div v-else-if="pastBookings.length" class="bookings">
                    <BookingItem v-for="booking in pastBookings" :booking="booking" :key="booking.bookingNumber" pastBooking isExpansion />
                </div>
                <p v-else>{{ t("myPages.myBooking.noBooking.past") }}</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseLoader from "@/components/base/BaseLoader.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useBookingsQuery from "@/composables/profile/useBookingsQuery";
import { useBookingStore } from "@/store/booking";
import { VBtn } from "v-ferry-components";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import BookingItem from "./BookingItem.vue";
import { ref } from "vue";
import useUnloadBookings from "@/composables/useUnloadBookings";
import { useUserStore } from "@/store/user";
import { Booking } from "@/models/front/Booking";
import { UserType } from "@/models/Enums";

const { t } = useI18n();

const { data: bookings, isLoading, getPastBookings } = useBookingsQuery();
const bookingStore = useBookingStore();
const userStore = useUserStore();

const isAgent = userStore.getUserType === UserType.Agent;

const { unload } = useUnloadBookings(false);

const showPast = ref(false);

const pastBookings = ref<Booking[]>([]);

const isLoadingPast = ref(false);

const error = ref("");

const isResetting = ref(false);

const newBooking = () => {
    isResetting.value = true;
    unload()
        .then(() => {
            if (isAgent) {
                bookingStore.setIsBusiness(true);
                window.location.href = "/company";
            } else {
                bookingStore.setIsBusiness(false);
                window.location.href = "/";
            }
        })
        .finally(() => {
            isResetting.value = false;
        });
};

const showPastBookings = () => {
    showPast.value = !showPast.value;
    if (!pastBookings.value.length) {
        isLoadingPast.value = true;

        getPastBookings()
            .then((resp) => {
                pastBookings.value = resp;
            })
            .catch((err) => {
                error.value = err.message;
            })
            .finally(() => {
                isLoadingPast.value = false;
            });
    }
};
</script>

<style lang="scss" scoped>
.my-bookings {
    display: flex;
    flex-direction: column;
    gap: 50px;

    > div {
        display: flex;
        flex-direction: column;
        gap: 15px;
        + div {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 20px;
        }
    }

    header {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .header {
        justify-content: space-between;

        a {
            text-decoration: none;
        }

        &-title {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }

    .bookings {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
</style>
