import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-profile" }
const _hoisted_2 = { class: "my-profile__section" }
const _hoisted_3 = { class: "my-profile__section" }
const _hoisted_4 = { class: "vehicles-form" }
const _hoisted_5 = {
  key: 0,
  class: "loader"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 2,
  class: "no-vehicle"
}
const _hoisted_8 = { key: 3 }
const _hoisted_9 = {
  key: 4,
  class: "error-messages"
}
const _hoisted_10 = { class: "button-group" }

import BaseLoader from "@/components/base/BaseLoader.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useVehiclesQuery from "@/composables/profile/useVehiclesQuery";
import { MyCoVehicle } from "@/models/front/MyCoVehicle";
import useVuelidate from "@vuelidate/core";
import { VBtn } from "v-ferry-components";
import { ref, watch } from "vue";
import MyDetails from "./MyDetails.vue";
import MyVehicle from "./MyVehicle.vue";
import useDialog from "@/composables/useDialog";
import { DialogType } from "@/models/Symbols/Dialog";
import { useI18n } from "vue-i18n";
import useVehicleInfo from "@/composables/vehicle-info/useVehicleInfo";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyProfile',
  setup(__props) {

const { t } = useI18n();
const { displayDialog } = useDialog();
const { data, addNewVehicleMutation, isLoading: isLoadingVehicles, removeVehicleMutation, updateVehicleMutation } = useVehiclesQuery();
const { isLoading : isLoadingVehicleInfo } = useVehicleInfo();

watch (isLoadingVehicleInfo, () => {
console.log('erup')
})

const editedVehicle = ref<MyCoVehicle | undefined>(undefined);

const v$ = useVuelidate();

const error = ref(false);

const onEdit = (vehicle: MyCoVehicle | undefined) => 
{ 
	editedVehicle.value = vehicle; 
};

const removeVehicle = (id: number) => {
    const vehicle = data.value?.find((v) => v.vehicleId === id);
    if (!vehicle) return;
    
    displayDialog({
        title: t("messages.removeVehicle.title"),
        message: t("messages.removeVehicle.body"),
        yes: t("button.remove"),
        no: t("button.no"),
        destructive: true,
        callback: () => {
        	removeVehicleMutation.mutateAsync({ 'vehicleId' : vehicle.vehicleId });
        },
        type: DialogType.danger,
    });
};

const updateVehicle = async (vehicle: MyCoVehicle) => {
	
	error.value = false;

    const isValid = await v$.value.$validate();
    if (!isValid) return;

	if (vehicle.vehicleId === 0) {
		await addNewVehicleMutation
		      .mutateAsync(new MyCoVehicle({
            				licenseNumber: vehicle.licenseNumber,
            				width: vehicle.width,
            				length: vehicle.length,
            				isDefault: vehicle.isDefault,
        	}))
        	.catch((err) => {
                console.log(err);
                error.value = true;
            })
            .finally(() => editedVehicle.value = undefined)
	}
	else {
    	await updateVehicleMutation
    		.mutateAsync(new MyCoVehicle({
            				licenseNumber: vehicle.licenseNumber,
            				width: +vehicle.width,
            				length: +vehicle.length,
            				vehicleId: vehicle.vehicleId,
            				isDefault: vehicle.isDefault
            			}))
        	.catch((err) => {
                console.log(err);
                error.value = true;
                editedVehicle.value = undefined;
            })
            .finally(() => {
            	editedVehicle.value = undefined;
            });
    }
}

const addVehicle = () => {
	
    editedVehicle.value = new MyCoVehicle({ vehicleId: 0 });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", null, [
        _createVNode(SvgIcon, { icon: "carbon/user" }),
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("myPages.myProfile.customerDetails.title")), 1)
      ]),
      _createVNode(MyDetails)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("header", null, [
        _createVNode(SvgIcon, { icon: "car" }),
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t("myPages.myProfile.vehicles.title")), 1)
      ]),
      _createElementVNode("form", _hoisted_4, [
        (_unref(isLoadingVehicles) || _unref(isLoadingVehicleInfo))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(BaseLoader)
            ]))
          : _createCommentVNode("", true),
        (_unref(data)?.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data), (vehicle) => {
                return (_openBlock(), _createBlock(MyVehicle, {
                  key: `${vehicle.vehicleId}-${vehicle.isDefault}`,
                  vehicle: vehicle,
                  class: "vehicles",
                  disabled: "",
                  onOnUpdateVehicle: (vehicle) => updateVehicle(vehicle),
                  isEditing: editedVehicle.value?.vehicleId === vehicle.vehicleId,
                  onOnEdit: ($event: any) => (onEdit(vehicle)),
                  onOnRemoveVehicle: removeVehicle
                }, null, 8, ["vehicle", "onOnUpdateVehicle", "isEditing", "onOnEdit"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (!_unref(isLoadingVehicles) && (_unref(data)?.length ?? 0) === 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("myPages.myProfile.vehicles.noVehicles")), 1))
          : _createCommentVNode("", true),
        (editedVehicle.value !== undefined && editedVehicle.value.vehicleId == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "hr" }, null, -1)),
              (_openBlock(), _createBlock(MyVehicle, {
                key: editedVehicle.value.vehicleId,
                vehicle: editedVehicle.value,
                onOnRemoveVehicle: _cache[0] || (_cache[0] = ($event: any) => (editedVehicle.value = undefined)),
                onOnUpdateVehicle: _cache[1] || (_cache[1] = (vehicle) => updateVehicle(vehicle)),
                isEditing: true,
                onOnEdit: _cache[2] || (_cache[2] = () => {}),
                class: "vehicles"
              }, null, 8, ["vehicle"]))
            ]))
          : _createCommentVNode("", true),
        (error.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("errorMessages.error")), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_unref(VBtn), {
            onClick: addVehicle,
            type: "button",
            class: "add",
            color: "ghost",
            disabled: _unref(isLoadingVehicles) || editedVehicle.value?.vehicleId == 0
          }, {
            default: _withCtx(() => [
              _createVNode(SvgIcon, { icon: "add" }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("myPages.myVehicle.addVehicle")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ])
  ]))
}
}

})