import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "my-pages"
}

import useUnloadBookings from "@/composables/useUnloadBookings";
import { UserType } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { VTab, VTabs } from "v-ferry-components";
import { computed } from "vue";
import LoginPage from "../Login/LoginPage.vue";
import MyBookings from "./MyBookings/MyBookings.vue";
import MyCards from "./MyCards/MyCards.vue";
import MyProfile from "./MyProfile/MyProfile.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const userStore = useUserStore();

const isLoggedIn = computed(() => userStore.isLoggedIn);

const bookingStore = useBookingStore();

const { unload } = useUnloadBookings();

if (bookingStore.isAmendment) {
    unload();
}

return (_ctx: any,_cache: any) => {
  return (isLoggedIn.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("myPages.title")), 1),
        _createVNode(_unref(VTabs), {
          btnColor: "ghost",
          class: "tabs"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(VTab), {
              class: "tabs__tab",
              title: _ctx.$t('myPages.myProfile.label')
            }, {
              default: _withCtx(() => [
                _createVNode(MyProfile)
              ]),
              _: 1
            }, 8, ["title"]),
            (_unref(userStore).getUserType !== _unref(UserType).Agent)
              ? (_openBlock(), _createBlock(_unref(VTab), {
                  key: 0,
                  class: "tabs__tab",
                  title: _ctx.$t('myPages.myCards.label')
                }, {
                  default: _withCtx(() => [
                    _createVNode(MyCards)
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            _createVNode(_unref(VTab), {
              class: "tabs__tab",
              title: _ctx.$t('myPages.myBooking.label')
            }, {
              default: _withCtx(() => [
                _createVNode(MyBookings)
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createBlock(LoginPage, { key: 1 }))
}
}

})