import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "prose" }
const _hoisted_2 = { class: "h3" }

import useLogin from "@/composables/auth/useLogin";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { VBtn, VInput } from "v-ferry-components";
import { reactive, ref } from "vue";

interface Props {
    email?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ForgotPassword',
  props: {
    email: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const state = reactive({
    email: props.email ?? "",
});

const rules = {
    email: { required },
};

const v$ = useVuelidate(rules, state);

const isLoading = ref(false);

const { sendForgottenPassword } = useLogin();

const submitForm = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;
	
	isLoading.value = true;
	
    sendForgottenPassword(state.email).then(() => {
        emit("close");
    });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "forgot-password",
    onSubmit: _withModifiers(submitForm, ["prevent"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t("forgotPassword.title")), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t("forgotPassword.info.enterEmail")), 1)
    ]),
    _createVNode(_unref(VInput), {
      modelValue: _unref(v$).email.$model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).email.$model) = $event)),
      label: _ctx.$t('fields.email.label')
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_unref(VBtn), { isLoading: isLoading.value }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("forgotPassword.send")), 1)
      ]),
      _: 1
    }, 8, ["isLoading"]),
    _createElementVNode("a", {
      href: "#",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (emit('close')), ["prevent"])),
      class: "link"
    }, _toDisplayString(_ctx.$t("forgotPassword.back")), 1)
  ], 32))
}
}

})