import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "booking-flow" }
const _hoisted_2 = { class: "card-tabs" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "unload-container" }

import { useBookingStore } from "@/store/booking";
import useVuelidate from "@vuelidate/core";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import useBookingNavigation from "../composables/useBookingNavigation";
import BookingBasket from "./BookingBasket.vue";
import BookingStepper from "./BookingStepper.vue";
import CustomerDetails from "./customerDetails/CustomerDetails.vue";
import Departures from "./departures/Departures.vue";
import PaymentDetails from "./paymentDetails/PaymentDetails.vue";
import TicketsSelect from "./ticketSelection/TicketsSelect.vue";
import TripDetails from "./tripDetails/TripDetails.vue";
import useCalculateQuery from "@/composables/useCalculateQuery";
import useUnloadBookings from "@/composables/useUnloadBookings";
import { VBtn, VCard } from "v-ferry-components";
import OpenTicket from "./OpenTicket/OpenTicket.vue";
import useRuntimeConfig from "@/composables/useRuntimeConfig";
import { useUserStore } from "@/store/user";

interface Props {
    isBusinessBooking?: boolean;
    isOpenTicket?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingFlow',
  props: {
    isBusinessBooking: { type: Boolean },
    isOpenTicket: { type: Boolean }
  },
  async setup(__props: any) {

let __temp: any, __restore: any

const props = __props;

const banner = ref("");
const allowOpenTickets = ref(false);

const { getRuntimeConfig } = useRuntimeConfig();
const isAgent = computed(() => useUserStore().getUserType === 2);

const isApp = computed(() => navigator.userAgent.includes("OrnoApp v1.0"));
const isBookingView = computed(() => route.path === "/" || "/company" || "/open-ticket");

getRuntimeConfig().then((resp) => {
    banner.value = resp.env;
    allowOpenTickets.value = isApp !== true && isBookingView;
});

const bookingStore = useBookingStore();

const route = useRoute();
bookingStore.setIsBusiness(props.isBusinessBooking);

const showBasket = computed(() => {
    if (props.isOpenTicket) {
        return true;
    } else if (route.query.s) {
        return +route.query.s > 2;
    } else {
        return false;
    }
});

const { data, isLoading: loadingBasket, isFetching } = useCalculateQuery(undefined, props.isOpenTicket);

const showSummary = computed(() => {
    return bookingStore.isAmendment === false || (route.query.s && +route.query.s > 4);
});

const activeComponent = computed(() => {
    if (props.isOpenTicket) {
        switch (route.query.s) {
            case "2":
                return PaymentDetails;
            default:
                return OpenTicket;
        }
    } else {
        switch (route.query.s) {
            case "2":
                return TicketsSelect;
            case "3":
                return Departures;
            case "4":
                return CustomerDetails;
            case "5":
                return PaymentDetails;
            default:
                return TripDetails;
        }
    }
});

const { unload } = useUnloadBookings();

if (!route.query.s && bookingStore.isAmendment) {
    (
  ([__temp,__restore] = _withAsyncContext(() => unload())),
  await __temp,
  __restore()
);
}

const unloadBooking = () => {
    unload().then(() => {
        switch (props.isOpenTicket ?? false) {
            case false:
                window.location.href = "/?s=1";
                break;
            default:
                window.location.href = "/open-ticket";
                break;
        }
    });
};

const stepComponentRef = ref();

const v$ = useVuelidate();

const { goToStep } = useBookingNavigation();

const isLoading = ref(false);

const onNextStep = async (step: number) => {
    const valid = await v$.value.$validate();

    if (valid) {
        if (stepComponentRef.value?.persistState) {
            stepComponentRef.value.persistState();
            goToStep(step);
        }

        if (stepComponentRef.value?.validateStepAsync) {
            isLoading.value = true;
            stepComponentRef.value
                ?.validateStepAsync()
                .then(() => {
                    goToStep(step);
                })
                .catch(() => {
                    return;
                })
                .finally(() => {
                    isLoading.value = false;
                });
        } else {
            goToStep(step);
        }
    }
};

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["booking-step container", { hasBasket: showBasket.value }]),
      key: activeComponent.value.__name
    }, [
      _createElementVNode("div", null, [
        _createVNode(_unref(VCard), null, {
          default: _withCtx(() => [
            _createElementVNode("ul", _hoisted_2, [
              (!isAgent.value)
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, [
                    _createVNode(_component_RouterLink, { to: "/" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.customerBooking")), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("li", null, [
                _createVNode(_component_RouterLink, { to: "/company" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.agentBooking")), 1)
                  ]),
                  _: 1
                })
              ]),
              (allowOpenTickets.value)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                    _createVNode(_component_RouterLink, { to: "/open-ticket" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("menuLinks.openTicket")), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_openBlock(), _createBlock(_resolveDynamicComponent(activeComponent.value), {
              ref_key: "stepComponentRef",
              ref: stepComponentRef
            }, null, 512)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(VBtn), {
                color: "ghost",
                onClick: unloadBooking
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Avbryt bokning")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(BookingStepper, {
          onContinue: onNextStep,
          nextStepLoading: isLoading.value,
          showBasket: showBasket.value,
          nothingToPay: stepComponentRef.value?.nothingToPay,
          basketSections: _unref(data)?.sections.length,
          isLoading: isLoading.value,
          isOpenTicket: _ctx.isOpenTicket
        }, {
          default: _withCtx(() => [
            _createVNode(BookingBasket, {
              sections: _unref(data)?.sections,
              total: _unref(data)?.totalPrice,
              paid: _unref(data)?.paid,
              amd: _unref(data)?.amd,
              showSummary: showSummary.value,
              isLoading: _unref(loadingBasket),
              isFetching: _unref(isFetching),
              isMobile: ""
            }, null, 8, ["sections", "total", "paid", "amd", "showSummary", "isLoading", "isFetching"])
          ]),
          _: 1
        }, 8, ["nextStepLoading", "showBasket", "nothingToPay", "basketSections", "isLoading", "isOpenTicket"])
      ]),
      (showBasket.value)
        ? (_openBlock(), _createBlock(BookingBasket, {
            key: 0,
            sections: _unref(data)?.sections,
            total: _unref(data)?.totalPrice,
            paid: _unref(data)?.paid,
            amd: _unref(data)?.amd,
            showSummary: showSummary.value,
            isLoading: _unref(loadingBasket),
            isFetching: _unref(isFetching)
          }, null, 8, ["sections", "total", "paid", "amd", "showSummary", "isLoading", "isFetching"]))
        : _createCommentVNode("", true)
    ], 2))
  ]))
}
}

})