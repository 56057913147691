<template>
    <VModal @close="emit('close')" :title="$t('myPages.myBooking.item.prepareCancel.title')">
        <template #default>
            <BaseLoader v-if="isLoading" alignCenter />
            <div v-else-if="prepareInfo" class="modal__body prose">
                <p>{{ $t("myPages.myBooking.item.bookingno") }}: {{ booking.bookingNumber }}</p>
            </div>
            <div v-else-if="error" class="modal__body error">
                <p>{{ error }}</p>
            </div>
        </template>
        <template #footer>
            <div v-if="prepareInfo" class="modal__footer">
                <div v-if="prepareInfo.canCancel" class="modal__footer-section">
                    <ul>
                        <li v-for="message in prepareInfo.messages" :key="message">
                            <b>{{ message }}</b>
                        </li>
                    </ul>
                    <b v-if="prepareInfo.refundAmount > 0"
                        >{{ $t("myPages.myBooking.item.prepareCancel.refund") }} {{ prepareInfo.refundAmount }}kr</b
                    >

                    <div class="buttons">
                        <VBtn @click="confirmCancelBooking" class="cancel" :isLoading="confirmCancel.isLoading.value">{{
                            $t("myPages.myBooking.item.prepareCancel.confirmCancel")
                        }}</VBtn>
                        <VBtn @click="emit('close')">{{ $t("myPages.myBooking.item.prepareCancel.abort") }}</VBtn>
                    </div>
                </div>
                <div v-else>
                    <ul>
                        <li v-for="message in prepareInfo.messages" :key="message">
                            <b>{{ message }}</b>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </VModal>
</template>

<script setup lang="ts">
import BaseLoader from "@/components/base/BaseLoader.vue";
import useCancelBooking from "@/composables/profile/useCancelBooking";
import useSnackbar from "@/composables/useSnackbar";
import { PrepareCancelResponse } from "@/models/api/BookingModelResponse";
import { Booking } from "@/models/front/Booking";
import { VBtn, VModal } from "v-ferry-components";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
    booking: Booking;
}

const props = defineProps<Props>();

const emit = defineEmits(["close"]);

const { prepareCancel, confirmCancel, isLoading } = useCancelBooking();

const { displaySnack } = useSnackbar();

const { t } = useI18n();

const prepareInfo = ref<PrepareCancelResponse>();

const error = ref("");

prepareCancel(props.booking.bookingNumber)
    .then((data) => {
        prepareInfo.value = data;
    })
    .catch((err) => {
        error.value = t(`errorCodes.${err.response.data.errorCode}`);
    });

const confirmCancelBooking = () => {
    confirmCancel
        .mutateAsync(props.booking.bookingNumber)
        .then(() => {
            emit("close");
            displaySnack({
                message: t("myPages.myBooking.item.prepareCancel.success"),
                type: "success",
                id: `${props.booking.bookingNumber}`,
            });
        })
        .catch(() => {
            displaySnack({
                message: t("myPages.myBooking.item.prepareCancel.error"),
                type: "danger",
                id: `${props.booking.bookingNumber}`,
            });
        });
};
</script>

<style lang="scss" scoped>
.modal {
    &__body {
        p {
            color: var(--c-primary);
            font-size: 1.125rem;
        }
    }
    &__footer {
        &-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            gap: 10px;

            .buttons {
                display: flex;
                gap: 10px;
            }
        }
    }
}
.error {
    p {
        color: var(--c-danger-darker) !important;
    }
}
.cancel {
    background-color: var(--c-danger);

    &:hover {
        background-color: var(--c-danger-darker);
    }
}
</style>
