import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "payment-option__label" }
const _hoisted_2 = {
  key: 0,
  class: "icon-swish"
}
const _hoisted_3 = {
  key: 1,
  class: "icon"
}

import SvgIcon from "@/components/icons/SvgIcon.vue";
import { VRadio } from "v-ferry-components";
import { computed } from "vue";

interface Props {
    value: string;
    modelValue?: string;
    icon?: string;
    disabled?: boolean;
    hasError?: boolean;
    label: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentOption',
  props: {
    value: {},
    modelValue: {},
    icon: {},
    disabled: { type: Boolean },
    hasError: { type: Boolean },
    label: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const inputValue = computed({
    get() {
        return props.modelValue;
    },
    set(val: string | undefined) {
        emit("update:modelValue", val);
    },
});

const isSwish = computed(() => props.label.toLocaleLowerCase().includes("swish"));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["payment-option", { 'payment-option--error': _ctx.hasError, 'payment-option--active': inputValue.value === _ctx.value }])
  }, [
    _createVNode(_unref(VRadio), {
      id: _ctx.value,
      name: _ctx.value,
      modelValue: inputValue.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
      value: _ctx.value,
      label: _ctx.label,
      disabled: _ctx.disabled
    }, {
      default: _withCtx(({ label }) => [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(label) + " ", 1),
          (isSwish.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_ctx.icon)
                  ? (_openBlock(), _createBlock(SvgIcon, {
                      key: 0,
                      icon: _ctx.icon
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true)
              ]))
        ])
      ]),
      _: 1
    }, 8, ["id", "name", "modelValue", "value", "label", "disabled"])
  ], 2))
}
}

})