import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "booking-item" }
const _hoisted_2 = { class: "booking-number" }
const _hoisted_3 = { class: "departures-container" }
const _hoisted_4 = {
  key: 0,
  class: "departure"
}
const _hoisted_5 = { class: "resources" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "buttons"
}

import SvgIcon from "@/components/icons/SvgIcon.vue";
import { departureRoutes } from "@/config/routes";
import { VBtn } from "v-ferry-components";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import AmendBookingModal from "./AmendBookingModal.vue";
import PrepareCancelModal from "./PrepareCancelModal.vue";
import { Booking } from "@/models/front/Booking";
import useUnloadBookings from "@/composables/useUnloadBookings";
import { dayDateTime } from "@/helpers/dateHelpers";

interface Props {
    booking: Booking;
    expanded?: boolean;
    pastBooking?: boolean;
    isExpansion?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BookingItem',
  props: {
    booking: {},
    expanded: { type: Boolean },
    pastBooking: { type: Boolean },
    isExpansion: { type: Boolean }
  },
  setup(__props: any) {

const showCancel = ref(false);
const showAmend = ref(false);

const { unload } = useUnloadBookings();

const closeAmend = () => {
    showAmend.value = false;
    unload();
};

const props = __props;

const { t } = useI18n();

const isActive = ref(props.expanded);

const routeOutward = departureRoutes.find((r) => r.value === props.booking.departure.route);
const routeReturn = departureRoutes.find((r) => r.value === props.booking.return?.route);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isActive.value = !isActive.value)),
        class: _normalizeClass({ interactive: _ctx.isExpansion })
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_unref(t)("myPages.myBooking.item.bookingno")), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.booking.bookingNumber), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(["departure", { hasPast: new Date(_ctx.booking.departure.departureDate) < new Date() && !_ctx.pastBooking }])
          }, [
            _createElementVNode("span", null, _toDisplayString(_unref(routeOutward)?.outwardRoute) + " -> " + _toDisplayString(_unref(routeOutward)?.arrivalRoute), 1),
            _createElementVNode("span", null, _toDisplayString(_unref(dayDateTime)(_ctx.booking.departure.departureDate)), 1)
          ], 2),
          (_ctx.booking.return)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(_unref(routeReturn)?.outwardRoute) + " -> " + _toDisplayString(_unref(routeReturn)?.arrivalRoute), 1),
                _createElementVNode("span", null, _toDisplayString(_unref(dayDateTime)(_ctx.booking.return.departureDate)), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isExpansion)
          ? (_openBlock(), _createBlock(SvgIcon, {
              key: 0,
              class: _normalizeClass(["chevron", { 'chevron-active': isActive.value }]),
              icon: "chevron",
              width: "15px"
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["booking-item__content", { 'booking-item__content-active': isActive.value || !_ctx.isExpansion }])
      }, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.booking.departure.resources, (resource, index) => {
            return (_openBlock(), _createElementBlock("p", { key: index }, [
              _createTextVNode(_toDisplayString(resource.amount) + " x ", 1),
              (resource.type)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(resource.description), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(resource.description) + " " + _toDisplayString(resource.license ? `(${resource.license})` : ""), 1))
            ]))
          }), 128))
        ]),
        (!_ctx.pastBooking)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_unref(VBtn), {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (showCancel.value = true)),
                class: "cancel"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("myPages.myBooking.item.cancel")), 1)
                ]),
                _: 1
              }),
              _createVNode(_unref(VBtn), {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (showAmend.value = true)),
                class: "reschedule"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)("myPages.myBooking.item.reschedule")), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    (showCancel.value)
      ? (_openBlock(), _createBlock(PrepareCancelModal, {
          key: 0,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (showCancel.value = false)),
          booking: _ctx.booking,
          isClosable: ""
        }, null, 8, ["booking"]))
      : _createCommentVNode("", true),
    (showAmend.value)
      ? (_openBlock(), _createBlock(AmendBookingModal, {
          key: 1,
          onClose: closeAmend,
          booking: _ctx.booking,
          isClosable: ""
        }, null, 8, ["booking"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})