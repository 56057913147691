<template>
    <div class="trip-details">
        <div class="prose">
            <div class="subtitle">
                <b v-if="isBusiness" class="agent-title">{{ t("agent.title") }}</b>
                <b v-if="isAmend" class="agent-title">{{ t("bookingFlow.isAmend") }} {{ bookingStore.bookingNumber }}</b>
            </div>
            <h1 class="h2">{{ t("tripDetails.title") }}</h1>
        </div>

        <div class="routes">
            <VSelect
                v-model="v$.outwardRoute.$model"
                :hasError="v$.outwardRoute.$error"
                :label="t('tripDetails.label.outwardRoute')"
                :options="routes"
                @option:selected="onOutwardRouteSelect"
                :disabled="outwardIsLocked"
                :class="{ disabled: outwardIsLocked }"
                :clearable="false"
            />
            <VBtn @click="switchRoutes" class="v-btn--ghost">
                <SvgIcon icon="carbon/arrows-horizontal" width="50px" />
            </VBtn>
            <VSelect
                v-model="v$.returnRoute.$model"
                :hasError="v$.returnRoute.$error"
                :label="t('tripDetails.label.returnRoute')"
                :options="returnOptions"
                :clearable="false"
            />
        </div>
        <VToggleSwitch
            v-model="hasReturn"
            :class="{ disabled: outwardIsLocked }"
            id="tripToggle"
            v-if="bookingStore.getIsAmendment === false"
            :label="t('tripDetails.tripType')"
        />
        <div class="dates">
            <div class="dates-outward">
                <p class="dates__title">{{ t("tripDetails.label.outwardDate") }}</p>
                <VDatePicker
                    v-model="v$.outwardDate.$model"
                    :hasError="v$.outwardDate.$error"
                    :min-date="new Date()"
                    :class="{ error: v$.outwardDate.$error, disabled: outwardIsLocked }"
                    isInline
                    weekdaysFormat="W"
                    locale="sv"
                />
                <div v-if="v$.outwardDate.$error" class="error-messages">
                    <p v-for="error in [...new Set(v$.outwardDate.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
                </div>
            </div>
            <div v-if="hasReturn" class="dates-return">
                <p class="dates__title">{{ t("tripDetails.label.returnDate") }}</p>
                <VDatePicker
                    v-model="v$.returnDate.$model"
                    :hasError="v$.returnDate.$error"
                    :min-date="state.outwardDate ?? new Date()"
                    :key="state.outwardDate?.toDateString()"
                    :class="{ error: v$.returnDate.$error }"
                    isInline
                    weekdaysFormat="W"
                    locale="sv"
                />
                <div v-if="v$.returnDate.$error" class="error-messages">
                    <p v-for="error in [...new Set(v$.returnDate.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/icons/SvgIcon.vue";
import { routes } from "@/config/routes";
import { outwardDateRequired, returnDateRequired } from "@/helpers/validators";
import { useBookingStore } from "@/store/booking";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { VBtn, VDatePicker, VSelect, VToggleSwitch } from "v-ferry-components";
import { computed, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

const isBusiness = computed(() => bookingStore.getIsBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const { t } = useI18n();

const returnOptions = computed(() => routes.filter((r) => r.value !== state.outwardRoute));

const onOutwardRouteSelect = () => {
    const returnRoutes = routes.filter((r) => r.value !== state.outwardRoute);
    if (returnRoutes.length) {
        state.returnRoute = returnRoutes[0].value;
    }
};

const switchRoutes = () => {
    if (!outwardIsLocked.value) {
        state.outwardRoute = state.returnRoute;
        onOutwardRouteSelect();
    }
};

const bookingStore = useBookingStore();
const hasReturn = ref(bookingStore.getIsReturn);

const outwardIsLocked = computed(() => bookingStore.getBooking.outward.isLocked && bookingStore.getIsAmendment);

const state = reactive({
    outwardRoute: bookingStore.outward.route.length ? bookingStore.outward.route : "DAOR",
    returnRoute: bookingStore.return.route.length ? bookingStore.return.route : "ORDA",
    outwardDate: bookingStore.outward.date ? new Date(bookingStore.outward.date) : undefined,
    returnDate: bookingStore.return.date ? new Date(bookingStore.return.date) : undefined,
});

const rules = {
    outwardRoute: {
        v: () => !!state.outwardRoute,
    },
    returnRoute: {
        v: () => !!state.returnRoute,
    },
    outwardDate: {
        v: helpers.withMessage(
            t("tripDetails.helpers.outwardDate"),
            () => outwardIsLocked.value === true || outwardDateRequired(state.outwardDate),
        ),
    },
    returnDate: {
        v: helpers.withMessage(t("tripDetails.helpers.returnDate"), () =>
            returnDateRequired(hasReturn.value, state.returnDate, state.outwardDate),
        ),
    },
};

const v$ = useVuelidate(rules, state);

const persistState = () => {
    if (state.outwardDate) {
        bookingStore.setTripDetails(state.outwardRoute, state.outwardDate, state.returnRoute, hasReturn.value, state.returnDate);
    }
};

defineExpose({
    persistState,
});

watch(state, () => {
    if (state.returnDate && state.outwardDate)
        if (state.returnDate < state.outwardDate) {
            state.returnDate = state.outwardDate;
        }
});
</script>

<style scoped lang="scss">
.trip-details {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .subtitle {
        display: flex;
        justify-content: space-between;
    }

    .routes,
    .dates {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .routes {
        align-items: center;
        div {
            flex: 1;
        }

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            div {
                width: 100%;
            }
        }
    }

    .dates {
        gap: 50px;
        div {
            flex: 1;
        }

        &__title {
            font-weight: 600;
            margin-bottom: 5px;
        }
    }
    .disabled {
        pointer-events: none;
        cursor: none;
        opacity: 0.6;
    }
}
</style>
