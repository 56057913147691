import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "my-cards"
}
const _hoisted_2 = {
  key: 0,
  class: "my-cards__section"
}
const _hoisted_3 = {
  key: 1,
  class: "prose"
}
const _hoisted_4 = {
  key: 1,
  class: "my-cards__section"
}
const _hoisted_5 = {
  key: 1,
  class: "prose"
}
const _hoisted_6 = {
  key: 2,
  class: "my-cards__section"
}

import BaseLoader from "@/components/base/BaseLoader.vue";
import SvgIcon from "@/components/icons/SvgIcon.vue";
import useAvailableMultiTripCardQuery from "@/composables/profile/useAvailableMultiTripCardQuery";
import useCustomerCardsQuery from "@/composables/profile/useCustomerCardsQuery";
import { ref } from "vue";
import DisplayCards from "./DisplayCards.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyCards',
  setup(__props) {

const { data: cards, isLoading: isLoadingCards } = useCustomerCardsQuery();

const { data: availableCardsForPurchase, isLoading: isLoadingTickets } = useAvailableMultiTripCardQuery();

const availableTicketsRef = ref();

return (_ctx: any,_cache: any) => {
  return (_unref(isLoadingCards) || _unref(isLoadingTickets))
    ? (_openBlock(), _createBlock(BaseLoader, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_unref(cards)?.travelCards)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("header", null, [
                _createVNode(SvgIcon, { icon: "carbon/card" }),
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("myPages.titles.travelcard")), 1)
              ]),
              (_unref(cards)?.travelCards?.length)
                ? (_openBlock(), _createBlock(DisplayCards, {
                    key: 0,
                    travelCards: _unref(cards)?.travelCards
                  }, null, 8, ["travelCards"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
                    _createElementVNode("p", null, " Du har inga kort. Årskort beställs via mail: kontoret@ornosjotrafik.se i god tid innan man vill att det ska börja gälla. Kostnaden faktureras via mail. ", -1)
                  ])))
            ]))
          : _createCommentVNode("", true),
        (_unref(cards)?.multiTripCards)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("header", null, [
                _createVNode(SvgIcon, { icon: "carbon/ticket" }),
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("myPages.titles.tickets")), 1)
              ]),
              (_unref(cards)?.multiTripCards?.length)
                ? (_openBlock(), _createBlock(DisplayCards, {
                    key: 0,
                    multiTripCards: _unref(cards)?.multiTripCards
                  }, null, 8, ["multiTripCards"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[1] || (_cache[1] = [
                    _createElementVNode("p", null, "Du har inga partibiljetter", -1)
                  ])))
            ]))
          : _createCommentVNode("", true),
        (_unref(availableCardsForPurchase))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("header", null, [
                _createVNode(SvgIcon, { icon: "carbon/shopping-cart-plus" }),
                _createElementVNode("h3", null, _toDisplayString(_ctx.$t("myPages.titles.newTicket")), 1)
              ]),
              _createVNode(DisplayCards, {
                class: "my-cards__purchase",
                cardsForPurchase: _unref(availableCardsForPurchase),
                ref_key: "availableTicketsRef",
                ref: availableTicketsRef
              }, null, 8, ["cardsForPurchase"])
            ]))
          : _createCommentVNode("", true)
      ]))
}
}

})