<template>
    <BaseLoader v-if="isLoading" />
    <div v-else class="open-ticket">
        <div class="open-ticket__section">
            <div class="title">
                <h1 class="h3">{{ $t("openTicket.title") }}</h1>
                <p class="subtitle prose">{{ $t("openTicket.subtitle") }}</p>
            </div>
            <div class="ticket-select">
                <TicketsSelectOption v-model="ticketAmount" icon="person" :title="$t('openTicket.ticket.label')" :max="20" />
            </div>
            <div v-if="v$.tickets.$error" class="error-messages">
                <p v-for="error in [...new Set(v$.tickets.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
            </div>
        </div>
        <hr />
        <div class="open-ticket__section">
            <div class="title">
                <h3 class="h3">{{ $t("openTicket.form.title") }}</h3>
                <p class="subtitle prose">{{ $t("openTicket.form.subtitle") }}</p>
            </div>

            <form @submit.prevent>
                <div class="row">
                    <VInput
                        v-model="v$.mobilePhone.$model"
                        :hasError="v$.mobilePhone.$error"
                        :label="$t('fields.phone.label')"
                        autocomplete="tel"
                        type="tel"
                        v-maska
                        data-maska="#############"
                    />
                    <VInput
                        v-model="v$.confirmMobilePhone.$model"
                        :hasError="v$.confirmMobilePhone.$error"
                        :label="$t('fields.confirmPhone.label')"
                        autocomplete="tel"
                        type="tel"
                        v-maska
                        data-maska="#############"
                    />
                </div>
                <div v-if="v$.confirmMobilePhone.$error" class="error-messages">
                	<p v-for="error in [...new Set(v$.confirmMobilePhone.$errors.map((e) => e.$message))]" :key="error.toString()">{{ error }}</p>
                </div>
               
            </form>
        </div>
        <div class="error-messages large">
            <p>{{ error }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseLoader from "@/components/base/BaseLoader.vue";
import useBook from "@/composables/book/useBook";
import useOpenTicketsQuery from "@/composables/open-tickets/useOpenTicketsQuery";
import { required } from "@/helpers/validators";
import { BookRequest } from "@/models/front/BookRequest";
import useVuelidate from "@vuelidate/core";
import { vMaska } from "maska";
import { VInput } from "v-ferry-components";
import { computed, reactive, ref } from "vue";
import TicketsSelectOption from "../ticketSelection/TicketsSelectOption.vue";
import { useOpenTicketStore } from "@/store/openTicket";
import { helpers } from "@vuelidate/validators";

const { data, isLoading } = useOpenTicketsQuery();

const { book } = useBook();

const openTicketStore = useOpenTicketStore();

const ticketAmount = computed({
    get() {
        return openTicketStore.getNumberOfTickets ?? 1;
    },
    set(value: number) {
        openTicketStore.setNumberOfTickets(+value);
    },
});

const state = reactive({
    tickets: ticketAmount.value,
    mobilePhone: "",
    confirmMobilePhone: "",
});

const rules = {
    tickets: {
        v: helpers.withMessage("Du behöver välja antal biljetter", (v: number) => v > 0),
        required,
    },
    mobilePhone: {
        required,
    },
    confirmMobilePhone: {
    	v: helpers.withMessage("Telefonnumren matchar inte. Ange ditt telefonnummer igen för att bekräfta.", (v: string) => v === state.mobilePhone),
        required,
    },
};

const v$ = useVuelidate(rules, state);

const error = ref("");

const validateStepAsync = () => {
    const identifier = openTicketStore.getIdentifier;

    if (!identifier) return;

    const request: BookRequest = {
        identifiers: identifier.split("&"),
        vehicleDetails: [],
        bookingNumber: -1,
        phoneNumber: state.mobilePhone
    };

    return new Promise((resolve, reject) => {
        book(request)
            .then(() => {
                resolve(true);
            })
            .catch((err) => {
                error.value = err.message;
                reject();
            });
    });
};

defineExpose({
    validateStepAsync,
});
</script>

<style scoped lang="scss">
.open-ticket {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .title {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    &__section {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .ticket-select {
            display: flex;
            justify-content: center;
            align-items: center;

            --v--btn-padding: 0px;

            .ticket {
                @include media-breakpoint-down(sm) {
                    padding: 5px 3px;

                    :deep(.v-btn) {
                        padding: 5px;
                    }
                }
            }
        }

        .row {
            display: flex;
            gap: 5px;

            @include media-breakpoint-down(md) {
                flex-direction: column;
            }

            .v-input {
                width: 100%;
            }
        }
    }

    hr {
        border: none;
        height: 1px;
        background-color: var(--c-border);
    }
}
</style>
