import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ticket__content" }
const _hoisted_2 = { class: "prose" }

import SvgIcon from "@/components/icons/SvgIcon.vue";
import { VCard, VQuantity } from "v-ferry-components";
import { computed } from "vue";

interface Props {
    modelValue: number;
    icon: string;
    title: string;
    max?: number;
    hideQuantity?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TicketsSelectOption',
  props: {
    modelValue: {},
    icon: {},
    title: {},
    max: {},
    hideQuantity: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const quantity = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emit("update:modelValue", val);
    },
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VCard), {
    class: _normalizeClass(["ticket", { 'is-active': quantity.value > 0 }]),
    bordered: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(SvgIcon, {
          class: "ticket__icon",
          icon: _ctx.icon
        }, null, 8, ["icon"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1)
        ])
      ]),
      _createVNode(_unref(VQuantity), {
        color: "ghost",
        max: _ctx.max ?? 5,
        min: 0,
        modelValue: quantity.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((quantity).value = $event)),
        class: _normalizeClass({ noIcons: _ctx.hideQuantity })
      }, null, 8, ["max", "modelValue", "class"])
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})