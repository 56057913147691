import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-page" }
const _hoisted_2 = { class: "login-form" }
const _hoisted_3 = { class: "prose" }
const _hoisted_4 = { class: "h4" }

import LoginForm from "@/components/login/LoginForm.vue";
import { VBtn, VCard } from "v-ferry-components";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useBookingStore } from "@/store/booking";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentLoginPage',
  setup(__props) {

const router = useRouter();

const bookingStore = useBookingStore();

const error = ref("");

const goToBooking = (state) => {
	
	bookingStore.setRoute(state?.defaultRoute);
	
    router.push(state?.type === 'Agent' ? "/company" : "/");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "login-page__background" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(VCard), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("loginPage.title.agent")), 1),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, [
              _createTextVNode(" Logga in för att se, ändra och avboka dina bokningar som "),
              _createElementVNode("b", null, "företagskund"),
              _createTextVNode(". Vi önskar dig en trevlig resa! ")
            ], -1))
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createVNode(LoginForm, {
            hideCreateAccount: "",
            onOnLogin: goToBooking,
            isAgent: true
          })
        ]),
        _: 1
      })
    ])
  ]))
}
}

})