import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "customer-details" }
const _hoisted_2 = {
  key: 0,
  class: "loading-overlay"
}
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = {
  key: 0,
  class: "agent-title"
}
const _hoisted_5 = {
  key: 1,
  class: "agent-title"
}
const _hoisted_6 = { class: "h2" }
const _hoisted_7 = { class: "error-messages" }

import LoginForm from "@/components/login/LoginForm.vue";
import { UserType } from "@/models/Enums";
import { useBookingStore } from "@/store/booking";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";
import useBookingDetailsQuery from "../../composables/useBookingDetailsQuery";
import CustomerDetailsForm from "./CustomerDetailsForm.vue";
import BaseLoader from "@/components/base/BaseLoader.vue";
import useCreateAccount from "@/composables/auth/useCreateAccount";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerDetails',
  setup(__props, { expose: __expose }) {

const bookingStore = useBookingStore();
const isBusiness = computed(() => bookingStore.isBusiness);
const isAmend = computed(() => bookingStore.getIsAmendment);

const userStore = useUserStore();

const { setDetails, data: details, isFetching } = useBookingDetailsQuery();
const { createCustomer } = useCreateAccount();

const userType = computed(() => userStore.getUserType);
const isLoggedIn = computed(() => userType.value !== UserType.Unknown);

const createAccount = ref(false);

const showCustomerDetailsForm = ref(isLoggedIn.value);

const toggleCustomerForm = (show: boolean, create?: boolean) => {
    createAccount.value = false;
    if (create) {
        createAccount.value = create;
    }
    showCustomerDetailsForm.value = show;
};

const customerDetailsRef = ref();

const error = ref();

const getUserType = (createNewAccount: boolean) => {
    if (isBusiness.value) return UserType.Agent;
    if (createNewAccount) return UserType.Customer;
    else return UserType.Guest;
};

const validateStepAsync = () => {
    const state = customerDetailsRef.value.state;
    const createNewAccount = customerDetailsRef.value.createNewAccount;

    const detailsRequest = {
        firstName: state.firstName,
        lastName: state.lastName,
        address: state.address,
        zip: state.zip,
        city: state.city,
        mobilePhone: state.mobilePhone,
        email: state.email,
        password: state.password,
        passwordRepeat: state.password,
        type: getUserType(createNewAccount),
        userName: state.email,
        name: !state.name ? `${state.firstName} ${state.lastName}` : state.name,
        terms: state.gdpr,
        bookingNumber: bookingStore.getBookingNumber,
    };

    return setDetails(detailsRequest)
        .then(() => {
            bookingStore.setTermsAccepted(true);

            if (createNewAccount) {
                return createCustomer(state).catch((err) => {
                    error.value = err.message;

                    return Promise.reject(err);
                });
            }

            if (userStore.getUserType === UserType.Unknown) {
                userStore.setUserType(UserType.Guest);
            }
        })
        .catch((err) => {
            error.value = err.message;
            return Promise.reject(err);
        });
};

__expose({
    validateStepAsync,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(isFetching))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(BaseLoader)
        ]))
      : _createCommentVNode("", true),
    (isLoggedIn.value || showCustomerDetailsForm.value)
      ? (_openBlock(), _createBlock(CustomerDetailsForm, {
          isLoggedIn: isLoggedIn.value,
          details: _unref(details),
          createAccount: createAccount.value,
          onAbort: _cache[0] || (_cache[0] = ($event: any) => (toggleCustomerForm(false))),
          ref_key: "customerDetailsRef",
          ref: customerDetailsRef,
          key: _unref(details)?.email
        }, null, 8, ["isLoggedIn", "details", "createAccount"]))
      : (!isLoggedIn.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_3, [
                (isBusiness.value)
                  ? (_openBlock(), _createElementBlock("b", _hoisted_4, _toDisplayString(_ctx.$t("agent.title")), 1))
                  : _createCommentVNode("", true),
                (isAmend.value)
                  ? (_openBlock(), _createElementBlock("b", _hoisted_5, _toDisplayString(_ctx.$t("bookingFlow.isAmend")) + " " + _toDisplayString(_unref(bookingStore).bookingNumber), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t("customerDetails.loginTitle")), 1)
            ]),
            _createVNode(LoginForm, {
              onCreateAccount: _cache[1] || (_cache[1] = ($event: any) => (toggleCustomerForm(true, true))),
              isAgent: isBusiness.value
            }, null, 8, ["isAgent"]),
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (toggleCustomerForm(true)), ["prevent"])),
              class: "link text-center"
            }, _toDisplayString(_ctx.$t("customerDetails.buttons.continue")), 1)
          ], 64))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", null, _toDisplayString(error.value), 1)
    ])
  ]))
}
}

})