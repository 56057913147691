<template>
    <form class="forgot-password" @submit.prevent="submitForm">
        <div class="prose">
            <h2 class="h3">{{ $t("forgotPassword.title") }}</h2>
            <p>{{ $t("forgotPassword.info.enterEmail") }}</p>
        </div>
        <VInput v-model="v$.email.$model" :label="$t('fields.email.label')" />
        <VBtn :isLoading="isLoading">{{ $t("forgotPassword.send") }}</VBtn>
        <a href="#" @click.prevent="emit('close')" class="link">{{ $t("forgotPassword.back") }}</a>
    </form>
</template>

<script setup lang="ts">
import useLogin from "@/composables/auth/useLogin";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { VBtn, VInput } from "v-ferry-components";
import { reactive, ref } from "vue";

interface Props {
    email?: string;
}

const props = defineProps<Props>();
const emit = defineEmits(["close"]);

const state = reactive({
    email: props.email ?? "",
});

const rules = {
    email: { required },
};

const v$ = useVuelidate(rules, state);

const isLoading = ref(false);

const { sendForgottenPassword } = useLogin();

const submitForm = async () => {
    const isValid = await v$.value.$validate();
    if (!isValid) return;
	
	isLoading.value = true;
	
    sendForgottenPassword(state.email).then(() => {
        emit("close");
    });
};
</script>

<style scoped lang="scss">
.forgot-password {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>
