import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "receipt__qr-code" }
const _hoisted_3 = { class: "receipt__description" }
const _hoisted_4 = {
  key: 0,
  class: "receipt__expired"
}
const _hoisted_5 = { class: "boarding-card__footer" }

import useRuntimeConfig from "@/composables/useRuntimeConfig";
import { BookingComplete } from "@/models/front/BookingComplete";
import QrcodeVue from "qrcode.vue";
import { A11y, Navigation, Pagination, Virtual } from "swiper/modules";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref, computed } from "vue";
import { VCard } from "v-ferry-components";
import BaseLoader from "@/components/base/BaseLoader.vue";

interface Props {
    tickets?: BookingComplete[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OpenTicket',
  props: {
    tickets: {}
  },
  setup(__props: any) {

const swiperModules = [Navigation, A11y, Virtual, Pagination];

const props = __props;

const initialSlide = computed(() => {

	return props.tickets?.indexOf(props.tickets?.find(ticket => ticket.isValid === true));
});

const breakpoints = {
    0: { slidesPerView: 1 },
};

const { getRuntimeConfig } = useRuntimeConfig();

const baseApiUrl = ref("");
getRuntimeConfig().then((r) => {
    baseApiUrl.value = r.apiBaseUrl;
});

const print = (printAll = false) => {
    if (printAll) {
        document.getElementsByClassName("swiper-wrapper")[0].classList.add("print-all");
    } else {
        document.getElementsByClassName("swiper-wrapper")[0].classList.remove("print-all");
    }

    window.print();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VCard), { class: "boarding-card" }, {
    default: _withCtx(() => [
      (!_ctx.tickets)
        ? (_openBlock(), _createBlock(BaseLoader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t("thankyou.booking.title")), 1),
            (_openBlock(), _createBlock(_unref(Swiper), {
              navigation: "",
              initialSlide: initialSlide.value,
              breakpoints: breakpoints,
              key: initialSlide.value,
              centeredSlides: true,
              pagination: { clickable: true },
              modules: swiperModules
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tickets, (boardingCard, index) => {
                  return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                    key: index,
                    virtualIndex: index,
                    class: "boarding-card__wrapper"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["receipt", { 'disabled' : boardingCard.isValid !== true }])
                      }, [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(QrcodeVue, {
                            value: boardingCard.bookingNumber.toString(),
                            size: 100
                          }, null, 8, ["value"])
                        ]),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("checkin.boardingCard.bookingNr")) + ": " + _toDisplayString(boardingCard.bookingNumber), 1),
                        (boardingCard.usedAt)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("checkin.boardingCard.expiredAt")) + ": " + _toDisplayString(boardingCard.usedAt), 1))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["virtualIndex"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["initialSlide"])),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("a", {
                href: "#",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (print()))
              }, _toDisplayString(_ctx.$t(`checkin.boardingCard.print`)), 1),
              (_ctx.tickets.length > 1)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "#",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (print(true)))
                  }, _toDisplayString(_ctx.$t(`checkin.boardingCard.printAll`)), 1))
                : _createCommentVNode("", true)
            ])
          ]))
    ]),
    _: 1
  }))
}
}

})