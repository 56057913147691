<template>
    <form @submit.prevent="submit">
        <div class="display-cards">
            <table>
                <thead>
                    <tr>
                        <th>
                            {{ $t("myPages.myCards.labels.card") }}
                        </th>
                        <th v-if="multiTripCards">
                            {{ $t("myPages.myCards.labels.remainingTrips") }}
                        </th>
                        <th v-if="cardsForPurchase">
                            {{ $t("myPages.myCards.labels.price") }}
                        </th>
                        <th v-if="travelCards">
                            {{ $t("myPages.myCards.labels.validThrough") }}
                        </th>
                        <th v-if="travelCards">
                            {{ $t("myPages.myCards.labels.usedTrips") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="multiTripCard in multiTripCards" :key="multiTripCard.name">
                        <td>{{ $t(`myPages.myCards.cards.${multiTripCard.type}.${multiTripCard.subType}`) }}</td>
                        <td>
                            {{ multiTripCard.maxUsage - multiTripCard.currentUsage }}/{{ multiTripCard.maxUsage }}
                            {{ $t("myPages.myCards.tripsRemaining") }}
                        </td>
                    </tr>
                    <tr v-for="card in travelCards" :key="card.name">
                        <td>{{ $t(`myPages.myCards.cards.${card.type}.${card.subType}`) }}</td>
                        <td>{{ card.validBetween }}</td>
                        <td>{{ card.currentUsage }}</td>
                    </tr>
                    <tr v-for="card in cardsForPurchase" :key="card.description">
                        <td class="checkbox-container">
                            <VRadio
                                v-model="v$.availableCard.$model"
                                :id="card.description"
                                :label="$t(`myPages.myCards.cards.${card.type}.${card.subType}`)"
                                :value="card.identifier"
                                :hasError="v$.availableCard.$error"
                                name="purchaseCard"
                            />
                        </td>
                        <td>{{ card.price }}kr</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <VBtn v-if="cardsForPurchase" :isLoading="isLoadingBuy">{{ $t("myPages.myCards.buyBtn") }}</VBtn>
    </form>
</template>

<script setup lang="ts">
import useAvailableMultiTripCardQuery from "@/composables/profile/useAvailableMultiTripCardQuery";
import { AvailableCard } from "@/models/front/AvailableCard";
import { CustomerCard } from "@/models/front/Cards";
import useVuelidate from "@vuelidate/core";
import { required } from "@/helpers/validators";
import { VBtn, VRadio } from "v-ferry-components";
import { reactive } from "vue";
import { useRouter } from "vue-router";

const { buyTickets, isLoadingBuy } = useAvailableMultiTripCardQuery();
const router = useRouter();

interface Props {
    multiTripCards?: CustomerCard[];
    travelCards?: CustomerCard[];
    cardsForPurchase?: AvailableCard[];
}

defineProps<Props>();

const state = reactive({
    availableCard: "",
});

const rules = {
    availableCard: { required },
};

const v$ = useVuelidate(rules, state);

const submit = async () => {
    const isValid = await v$.value.$validate();

    if (!isValid) return;
    buyTickets(state.availableCard).then((resp) => {
        router.push(`/checkout/?reference=${resp.reference}`);
    });
};
</script>

<style lang="scss" scoped>
form {
    .display-cards {
        overflow-x: auto;
        table {
            width: 100%;
            min-width: 500px;
            border-collapse: separate;
            border-spacing: 0 10px;

            thead {
                background-color: transparent;
            }

            th {
                text-align: left;
                padding: 0 5px;
            }

            tbody {
                tr {
                    padding: 15px;
                    background-color: var(--c-table);

                    td {
                        padding: 15px 5px;

                        .v-checkbox {
                            padding: 0px;
                        }
                    }
                }
            }
        }

        .checkbox-container {
            display: flex;
        }
    }
    .v-btn {
        float: right;
    }
}
</style>
