import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "departure__radio--departure" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "departure__radio--arrival" }

import SvgIcon from "@/components/icons/SvgIcon.vue";
import { HHmm, dateAndTime } from "@/helpers/dateHelpers";
import { Direction, VehicleCapacity } from "@/models/Enums";
import { DepartureModel } from "@/models/front/DepartureModel";
import { useDepartureStore } from "@/store/departure";
import { VRadio } from "v-ferry-components";
import { computed } from "vue";

interface Props {
    modelValue?: string;
    departure: DepartureModel;
    direction: Direction;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Departure',
  props: {
    modelValue: {},
    departure: {},
    direction: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const departureStore = useDepartureStore();
const isDisabled = computed(() => {
	
	if (props.departure.isBookable === false) {
		return true;
	}
	
    if (props.departure.capacity === VehicleCapacity.None) {
        return true;
    }

    if (props.direction === Direction.Outward) {
        return false;
    }

    return dateAndTime(props.departure.departureDate) < departureStore.getDeparture(Direction.Outward).date;
});

const inputValue = computed({
    get() {
        return props.modelValue;
    },
    set(value?: string) {
        emit("update:modelValue", value);
    },
});

if (props.departure.id === inputValue.value) {
    const selectedDeparture = departureStore.getDeparture(props.direction);
    departureStore.setDeparture(props.direction, { ...selectedDeparture, identifier: props.departure.identifier });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["departure", { disabled: isDisabled.value }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["departure__radio", { active: _ctx.modelValue === _ctx.departure.id }])
    }, [
      _createVNode(_unref(VRadio), {
        id: `departure-${_ctx.departure.id}`,
        name: `departure-${_ctx.departure.route}`,
        value: _ctx.departure.id,
        modelValue: inputValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
        disabled: isDisabled.value
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(HHmm)(_ctx.departure.departureDate)), 1),
          (_ctx.departure.capacity == _unref(VehicleCapacity).None)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t(`departures.vehicleInfo.2`)), 1))
            : (_openBlock(), _createBlock(SvgIcon, {
                key: 1,
                icon: "carbon/arrow-right-long",
                class: "arrow",
                width: "3.75rem"
              })),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(HHmm)(_ctx.departure.arrivalDate)), 1),
            _createVNode(SvgIcon, {
              icon: "carbon/car",
              class: _normalizeClass(`vehicle-info vehicle-info--${_ctx.departure.capacity.toString().toLowerCase()}`),
              height: "1.5rem"
            }, null, 8, ["class"])
          ])
        ]),
        _: 1
      }, 8, ["id", "name", "value", "modelValue", "disabled"])
    ], 2)
  ], 2))
}
}

})