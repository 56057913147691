import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

interface Props {
    icon: string;
    spin?: boolean;
    height?: string;
    width?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SvgIcon',
  props: {
    icon: {},
    spin: { type: Boolean },
    height: {},
    width: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["icon", { 'icon-spin': _ctx.spin }]),
    style: _normalizeStyle((_ctx.height ? `height: ${_ctx.height || '2rem'};` : '') + (_ctx.width ? `width: ${_ctx.width || '2rem'};` : ''))
  }, [
    _createElementVNode("use", {
      href: `#${_ctx.icon}`
    }, null, 8, _hoisted_1)
  ], 6))
}
}

})